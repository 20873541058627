<template>    
    <basic-header
        pretitle="Sandbox"
        :title="`Execution ID: ${$route.params.id}`"
        :buttonText= "`Back to Unit Test`"
        :buttonClass="`btn-secondary btn-sm`"
        @btnClick="$router.push({name: 'Unit Test'})"
    ><i class="fe fe-arrow-left me-1"></i></basic-header>
    <div class="container-fluid">
        <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <div class="list-group list-group-flush my-n3">
                        <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="mb-0">Status</h5>
                                </div>
                                <div class="col-auto">
                                    <strong :class="[statusClass, 'text-uppercase']">{{status}}</strong>
                                    <div v-if="status != 'failed' && status != 'succeeded'" style="margin-left:10px" class="spinner-border spinner-border-sm text-primary">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="mb-0">Job Name</h5>
                                </div>
                                <div class="col-auto">
                                    <small class="text-muted">{{jobName}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="mb-0">Run</h5>
                                </div>
                                <div class="col-auto">
                                    <small class="text-muted">{{name}}</small>
                                </div>
                            </div>
                        </div>
                        <div v-if="cluster" class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="mb-0">Cluster</h5>
                                </div>
                                <div class="col-auto">
                                    <small class="text-muted">{{cluster}}</small>
                                </div>
                            </div>
                        </div>
                        <div v-if="startedAt" class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="mb-0">Started</h5>
                                </div>
                                <div class="col-auto">
                                    <small class="text-muted">{{startedAt.format('MMM D, YYYY h:mm:ss A')}}</small>
                                </div>
                            </div>
                        </div>
                        <div v-if="completedAt" class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="mb-0">Completed</h5>
                                </div>
                                <div class="col-auto">
                                    <small class="text-muted">{{completedAt.format('MMM D, YYYY h:mm:ss A')}}</small>
                                </div>
                            </div>
                        </div>
                        <div v-if="startedAt" class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="mb-0">Duration</h5>
                                </div>
                                <div class="col-auto">
                                    <small class="text-muted">{{duration}}s</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-header-title">
                    Logs
                    </h4>   
                    <div v-if="status != 'failed' && status != 'succeeded'" class="spinner-border spinner-border-sm float-end">&nbsp;</div>

                </div>
                <div class="card-body">
                    <pre v-if="logs" id="logs"><code>{{logs}}</code></pre>
                    <pre v-else><code>please wait...</code></pre>
                </div>
            </div>
            <div class="card"  v-if="status=='succeeded' && outputPaths.length">
                <div class="card-header">
                    <h4 class="card-header-title">
                    Output Paths
                    </h4>   
                </div>
                <div class="card-body">
                    <ul>
                        <li v-for="path in outputPaths" :key="path.index">{{path}}</li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
import BasicHeader from "../../components/BasicHeader.vue";
export default {
    components: { BasicHeader },
    data() {
        const jobIdPieces = this.$route.params.id.split('-')
        const executionId = jobIdPieces.pop()
        const jobName = jobIdPieces.join('-')
        return {
            name: null,
            status: null,
            statusClass: 'text-primary',
            cluster: null,
            startedAt: null,
            completedAt: null,
            failureReason: null,
            duration: 0,
            executionId: executionId,
            jobName: jobName,
            logs: "",
            logsError: false,
            breakCheck: false,
            outputPaths: [],
        }
    },
    methods: {
        handleJobChecking() {
            if (this.breakCheck) 
                return;
                
            const statusPromise = this.getExecutionStatus()
            const logsPromise = this.getExecutionLogs()

            statusPromise.finally((respones) => {
                if (this.logsError || (this.status != 'failed' && this.status != 'succeeded')) {
                    setTimeout(()=>{
                        this.handleJobChecking();
                    }, 2000)
                }
            })
        },
        getExecutionStatus() {
            return axios.get(`${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/jobs/${this.jobName}/runs/${this.executionId}/status`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                if(response.data){
                    const runData = response.data;
                    this.status = runData.status
                    if (this.status == 'succeeded') {
                        this.statusClass = 'text-success'
                    } else if (this.status == 'failed') 
                        this.statusClass = 'text-danger'
                    this.name = runData.name;
                    this.cluster = runData.cluster;
                    this.startedAt = dayjs(runData.started_at);
                    if (runData.completed_at) {
                        this.completedAt = dayjs(runData.completed_at);
                        let diff = dayjs.duration(this.completedAt.diff(this.startedAt));
                        this.duration = diff.asSeconds()
                    } else {
                        let diff = dayjs.duration(dayjs().diff(this.startedAt));
                        this.duration = Math.floor(diff.asSeconds())
                    }
                }
            });
        },
        getExecutionLogs() {
            return axios.get(`${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/jobs/${this.jobName}/executions/${this.jobName}-${this.executionId}/logs`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).catch((error) => {
                this.logsError = true
                if (error.response.data.error) 
                    //don't show pre-job errors
                    if (error.response.data.error.indexOf("the server rejected our request") != -1) 
                        this.logs = "";
                    else
                        this.logs = error.response.data.error
                else
                    this.logs = error.message
            }).then((response) => {
                if (response.data) {
                    if (this.logs != response.data.Logs) {
                        this.logs = response.data.Logs

                        const regex = /Copied to S3: WrappedArray\((.*)\)/gi
                        const matches = [...this.logs.matchAll(regex)]
                        let locations = []
                        for (const match of matches) {
                            if(match[1]) {
                                let paths = match[1].split(',').map((path) => {
                                    return 's3://'+path;
                                })
                                locations = locations.concat(paths)
                            }
                        }
                        this.outputPaths = locations

                        //scroll to bottom of logs
                        setTimeout(()=>{
                            var logsDiv = document.getElementById("logs");
                            logsDiv.scrollTop = logsDiv.scrollHeight;
                        },0)
                    }
                }
            });
        }
    },
    
    beforeMount() {
        this.handleJobChecking()
    },

    beforeUnmount() {
        this.breakCheck = true;
    }
};
</script>

<style>
    #logs{
        max-height: 60vh;
        border: 1px;
    }
</style>
<template>
    <div class="modal-card card">
        <form @submit.prevent="onGenerateToken()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Generate Token</h4>
                <!-- Close -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="card-body" >
                <form-fields v-if="!message" :fields="fields" v-model="user" />
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
                <div v-if="message">
                    {{ message }}
                </div>
            </div>
            <div class="card-footer" v-if="!message">
                <div class="text-end">
                    <submit-button
                        :fields="fields"
                        v-model="user"
                        :loading="loading"
                        btnText="Generate"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import formFields from "../FormFields.vue";
import submitButton from "../SubmitButton.vue";
import { mapActions } from "vuex";
import Fields from "../../utils/field-constants/generateTokenFields";
export default {
    components: { formFields, submitButton },
    props: {
        modalData: {
            type: [Object],
        },
    },
    data() {
        return {
            error: "",
            message: "",
            loading: false,
            user: this.modalData,
            fields: Fields,
        };
    },
    name: "AddUser",
    methods: {
        ...mapActions("admin", {
            generateToken: "generateToken",
        }),
        async onGenerateToken() {
            this.loading = true;
            try {
                await this.generateToken(this.user);
                this.message = "Success! Token has been emailed to " + this.user.email + ".";
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;

                return;
            }
            this.loading = false;
        },
    },
};
</script>

<style>
</style>
import router from '../../router'
import axios from 'axios'

const store = {
    namespaced:true,
    state: {
        autClientData: null,
        autProdClientData: null,
        currentClass: {},
        currentClassData: null,
        deploymentStatus: null,
        countries: null, 
        domainList: []   
    },
    mutations: {
        setAutClientData(state, data) {
            state.autClientData = data;
        },
        setAutProdClientData(state, data) {
            state.autProdClientData = data;
        },
        setCurrentClass(state, data) {
            state.currentClass[data.cid] = data.cls;
        },
        setCurrentClassData(state, data) {
            state.currentClassData = data;
        },
        setDeploymentStatus(state, data) {
            state.deploymentStatus = data;
        },
        setCountries(state, data) {
            state.countries = data;
        },
        setDomainList(state, data) {
            state.domainList = data;
        }  

    },

    actions: {
        async fetchClient({rootState, state, commit}) {
            return axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        fetchAutClientData({rootState, state, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setAutClientData', response.data);             
                                
            });
        },

        fetchAutProdClientData({rootState, state, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/production/clients/${rootState.currentClient.cid}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setAutProdClientData', response.data);   
            }).catch((err) =>{
                commit('setAutProdClientData', null); 
            })
        },

        setAutClassData({ commit }, data) {            
            commit('setCurrentClassData', data);
        },

        fetchAutClassData({rootState, state, commit}, cls) {
            axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/classes/${cls}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setCurrentClass', {cid:rootState.currentClient.cid, cls});
                commit('setCurrentClassData', response.data);  
            });
        },
        
        fetchDeploymentStatus({rootState, state, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/clients/${rootState.currentClient.cid}/deployment/status`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setDeploymentStatus', response.data);             
                                
            });
        },
        fetchCountries({rootState, state, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/countries`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setCountries', response.data.countries);  
            });
        },    
                   
        async updateAutClientData({rootState}, payload) {
            return axios({
                method: 'put',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}`,
                data: payload, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },
        async createAutClientClass({rootState}, payload) {
            return axios({
                method: 'post',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/classes`,
                data: payload, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },
        async updateAutClientClass({rootState}, payload) {
            return axios({
                method: 'put',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/classes/${payload.cls}`,
                data: payload.classObject, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },
        async deleteAutClientClass({rootState}, payload) {
            return axios({
                method: 'delete',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/classes/${payload.cls}`,
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },
        async activateClientConfig({rootState}) {
            return axios({
                method: 'post',
                url: rootState.mgmtUrl + `/v1/clients/${rootState.currentClient.cid}/activate`,
                data: {}, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },

        async getDomainLists({rootState,commit}) {
            axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/domainlists`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setDomainList', response.data);  
            });
        },

        async createDomainLists({rootState,commit}) {
            axios.post(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/domainlists`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setDomainList', response.data);  
            });
        },

        async updateEdgeHostname({rootState}, payload) {
            return axios({
                method: 'put',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/domainlists/${payload.edgeHostname}`,
                data: payload, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },

    }

    
}

export default store
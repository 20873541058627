<template>
    <div v-for="(field, index) in inputVal" :key="field.index" class="sub-input-group" > 
        <div class="form-group row">
            <label class="col-sm-2 col-form-label"> Name </label>
            <div class="col-sm-10">
                <input type="text" class="form-control" v-model="field['condition_name']" :disabled="!editable" :readonly="!editable">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Variable</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" v-model="field['name']" :disabled="!editable" :readonly="!editable">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Criterion</label>
            <div class="col-sm-10">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="field['criteria']"  name="PRESENCE" value="PRESENCE">
                    <label class="form-check-label" >is present</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="field['criteria']"  name="ABSENCE" value="ABSENCE">
                    <label class="form-check-label" >is absent</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="field['criteria']"  name="ONE_OF"  value="ONE_OF" >
                    <label class="form-check-label" >is one of</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="field['criteria']"  name="NOT_ANY_OF"  value="NOT_ANY_OF" >
                    <label class="form-check-label" >is not one of</label>
                </div>
            </div>
        </div> 
        <div class="form-group row" v-if="field['criteria'] =='ONE_OF' || field['criteria'] =='NOT_ANY_OF' ">
            <label class="col-sm-2 col-form-label">Values</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" :value="field['values']" @input="inputHandler(index, $event)" :disabled="!editable" :readonly="!editable">
                <!-- <input type="text" class="form-control" v-model="field['values']" :disabled="!editable" :readonly="!editable"> -->
            </div>
        </div>          
    </div>    
    <button v-show="editable" @click="addSubCondition" class="btn btn-primary mb-2">Add Sub Condition</button>  
</template>

<script>
export default {     
    computed: {
        inputVal() {
            return this.modelValue;
        }
    },    
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        editable: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: true
        }        
    },
    methods: {
        addSubCondition() {           
            this.inputVal.push({});
        },
        inputHandler(index,e) {           
           this.inputVal[index].values = e.target.value.split(',');
        }
    },
    emits:[
        "update:modelValue",
        'change'
    ]

}
</script>

<style>

</style>
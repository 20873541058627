<template>
    <div>
        <textarea :id="editorId" v-model="inputVal"></textarea>
    </div>
</template>

<script>
import CodeMirror from "codemirror";
import yaml from "js-yaml";
import jsonlint from "jsonlint";
window.jsonlint = jsonlint;
window.jsyaml = yaml;

import "codemirror/mode/javascript/javascript";
import "codemirror/mode/python/python";
import "codemirror/mode/yaml/yaml";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/yaml-lint";
import "codemirror/addon/lint/javascript-lint";
import "codemirror/addon/lint/json-lint";
import "codemirror/addon/search/searchcursor";
import eYAML from 'yaml';


import {mapActions} from 'vuex'


export default {
    name: "CodemirrorEditor",
    data() {
        return {
            subYamlCodeObj: {},
            codeObjectKey: "",
            setSubYamlCodeObj: (obj, key) => {
                this.subYamlCodeObj[key] = obj; 
                this.onEditorChange(this.editor);
            }
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit("update:modelValue", val);
            },
        },
    },
    props: {
        editorId: {
            type: String,
            default: "codeEditor",
        },
        theme: {
            type: String,
            default: "mdn-like",
        },
        mode: {
            type: String,
            default: "javascript",
        },
        type: {
            type: String,
            default: "yaml",
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        refreshOn: {
            type: Boolean,
            default: false,
        },
        modelValue: [Object, String],
        advancedProps: {
            type: Array,
            default: () => []
        },
        editorType: {
            type: String
        }
    },
    watch: {
        // inputVal() {
        //     this.updateValue(this.inputVal);
        // },
        refreshOn(val) {
            var self = this
            if(val && !this.isRefreshed){
                setTimeout(()=>{                    
                    self.editor.refresh()
                },0);
                this.isRefreshed = true
            }            
        },
        readOnly(val) {
            const readOnlyOption = val ? "nocursor" : false
            this.editor.setOption("readOnly", readOnlyOption);
        }
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
        }),
        updateValue(value) {
            let newValue = value;            
            if(this.editor && this.type == "yaml" && value ) {
                newValue = (typeof value != "string")? eYAML.stringify(newValue) : newValue;
                if(this.editorType !== 'advanced_props') {
                    let advancedPropsYaml = {};
                    let doc = eYAML.parseDocument(newValue); 
                    let counter = 0;
                    let self = this;
                    // Walks through tree starting from node, calling a visitor function
                    // Ref: https://eemeli.org/yaml/#modifying-nodes
                    eYAML.visit(doc, {
                        Pair(_, pair, path) { 
                            counter++;                
                            if (pair.key && pair.key.value === 'code'){  
                                self.subYamlCodeObj["code_link_"+counter] = pair.value.value; 
                                pair.value.value = "code_link_"+counter;
                                return undefined; // Do nothing and continue
                            } else if(self.advancedProps.length>0) {
                                self.advancedProps.forEach((attr) => {
                                    if(pair.key && pair.key.value === attr) {                                        
                                        advancedPropsYaml = {...advancedPropsYaml, ...pair.toJSON()};
                                    }

                                    return undefined;

                                }); 
                            } else { 
                                return (pair.value.items);
                            }
                        }                    
                    });
                   
                   // Set adavnacedPropsYaml in store
                    self.$store.dispatch("sandboxUnitTest/setAdvancedPropsYaml", advancedPropsYaml);
                    
                    
                    // Remove advacned props from doc
                    doc = self.removeNodes(doc, self.advancedProps);
                    
                    newValue = doc.toString(); 
                }
                
            }
           
            if(this.editor && newValue && newValue != this.editor.getValue()) {
                this.editor.getDoc().setValue(newValue);
            }
            
            if(this.type == 'yaml') {
                this.makeCodeViewBtn('code_link');
            }
        },
        handleLintErrors(unsortedErrors, sortedErrors) {
            const isError = (sortedErrors.length)? true : false;
            this.$emit('lintFailure', isError);           
        },

        removeNodes(doc, keys) {
            keys.forEach((attr) => {
                if(doc.has(attr)) {                                       
                    doc.delete(attr);    
                }
            });
            return doc;
        },

        addNodes(doc, keys) {
            let self = this;
            keys.forEach((key) => {               
                try {
                    const map  = doc.createPair( key,  self.$store.state.sandboxUnitTest.advancedPropsYaml[key]);                   
                    doc.add(map);
                } catch(e) {
                    console.log(e);
                }
            });
            return doc;
        },

        // Search and mark given range of text with a node
        // Ref: https://codemirror.net/doc/manual.html#markText
        makeCodeViewBtn(text) {
            var cursor = this.editor.getSearchCursor(text, CodeMirror.Pos(this.editor.firstLine(), 0), {caseFold: true, multiline: true});
            while(cursor.findNext()) {
                var word = this.editor.findWordAt(cursor.from());
                var codeKey = this.editor.getRange(word.anchor, word.head);                  
                var self = this;
                var button = document.createElement('button');
                var btnText = document.createTextNode("View Code");
                button.className = "btn btn-primary btn-sm";
                button.appendChild(btnText);            
                button.onclick = function () {                                      
                    self.showModal({ component: 'codemirrorModal' ,  data: {subYamlType:"python", codeKey, saveMethod:self.setSubYamlCodeObj}, value: self.subYamlCodeObj[codeKey]  }); 
                };       
                this.editor.markText(word.anchor, word.head, {replacedWith: button})
            }
        },

        onEditorChange(editor) {
            let newVal = editor.getValue();
            let doc = "";
            if(this.type == "yaml") { 
                try {                    
                    doc = eYAML.parseDocument(newVal);
                    // Print stringified JSON
                    // console.log(JSON.stringify(doc.toJSON()))
                } catch(e) {
                    console.log(e);
                }
                
                let self = this;
                // Walks through tree starting from node, calling a visitor function
                // Ref: https://eemeli.org/yaml/#modifying-nodes
                eYAML.visit(doc, {
                    Pair(_, pair, path) {           
                        if (pair.key && pair.key.value === 'code'){ 
                            let code_link = pair.value.value;                             
                            if(self.subYamlCodeObj[code_link]) {
                                  pair.value.value = self.subYamlCodeObj[code_link];
                            }
                            return undefined;
                        } else { 
                            return (pair.value.items);
                        }                        
                    }                    
                });
               //Add advanced props back to doc
               if(this.advancedProps && this.advancedProps.length > 0) {
                    doc = this. addNodes(doc, this.advancedProps);                    
               }               
                
                newVal = doc.toString(); 
            } 

            this.$emit("update:modelValue", newVal);

        }

    },
    mounted() {        
        const self = this;        
        this.editor = CodeMirror.fromTextArea(
            document.getElementById(this.editorId),
            {
                lineNumbers: true, 
                theme: this.theme,              
                mode: this.mode, 
                matchBrackets: true, 
                readOnly: this.readOnly ? "nocursor" : false,           
                gutters: ["CodeMirror-linenumbers", "CodeMirror-lint-markers"],                
                lint: {
                "onUpdateLinting": self.handleLintErrors
                }
            }
        );        
       
        this.editor.on("change", (editor) => {
            self.onEditorChange(editor);           
        });

        this.updateValue(this.inputVal);
    },
};
</script>

<style>
</style>
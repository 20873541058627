<template>
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">{{pretitle}}</h6>
            <!-- Title -->
            <h1 class="header-title">{{title}}</h1>
          </div>
          <!-- Button -->
          <div class="col-auto">
            <span v-if='editable'>
                <span v-if='editMode'>
                    <button @click="deleteClick" class="btn btn-danger"> Delete </button>&nbsp;
                    <button @click="saveClick" class="btn btn-success"> Save </button>&nbsp;
                    <button @click="cancelClick" class="btn btn-secondary"> Cancel </button>&nbsp;
                </span>
                <span v-else>
                    <button @click="editClick" class="btn btn-info"> Edit </button>&nbsp;
                </span>
            </span>
            <button @click="closeClick" class="btn btn-secondary"> <span class="fe fe-x"></span> </button>
            
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
</template>

<script>
export default {
    name: 'DetailHeader',
    props: {
        'pretitle': String,
        'title': String,
        'editable': {
            type: Boolean,
            default: true
        },
        'editMode': {
            type: Boolean,
            default: false
        }
    },
    methods:{
      closeClick() {
        this.$emit('closeClick')
      },
      editClick() {
        this.$emit('editClick')
      },
      saveClick() {
        this.$emit('saveClick')
      },
      cancelClick() {
        this.$emit('cancelClick')
      },
      deleteClick() {
        this.$emit('deleteClick')
      },

    },
    emits: [
      'closeClick',
      'deleteClick',
      'editClick',
      'saveClick',
      'cancelClick'
    ]
};
</script>

<style>
</style>
<template>
  <div><p>Placeholder for run History</p></div>  
</template>

<script>
export default {
    name: "Run History",
     props:["job"]

}
</script>

<style>

</style>
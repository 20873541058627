<template>
    <basic-header
        pretitle="Administration"
        title="Users"
        buttonText="Add User"
        @btnClick="showModal({ component: 'addUser' })"
    />

    <div class="container-fluid">
        <div class="card">
            <datatable
                :columns="columns"
                :data="$store.state.admin.users"
                @deleteRows="showDeleteUsers"
                :deletable="true"
                :compact="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'users/', identifier:'email'}"
            />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapMutations } from "vuex";

export default {
    name: "Clients",
    components: { BasicHeader, Datatable },
    data() {
        return {
            columns: [
                { data: "name" },
                { data: "email" },
                {
                    data: "roles",
                    render: function (data, type, row) {
                        if (!data) return "";
                        return Array.isArray(data) ? data.join(" ") : data;
                    },
                },
                {
                    data: "cids",
                    render: function (data, type, row) {
                        if (!data) return "";
                        return Array.isArray(data) ? data.join(" ") : data;
                    },
                },
            ],
        };
    },
    beforeMount() {
        this.$store.dispatch("admin/fetchUsers");
    },
    methods: {
        showDeleteUsers(rows) {
            this.showModal({ component: "deleteUsers", data: rows });
        },
        ...mapActions({
            showModal: "showModal",
        }),
    },
};
</script>

<style scoped>
</style>
<template>    
    <basic-header
        pretitle="Aqfer Marketing Data Platform"
        title="Sandbox"
        buttonText="Create Sandbox"
        @btnClick="showModal({ component: 'addSandbox' })"
    />

    <div class="container-fluid">
        <div class="card">
            <datatable
                :columns="columns"
                :data="$store.state.sandbox.sandboxes"
                :deletable="true"
                @deleteRows="showDeleteSandboxes"
                :compact="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'', identifier:'name'}"
            />
        </div>
    </div>
</template>
<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapMutations , mapState} from "vuex";

export default {
    components: { BasicHeader, Datatable },
    data() {
        return {            
            columns: [
                { data: "name",
                  title: "Sandbox Name"
                 },
                { data: "env", 
                  title: "Env"
                },{
                    data: "cluster_name",
                    title: "cluster_name"
                },{
                    data: "k8s_namespace",
                    title: "K8s Namespace"
                }               
            ],
        };
    },
    computed: mapState({
        cid: state => state.currentClient.cid        
    }),
    watch: {
        cid() {
            this.fetchSandboxes();   
        }
    },    
    beforeMount() {
        this.fetchSandboxes();
    },
    methods: {
        showDeleteSandboxes(rows) {            
            this.showModal({ component: "deleteSandboxes", data: rows });
        },
        ...mapActions({
            showModal: "showModal",
        }),
        fetchSandboxes() {
            this.$store.dispatch("sandbox/fetchSandboxes");
        }
    }   

}
</script>
<template>
  <ul v-if="Object.keys(yamlContent).length > 1" class="nav nav-tabs">
    <li v-for="(val, key) in yamlContent" :key="key" class="nav-item">
      <button
        @click="tabChange(`${key}`)"
        :class="[activeYamlTab == `${key}` ? 'active' : '', 'nav-link']"
      >
        {{ key }}
      </button>
    </li>
  </ul>
  <div class="tab-content" :id="jobType+'TabContent'">
    <div
      v-for="(val, key) in yamlContent"
      :key="key"
      :class="[activeYamlTab == `${key}` ? 'show active' : '', 'tab-pane fade']"
    >
      <codemirror-editor
        v-on:lintFailure="onLintFailure"
        :editorId="key"
        mode="yaml"
        v-model="yamlContent[key]"
        :advancedProps= "advancedProps[key]"
        :refreshOn="activeYamlTab == `${key}`"
      />
    </div>
  </div>
</template>

<script>
import CodemirrorEditor from "../CodemirrorEditor.vue";
export default {
  components: { CodemirrorEditor },
  data() {
    return {
      activeYamlTab: this.activeTab,
    };
  },
  computed: {
    yamlContent: {
      get() {
        return this.modelValue;
      },
    },
  },
  props: {
    iframeSrc: String,
    yamlList: Array,
    modelValue: Object,
    activeTab: String,
    jobType: String,
    advancedProps: Object
  },
  methods: {
    onLintFailure(isError) {
      this.$emit("onLintFailure", isError);
    },
    tabChange(tab) {
      this.activeYamlTab = tab;
      this.$emit("tabChange", tab);
    }
  },
  emits: ['onLintFailure', 'tabChange']
};
</script>

<style scoped>
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-link {
  padding: 0.5rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.nav-link {
  border-color: #b1c2d9;
  text-transform: capitalize !important;
}
.nav-tabs .nav-item {
  margin-left: 0rem;
  margin-right: 0rem;
}
</style>
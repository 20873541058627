<template>
    <button class="btn btn-primary" :disabled="buttonState">
        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else>
            {{btnText}}
        </div>       
    </button>    
</template>
<script>
import formValidation from "../utils/formValidation";
export default {
    name: "submitButton",    
    computed: {
        buttonState() {            
            const { errors } = formValidation();            
            let disabled = true;
            for (let field of this.fields) {
                //Return true if field is required and not in modelValue or if validation is failed
                if ((field.visible && field.validation && (field.validation.filter(e => e.type === 'required').length > 0) && (!this.modelValue[field.name] && this.modelValue[field.name] !== 0) )||(errors[field.name] && !errors[field.name].passed)) {
                    disabled = true;
                    break;
                }
                disabled = false;
            }

            if (this.loading) 
                disabled = true;

            return disabled;
        }
    },
    props: {
        fields: Object,
        modelValue: Object,
        loading: {
            type: Boolean,
            default: false,
        },  
        btnText: {
            type: String,
            default: "Submit",
        },  
    }
    
    
}
</script>

<style scoped>
.btn {
    min-width: 10em;
}
</style>
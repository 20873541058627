<template>
  <basic-header pretitle="aqfer Graph Manager" title="Dashboard" />
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
export default {
  components: { BasicHeader },

}
</script>

<style>

</style>
<template>
  <basic-header pretitle="aqfer Universal Tag" title="Tag Dashboards" />
  <div class="container-fluid" id="tag_dashboard">
    <div class="row">
        <div class="col-md-12">
            <div class="mb-2">Choose a Class</div>
                <button
                 style="margin-right:10px"
                    v-for="classItem in classItems"
                    :key="classItem.cls == 'NA' ? 'default' : classItem.cls"
                    @click="currentCls != classItem.cls? currentCls = classItem.cls : currentCls = null"
                    class="btn class-btn card-header-title btn-secondary"
                    :class="[currentCls == classItem.cls ? 'active' : '']"
                >
                    {{ classItem.cls == "NA" ? "default" : classItem.cls }}
                </button>
            </div>
    </div>
    <div class="row"> 
        <div class="col-md-2">
            <input-field
            label="Trend"
            type="select"
            :options="trendOptions"
            v-model="trend"
        />
        </div>
        <div class="col-md-3" v-if="trend == 'Hourly'">
            <input-field label="Date(UTC)" type="calendar" v-model="date" :inputConfig="config" />
        </div>
        <div class="col-md-2" v-if="trend == 'Hourly' && tagTypesUnique.length">  
            <label class="form-label"> <span>Tags Type</span></label>          
            <select 
                class="form-select"
                label="Tags Type"
                v-model="tagType">                    
                <option
                    v-for="option in tagTypesUnique"
                    :key="option"
                    :value="option"
                >
                    {{ option }}
                </option>
            </select>        
        </div>
        <div class="col-md-3" v-if="trend == 'Hourly'&& countriesUnique.length" >
            <div class="form-group" >
                <label class="form-label"> <span>Country</span></label>
                <select 
                class="form-select"
                label="Country"
                v-model="country">                    
                    <option
                        v-for="option in countriesUnique"
                        :key="option"
                        :value="option"
                    >
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3" v-if="trend == 'Daily'">
            <input-field label="Start Date(UTC)" type="calendar" v-model="startDate" :inputConfig="config" />
        </div>
        <div class="col-md-3" v-if="trend == 'Daily'">
            <input-field label="End Date(UTC)" type="calendar" v-model="endDate" :inputConfig="config" />
        </div>
        <div class="col-md-2 ms-auto">
            <div class="form-group">
                <label class="form-label">&nbsp;</label>
                <div class="text-end">
                    <button class="btn btn-primary" @click="generateDashboard">
                        Generate</button
                    ><span>&nbsp;</span>                   
                </div>
            </div>
        </div>
    </div>    
    <div v-if="!graphLoading">
        <div class="row" v-if="trend == 'Hourly'">
            <div class="card  col-md-12">
                <div class="card-body">
                    <div :class="[(tagTypesUnique.length)?'hide':'']">
                        <p>{{errMsg}}</p>
                    </div>                    
                    <div class="chart-view" :class="[(!tagTypesUnique.length)?'hide':'']" >
                        <canvas  id="countChart" ></canvas>
                    </div>                    
                </div>
            </div>
        </div>
        <div v-if="trend == 'Daily'">
            <div class="form-group row">
                <label for="thresholdTagtype" class="col-sm-1 col-form-label">Threshold:</label>
                <div class="col-sm-2">
                    <input type="number" class="form-control" id="thresholdTagType" v-model="threshold_tagType" step="1000">
                </div>
            </div>
            <div class="row">
                <div class="card  col-md-12">
                    <div class="card-body">
                        <div :class="[Object.keys(tempTtObj).length && !(dailyChartMsg['ttDaily'])?'hide':'']">
                            <p>{{dailyChartMsg['ttDaily']}}</p>
                        </div>
                        <div class="chart-view" :class="[(!Object.keys(tempTtObj).length || dailyChartMsg['ttDaily'])?'hide':'']" style="position: relative; height:40vh; width:80vw">
                            <canvas  id="ttDaily"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="thresholdPartnerBeacon" class="col-sm-1 col-form-label">Threshold:</label>
                <div class="col-sm-2">
                    <input type="number" class="form-control" id="thresholdPartnerBeacon" v-model="threshold_partnerBeacon" step="1000">
                </div>
            </div>
            <div class="row">
                <div class="card  col-md-12">
                    <div class="card-body">
                        <div :class="[(Object.keys(tempPdObj).length && !dailyChartMsg['pdDaily'])?'hide':'']">
                            <p>{{dailyChartMsg['pdDaily']}}</p>
                        </div>
                        <div class="chart-view" :class="[(!(Object.keys(tempPdObj).length) || dailyChartMsg['pdDaily'])?'hide':'']" style="position: relative; height:40vh; width:80vw">
                            <canvas  id="pdDaily"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="thresholdRedirect" class="col-sm-1 col-form-label">Threshold:</label>
                <div class="col-sm-2">
                    <input type="number" class="form-control" id="thresholdRedirect" v-model="threshold_redirect" step="1000">
                </div>
            </div>
            <div class="row">
                <div class="card  col-md-12">
                    <div class="card-body">
                        <div :class="[( Object.keys(tempRdObj).length && !dailyChartMsg['rdDaily'])?'hide':'']">
                            <p>{{dailyChartMsg['rdDaily']}}</p>
                        </div>
                        <div class="chart-view" :class="[(!(Object.keys(tempRdObj).length) || dailyChartMsg['rdDaily'])?'hide':'']" style="position: relative; height:40vh; width:80vw">
                            <canvas  id="rdDaily" ></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div v-else class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    
    
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);




import BasicHeader from '../../components/BasicHeader.vue'
import InputField from "../../components/InputField.vue";
import dayjs from "dayjs";
import axios from 'axios';
import { mapActions, mapState} from "vuex";
export default {
    components: { BasicHeader, InputField },
    data() {
        return {
            classItems: null,
            currentCls: null,
            config: {
                wrap: true, 
                dateFormat: 'm-d-Y',
            },
            date: dayjs().format("MM/DD/YYYY"),
            trendOptions: [
                {label:"Hourly", value:"Hourly"},
                {label:"Daily", value: "Daily"}
            ],
            graphLoading: true,
            trend: "Hourly",            
            tagTypesUnique:[],
            countriesUnique: [],
            tagType: 'partner_beacon',
            country: "All",
            NO_OF_RETRY: 3,           
            startDate: dayjs().subtract(8, "day").format('MM/DD/YYYY'),
            endDate: dayjs().subtract(1, "day").format('MM/DD/YYYY'),
            tempTtObj: {},
            tempPdObj: {},
            tempRdObj: {},
            threshold_tagType: 1000,
            threshold_partnerBeacon: 1000,
            threshold_redirect: 1000,
            errMsg:'',
            dailyChartMsg: []

        };
    },
  
    mounted() {
        this.readClient();
    },
    watch: {
        cid() {
           this.graphLoading = true;
           this.readClient();
           this.generateDashboard();
        },
        currentCls() {
            this.generateDashboard();
        },
        trend() {
            this.generateDashboard();
        },
        date() {
            this.generateDashboard();
        },
        tagType() {
            this.startDrawHourlyGraph("countChart",this.tempObjT, this.tempObjY, this.tempObjLWT, "All" );
        },
        country(newCountry) {
            this.startDrawHourlyGraph("countChart",this.dataHTodayCc, this.dataHYesterdayCc, this.dataHLastweekThisDayCc, newCountry);
        },
        threshold_tagType() {
            this.startDrawDailyGraph("ttDaily", this.tempTtObj, this.dailyCountsLabels, this.threshold_tagType, "Tag Type");
        },
        threshold_partnerBeacon() {
            this.startDrawDailyGraph("pdDaily", this.tempPdObj, this.dailyCountsLabels, this.threshold_partnerBeacon, "Partner Domain");
        },
        threshold_redirect() {            
            this.startDrawDailyGraph("rdDaily", this.tempRdObj, this.dailyCountsLabels, this.threshold_redirect, "Redirect Domain");
        }

            
    },
    computed: {
     ...mapState({    
            cid: state => state.currentClient.cid       
        }),
        thisDay() {
            return dayjs(this.date).format('YYYYMMDD');    
        },
        previousDay() {
             return dayjs(this.date).subtract(1, "day").format('YYYYMMDD');
        },
        lastweek_thisday() {
            return dayjs(this.date).subtract(7, "day").format('YYYYMMDD');
        },
        fromDate() {
            return dayjs(this.startDate).format('YYYYMMDD');   
        },
        toDate() {
            return dayjs(this.endDate).format('YYYYMMDD');  
        }
    },
    methods: {
    ...mapActions({
            fecthClient: "aut/fetchClient"
        }),
        
        async readClient() {
            let response;
            try {
                response = await this.fecthClient();
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }

            const clientData = await response.data;
            this.classItems = clientData.classes.items;   
            this.currentCls = this.classItems[0].cls;
        },
        async generateDashboard() {
            this.graphLoading = true;
            let clsString = '';           
            if(this.currentCls) {
                clsString = '/clss/' + this.currentCls;
            }

            if(this.trend == "Hourly") {  
                this.tempObjLWT= {};
                this.tempObjT= {};
                this.tempObjY= {};         
               
                this.UniqTtObj = {};
                this.UniqCcObj = {};
                this.dataDailyTTH = [];
                this.dataHLastweekThisDay = [];
                this.dataHToday = [];
                this.dataHYesterday = [];
                this.dataHLastweekThisDayCc =[];
                this.dataHTodayCc = [];
                this.dataHYesterdayCc = [];
                this.tagTypesUnique = [];
                this.countriesUnique = [];

                const dates = `${this.lastweek_thisday},${this.previousDay},${this.thisDay}`;
                let response;
                try {
                    response = await this.countsByDates(
                                clsString,
                                dates
                                )
                } catch(error) {
                    this.graphLoading = false;
                    if (error.response.data.error)
                        this.errMsg = error.response.data.error;
                    else this.errMsg = error.message;
                    return;
                }  
                  
                this.graphLoading = false;
                if(!response.data || Object.keys(response.data).length === 0) {                    
                    this.errMsg = "No Data Found";
                } else {
                     let countsData = await response.data;
                
                    for (var hr in countsData) {                    
                        if (this.$store.state.environment !== "test" || hr.length > 8) {
                            switch (hr.substring(0, 8)) {
                            case this.lastweek_thisday:
                                this.transformData(hr.substr(-2), countsData[hr].tagType, this.tempObjLWT);
                                break;
                            case this.thisDay:
                                this.transformData(hr.substr(-2), countsData[hr].tagType, this.tempObjT);
                                break;
                            case this.previousDay:
                                this.transformData(hr.substr(-2), countsData[hr].tagType, this.tempObjY);
                                break;
                            default:                            
                                this.transformData(hr.substr(-2), countsData[hr].tagType, this.tempObjY);
                                break;
                            }
                        }                    
                    }

                    for (var k1 in this.tempObjT) {                    
                        this.UniqTtObj[k1] = 1;                    
                        this.dataHToday.push({
                            id: k1,
                            total: this.tempObjT[k1].total,
                            values: this.tempObjT[k1].values
                        });                    
                    }
                    for (var k2 in this.tempObjY) {                   
                        this.UniqTtObj[k2] = 1;                    
                        this.dataHYesterday.push({
                            id: k2,
                            total: this.tempObjY[k2].total,
                            values: this.tempObjY[k2].values
                        });                    
                    }
                    for (var k3 in this.tempObjLWT) {                    
                        this.UniqTtObj[k3] = 1;                    
                        this.dataHLastweekThisDay.push({
                            id: k3,
                            total: this.tempObjLWT[k3].total,
                            values: this.tempObjLWT[k3].values
                        });                    
                    } 

                    this.tagTypesUnique = [];
                    for (var key in this.UniqTtObj) {                        
                        this.tagTypesUnique.push(key);                        
                    }
                    
                    this.tagType = 'partner_beacon';
                    this.tagTypesUnique.sort();
                    if (this.tagTypesUnique.indexOf('partner_beacon') === -1) {
                        this.tagType = this.tagTypesUnique[0]
                    }

                    this.startDrawHourlyGraph('countChart',this.tempObjT, this.tempObjY, this.tempObjLWT, "All" );

                } 

                this.getCounts(clsString, this.tempObjT, this.thisDay, this.NO_OF_RETRY);
                this.getCounts(clsString, this.tempObjY, this.previousDay, this.NO_OF_RETRY);
                this.getCounts(clsString, this.tempObjLWT, this.lastweek_thisday, this.NO_OF_RETRY);


            } else if(this.trend == "Daily") {
                this.dailyCountsLabels = [];
                const diffDays = this.dateDiffInDays(this.fromDate, this.toDate);
                if(diffDays > 95) {
                    this.errMsg = "Request Denied. Requested date range is more than 3 months.";
                    return;
                }

                let response;
                try {
                    response = await this.dailyCounts(
                                clsString,
                                this.fromDate,
                                this.toDate
                            )
                } catch(error) {
                    this.graphLoading = false;
                    if (error.response.data.error)
                        this.errMsg = error.response.data.error;
                    else this.errMsg = error.message;
                    return;
                }

                
                this.graphLoading = false;
                let dailyCountsData = await response.data;
                if (!dailyCountsData) {                    
                    return
                }
                this.tempTtObj = {};
                this.tempPdObj = {};
                this.tempRdObj = {};
                for (var day in dailyCountsData) {                    
                    this.dailyCountsLabels.push(dayjs(day).format('MMM-DD'));
                    this.transformData(day, dailyCountsData[day].tagType, this.tempTtObj);
                    this.transformData(day, dailyCountsData[day].partnerBeacon, this.tempPdObj);
                    this.transformData(day, dailyCountsData[day].redirect, this.tempRdObj);
                }

                this.startDrawDailyGraph('ttDaily', this.tempTtObj, this.dailyCountsLabels, this.threshold_tagType, "Tag Type");
                this.startDrawDailyGraph('pdDaily', this.tempPdObj, this.dailyCountsLabels, this.threshold_partnerBeacon, "Partner Domain");
                this.startDrawDailyGraph('rdDaily', this.tempRdObj, this.dailyCountsLabels, this.threshold_redirect, "Redirect Domain");
                
            }      

        },
        transformData(date, src, dst, extraSrc) {
            // Transform data in to a more d3 like data { id: id, values: [{}]}
            var counts = {};
            for (var c in src) {            
                var m = c.match(/^(([a-z]-[a-z0-9]*)|NA):(.*)$/);
                var c2 = m ? (m[2] ? (m[3] + ' (' + m[2] + ')') : m[3]) : c;
                if (!extraSrc) {
                    counts[c] = src[c]
                } else if (extraSrc[c]) {
                    counts = JSON.parse(JSON.stringify(extraSrc))
                    counts[c]["All"] = src[c];
                } else {
                    counts[c] = {};
                    counts[c]["All"] = src[c];
                }
                if (c2 in dst) {
                    dst[c2].values.push({
                        date: date,
                        count: counts[c]
                    });
                    dst[c2].total += src[c];
                } else {
                    dst[c2] = {
                        total: src[c],
                        values: [{
                        date: date,
                        count: counts[c]
                        }]
                    };
                }
            
            }
        },

        async getCounts(clsString, tempObjDef, day, retryCounts) {
            let self = this;
            await this.countsByDate(
                            clsString,
                            day
            ).then(function (response){
                if (response.status == -1) {
                    if (retryCounts > 0) {
                        retryCounts--
                        self.getCounts(clsString, tempObjDef, day, retryCounts)
                    }
                } else {
                    var tempObj = {};
                    if (!response.data) {
                        self.graphLoading = false;
                        return
                    }
                    var data = response.data;
                    for (var date in data) {                        
                        if (self.$store.state.environment !== "test" || date.length > 8) {
                            switch (date.substring(0, 8)) {
                                case day:
                                    self.transformData(date.substr(-2), data[date].tagType, tempObj, data[date].tagType_countryCode);
                                    break;
                                default:
                                    if (self.$store.state.environment === "test") {
                                        self.transformData(date.substr(-2), data[date].tagType, tempObjDef, data[date].tagType_countryCode);
                                    }
                                    break;
                            }
                        }

                        for (let cc in data[date].countryCode) {
                            if (cc.length < 15) {
                                self.UniqCcObj[cc] = 1;
                            }
                        }                        
                    }

                    switch (day) {
                        case self.thisDay:
                            self.dataHTodayCc = tempObj;
                            for (const k1 in tempObj) {                                
                                self.UniqTtObj[k1] = 1;          
                            }
                            break;
                        case self.previousDay:
                            self.dataHYesterdayCc = tempObj;
                            for (const k1 in tempObj) {                                
                                self.UniqTtObj[k1] = 1;     
                            }
                            break;
                        case self.lastweek_thisday:
                            self.dataHLastweekThisDayCc = tempObj;
                            for (var k1 in tempObj) {                                
                                self.UniqTtObj[k1] = 1;             
                            }
                            break;
                    }
                    
                    self.tagTypesUnique = [];
                    for (const key in self.UniqTtObj) {                        
                        self.tagTypesUnique.push(key);                        
                    }
                    self.countriesUnique=[];

                    for (const key in self.UniqCcObj) {                    
                        self.countriesUnique.push(key);
                    }
                    
                    self.countriesUnique.sort();
                    self.countriesUnique.unshift("All");
                    

                }
            })
        },
        

        startDrawHourlyGraph(elementId, thisDayData, previousDayData, prevWeekThisDayData, country ) {
            if(!thisDayData || !previousDayData ||  !prevWeekThisDayData)
                return;

            const countChart = document.getElementById(elementId);
            const chart = Chart.getChart(elementId);
            if(chart) {
                chart.destroy();
            }
            

            const labels = ["00","01","02","03","04","05","06", "07", "08", "09", "10", "11","12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
            const gData = {
                labels: labels,
                datasets: [{
                    label: 'Previous Week This Day',
                    backgroundColor: 'rgb(255, 99, 132)', 
                    borderColor: 'rgb(255, 99, 132)',
                    data: prevWeekThisDayData[this.tagType]? prevWeekThisDayData[this.tagType].values.map((obj) => { 
                        return {'x':obj.date, 'y':obj.count[country] || obj.count}
                    }) : 0,
                    pointStyle: 'circle',
                    pointRadius: 3
                },{
                    label: 'Previous Day',
                    backgroundColor: 'rgb(12, 196, 2)',
                    borderColor: 'rgb(12, 196, 2)',
                    data: previousDayData[this.tagType]? previousDayData[this.tagType].values.map((obj) => { 
                        return {'x':obj.date, 'y':obj.count[country] ||obj.count}
                    }): 0,
                    pointStyle: 'circle',
                    pointRadius: 3
                },{
                    label: 'This Day',
                    backgroundColor: 'rgb(1, 94, 255)',
                    borderColor: 'rgb(1, 94, 255)',
                    data: thisDayData[this.tagType]? thisDayData[this.tagType].values.map((obj) => { 
                        return {'x':obj.date, 'y':obj.count[country] ||obj.count}
                    }): 0,
                    pointStyle: 'circle',
                    pointRadius: 3
                }                   

                ]
            };

            const options= {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: 'Hourly Chart'
                    },
                    tooltip: {
                        usePointStyle: true,
                    }
                }
            }

            const config = {
                type: 'line',
                data: gData,
                options: options
            }

            if (countChart) {
                new Chart(countChart, config);
            }            

        },

        startDrawDailyGraph(elementId, countData, labels, threshold, name) {
            const self = this;
            this.dailyChartMsg[elementId] = null;
            const countDataKeys = Object.keys(countData);
            let dailyCountData = JSON.parse(JSON.stringify(countData));
          
            dailyCountData = Object.fromEntries(Object.entries(dailyCountData).
                            filter(([key, val]) => {
                                return val.total > threshold; 
                            }));
            const dailyChart = document.getElementById(elementId);
            const chart = Chart.getChart(elementId);
            if(chart) {
                chart.destroy();
            }

            const colors = [
                "#015eff", "#0cc402", "#fc0a18", "#ff15ae", "#d99f07", "#11a5fe",
                "#037e43", "#ba4455", "#d10aff", "#08bbbb", "#7b6d2b", "#9354a6",
                "#b54e04", "#2d7593", "#e19772", "#fa7fbe", "#fe035b", "#95b42d",
                "#aea0db", "#905e76", "#92b27a", "#03c262", "#878aff", "#4a7662",
                "#ff6757", "#fe8504", "#9340e1", "#2a8602", "#07b6e5", "#d21170",
                "#526ab3", "#ff08e2", "#bb2ea7", "#e4919f", "#09bf91", "#90624c",
                "#bba94a", "#a26c05", "#5c7605", "#df89e7", "#b0487c", "#ee9345",
                "#70b458", "#b19b71", "#6b6d74", "#ec5206", "#85a7c7", "#ff678c",
                "#b55b3e", "#8054cc", "#7eb0a0", "#c480b3", "#d9102d", "#5a783f",
                "#fe66d2", "#bc13c8", "#62bd33", "#b8ab03", "#8f31ff", "#fd8581",
                "#ff5167", "#8f9a7f", "#d68201", "#8b9054"
            ];

            let datasets = [];
            for (let prop in dailyCountData) {
                const keyIndex = countDataKeys.findIndex((key) => key === prop );
                const color = colors[keyIndex];
                datasets.push({
                    label: prop,
                    backgroundColor: color,
                    borderColor: color,
                    data: dailyCountData[prop].values.map((obj) => {
                        return {'x': dayjs(obj.date).format('MMM-DD'), 'y': obj.count}
                    }),
                    pointStyle: 'circle',
                    pointRadius: 3
                });
            } 

            if(!datasets.length) {
                this.dailyChartMsg[elementId] = "No Data Found";
                return;
            }

            const gData = {
                labels: labels,
                datasets: datasets
            };

            const options= {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: name
                    },
                    tooltip: {
                        usePointStyle: true,
                    }
                }
            }

            const config = {
                type: 'line',
                data: gData,
                options: options,
                backgroundColor: colors
            }

            if (dailyChart) {
                new Chart(dailyChart, config);
            }            

        },

        dateDiffInDays(fromDate, toDate) {
            let hours = dayjs(toDate).diff(dayjs(fromDate), 'hours');
            const diffDays = Math.floor(hours / 24);
            return diffDays;
        },

        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },       

        async countsByDates(clsString, dates) {
            return axios.get(
                `${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/dates/${dates}?hourly=true&skipCountry=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        },

        async countsByDate(clsString, date) {
            return axios.get(
                `${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/dates/${date}?hourly=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                    timeout: 60000
                }
            ).then((response) => response )
            .catch((error) => {
                error.response.status = -1;
                return error.response; 
            });
        },

        async dailyCounts(clsString, fromDate, toDate) {
            return axios.get(
                `${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/from/${fromDate}/00/until/${toDate}/23?daily=true&skipCountry=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        }        
    }  
}
</script>

<style scoped>
#tag_dashboard .row {
    margin-bottom: 1.5rem;
}
.class-btn.active {
    background-color: #ffffff;
    color: #586a82;
    border-color: #ffffff;
}
.hide {
    display: none;
}
</style>
function getClassFields() { 
    return[
        {
           
            name: "description",
            label: "Description",
            type: "text",
            editable: true,
            visible: true,
        },       
        {
           // Load Type is based on pixel batch size
            name: "loadType",
            label: "Load Type",
            type: "radio",
            editable: true,
            options:[
                { label: "Batch Load", value: "BATCH_LOAD" },
                { label: "Lazy Load", value: "LAZY_LOAD" }
            ],
            visible: true,

        },
        { 
            name: "initLoad",
            label: "Initial Load",
            type: "number",
            editable: true,
            visible: true,
            tooltip: 'Maximum number of pixels to fire when the page loads for the first time',
            suppressIfField: 'loadType', suppressIfValueContains: ["LAZY_LOAD"]
        },
        {
            name: "subsLoad",
            label: "Subsequent Loads",
            type: "number",
            editable: true,
            visible: true,
            tooltip: 'Maximum number of pixels to fire when the page loads for subsequent times.',
            suppressIfField: 'loadType', suppressIfValueContains: ["LAZY_LOAD"]
        },
        {
            name: 'pixelBatchSize',
            label: 'Batch Size',
            type: 'number',
            editable: true,
            visible: true,
            tooltip: 'No of pixels to load after "Batch Period" seconds. If the user stays on the page',
            suppressIfField: 'loadType', suppressIfValueContains: ["BATCH_LOAD"]
          },
          {
            name: 'pixelBatchPeriod',
            label: 'Batch Period',
            type: 'number',
            editable: true,
            visible: true,
            tooltip: 'Time (in seconds) after which to load additional set of pixels.',
            suppressIfField: 'pixelBatchSize', suppressIfValueContains: [0]
          },
        {
           
            name: "pkMap",
            label: "Key-Value Map",
            type: "object",
            editable: true,
            visible: true,
            tooltip: 'Key-value map encoded in JSON. These can be used, for example, to refer to redirect-URL\nin r pixel ' +
            'by using pk=<key> query parameter instead of hard-coded ru=<url>.'
        },
        {
           
            name: "enabledRegions",
            label: "Enabled Regions",
            type: "array",
            editable: true,
            visible: true,
            tooltip: 'List of regions that will have direct non ESI files served along with region info.'
        },
        {
            name: "logCookies",
            label: "Log Request Cookies",
            type: "radio",
            editable: true,
            visible: true,
            options:[
                { label: "ALL", value: "ALL" },
                { label: "SPECIFIC", value: "SPECIFIC" }
            ] 
        },
        {
            name: "cookieList",
            label: "Specific Cookies to log",
            toolTip: 'List of case sensitive comma separated cookies to log',
            type: "text",
            editable: true,
            visible: true,
            suppressIfField: 'logCookies', 
            suppressIfValueContains: ['ALL'] 
        },
        {
            name: "log",
            label: "Log",
            type: "object",
            parseObject: true,
            editable: true,
            visible: true
        },
        {
            name: "logBeacon.mobile",
            label: "Log Beacon(Mobile)",
            type: "object",
            parseObject: true,
            editable: true,
            visible: true

        },
        {
            name: "logBeacon.std",
            label: "Log Beacon(Standard)",
            type: "object",
            parseObject: true,
            editable: true,
            visible: true

        },
        {
            name: "baseClass",
            label: "Based on Class",
            type: "text",
            editable: true,
            visible: true
        },
        {
            name: 'maxPixels',
            label: 'Maximum Pixels from Base Class',
            type: 'number',
            tooltip: 'Maximum pixels from base class to inherit',
            suppressIfField: 'baseClass', suppressIfValueContains: ['NONE'],
            visible: true
        },
        {   // Not avilable in Old Portal
            name: "rotations",
            label: "Rotation Configs",
            type: "object",
            editable: true,
            visible: true
        }
    ]

}

export default getClassFields;
<template>
    <div v-if="error" class="row">
        <div class="col-md-12">
            <p><code>{{error}}</code><code>{{response}}</code></p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <job-editor v-on:onLintFailure="setRunJobBtnState" v-on:tabChange="tabChange" :advancedProps="advancedProps" :jobType="jobType" v-model="yamlContent" :activeTab="activeTab" ></job-editor>  
        </div>
        <div class="col-md-4">
            <div class="text-end mb-4">
                <button @click="runJob()" class="btn btn-primary w-100" :disabled="runClicked || isButtonDisabled" style="width:200px;">
                    <div v-if="runClicked" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else>
                        Run {{jobType}} Job
                    </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                </button>
            </div> 
            <documentation-viewer :iframeSrc="iframeSrc"></documentation-viewer>     
        </div>
    </div>       
</template>

<script>
import axios from "axios";
import JobEditor from '../JobEditor.vue';
import DocumentationViewer from '../DocumentationViewer.vue'
import macros from "../../../utils/macros";

export default {
    components: { JobEditor, DocumentationViewer },
    data() { 
        return {       
            runClicked: false,
            response: '',
            error: '',
            isButtonDisabled: false,
        };
    },    
    computed: {
        yamlContent: {
            get() {
                return this.modelValue;
            },
        },
    },
    props: {
        jobType: String,
        activeTab: String,
        iframeSrc: String,
        modelValue: Object,
        advancedProps: Object        
    },
    methods: {
        runJob(){
            this.runClicked = true
            const self = this;
            var payload = {};

            const replacements = {
                cid: this.$store.state.currentClient.cid
            }
            
            //Prepare yamls for submission
            if (this.$store.state.sandboxUnitTest.jobType == 'mapper') {
                payload.parameters = {
                    JobConf: this.applyMacrosAndFormatting(this.yamlContent.jobconf, replacements),
                    DataChannels: this.applyMacrosAndFormatting(this.yamlContent.dataChannels, replacements),
                    Mappings: this.applyMacrosAndFormatting(this.yamlContent.mappings, replacements),
                    Credentials: this.applyMacrosAndFormatting(this.yamlContent.credentials, replacements),
                }
            } else {
                payload.parameters = {
                    ConfigContent: this.applyMacrosAndFormatting(this.yamlContent.config, replacements),
                }
            }

            // Handle advanced options and enviornment variables
            let cluster = "aqfer-prod-eks";
            if (this.$store.state.lakeviewUrl.indexOf('preprod') != -1) {
                cluster = "aqfer-preprod-eks";
            }

            if (this.$store.state.sandboxUnitTest.region) {
                cluster += "-" + this.$store.state.sandboxUnitTest.region;
            }

            let jobName;
            if (this.$store.state.sandboxUnitTest.jobType == 'mapperv2') {
                jobName = "transform-sandbox-collator"
            } else {
                jobName = "sandbox-" + this.$store.state.sandboxUnitTest.jobType;
            }
            
            if (this.$store.state.sandboxUnitTest.environment != 'prod') {
                jobName = this.$store.state.sandboxUnitTest.environment + "-" + jobName;
            }

            if(this.$store.state.sandboxUnitTest.jobNameOverride) {
                jobName = this.$store.state.sandboxUnitTest.jobNameOverride
            }

            // Make job run request
            const promise = axios
                .post(`${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/jobs/${jobName}/executions?cluster=${cluster}`, payload, {
                    headers: {
                        'Authorization': `Bearer ${this.$store.state.accessToken}`
                    }
                })
                .then((res) => {
                    this.response = JSON.stringify(res.data, null, 4);
                    if (res.data.execution_id) {
                        let executionPath = `${this.$route.path}/${res.data.execution_id}`
                        executionPath = executionPath.replace("//", "/"); //prevent doubleslash
                        self.$router.push(executionPath);
                    }
                }) 
                .catch((error) => {
                    self.error = error.message;
                    if (error.response.data && error.response.data.error) {
                        self.error += `: ${error.response.data.error}`
                    }
                })
                .finally(() => {
                    this.runClicked = false;
                })
        },
        applyMacrosAndFormatting(value, replacements) {
            if (!value)
                return;

            value = macros.process(value, "~~xx~~", replacements);

            return "\\n    " + value.replaceAll("\n", "\\n    ");
        },
        setRunJobBtnState(btnState) {
            this.isButtonDisabled = btnState; 
        },
        tabChange(tab) {
            this.$emit("tabChange", tab);
        }
    }
};
</script>

<style>
</style>
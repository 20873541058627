import axios from 'axios'

const store = {
    namespaced:true,
    state: {
        jobs: [],
        currentJob: null,
        currentCredential: null,
        currentSchema: null,
        currentMapping: null,
        currentDataChannel: null,
        credentials: [],
        schemas: [],
        mappings: [],
        dataChannels: [],
    },
    mutations: {
        setCurrentJob(state, job) {
            state.currentJob = job
        },
        setCurrentCredential(state, credential) {
            state.currentCredential = credential
        },
        setCurrentSchema(state, schema) {
            state.currentSchema = schema
        },
        setCurrentMapping(state, mapping) {
            state.currentMapping = mapping
        },
        setCurrentDataChannel(state, dataChannel) {
            state.currentDataChannel = dataChannel
        },
        setJobs(state, jobs) {
            state.jobs = jobs
        },
        setSchemas(state, schemas) {
            state.schemas = schemas
        },
        setMappings(state, mappings) {
            state.mappings = mappings
        },
        setCredentials(state, credentials) {
            state.credentials = credentials
        },
        setDataChannels(state, dataChannels) {
            state.dataChannels = dataChannels
        },
    },
    actions: {
        setCurrentJob({commit}, job) {
            commit('setCurrentJob', job)
        },
        setCurrentCredential({commit}, credential) {
            commit('setCurrentCredential', credential)
        },
        setCurrentSchema({commit}, schema) {
            commit('setCurrentSchema', schema)
        },
        setCurrentMapping({commit}, mapping) {
            commit('setCurrentMapping', mapping)
        },
        setCurrentDataChannel({commit}, dataChannel) {
            commit('setCurrentDataChannel', dataChannel)
        },
        fetchJobs({rootState, state, commit}) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/config/jobs`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let jobs = response.data;
                jobs = Object.keys(jobs).map(function (key) {
                    return { 
                        ...jobs[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ').replace(/-/g, ' ')
                    }
                });
                commit('setJobs', jobs)
                if (!state.currentJob)
                    commit('setCurrentJob',jobs[0])
            });
        },
        fetchMappings({rootState, state, commit}) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/config/mappings`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let data = response.data

                let schemas = data.schemas
                schemas = Object.keys(schemas).map(function (key) {
                    return { 
                        ...schemas[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ')
                    }
                });
                commit('setSchemas', schemas)
                if (!state.currentSchema)
                    commit('setCurrentSchema',schemas[0])

                let mappings = data.schema_mappings
                mappings = Object.keys(mappings).map(function (key) {
                    return { 
                        ...mappings[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ')
                    }
                });
                commit('setMappings', mappings)
                if (!state.currentMapping)
                    commit('setCurrentMapping',mappings[0])
            });
        },
        fetchCredentials({rootState, state, commit}) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/config/credentials`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let credentials = response.data[rootState.currentClient.cid];
                credentials = Object.keys(credentials).map(function (key) {
                    return { 
                        ...credentials[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ')
                    }
                });
                commit('setCredentials', credentials)
                if (!state.currentCredential)
                    commit('setCurrentCredential',credentials[0])
            });
        },
        fetchDataChannels({rootState, state, commit}) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/config/data_channels`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let channels = response.data[rootState.currentClient.cid];
                channels = Object.keys(channels).map(function (key) {
                    return { 
                        ...channels[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ')
                    }
                });
                commit('setDataChannels', channels)
                if (!state.currentDataChannel)
                    commit('setCurrentDataChannel',channels[0])
            });
        },
    }
}

export default store
<template>
  <nav-header
        :navItems="[
            { name: 'General Settings', link: '/aut/admin/general-settings' },
            { name: 'Classes', link: '/aut/admin/classes' },
            { name: 'Generate Tag', link: '/aut/admin/generate-tag' },
            { name: 'Configure Hosts', link: '/aut/admin/configure-hosts' },
            { name: 'Configure CNames', link: '/aut/admin/configure-cnames', disable: true }
        ]"
        pretitle="aqfer Universal tag"
        title="AUT Admin"
    />
    <div class="container-fluid" id="aut-admin-div" v-if="clientData ">
        <router-view :cid="cid" :clientData="clientData" ></router-view>
    </div>
    <div v-else class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div> 
</template>

<script>
import NavHeader from "../../../components/NavHeader.vue";
import { mapState } from "vuex";
export default {
  components: { NavHeader },
  computed: {
     ...mapState({    
            cid: state => state.currentClient.cid,
            clientData: state => state.aut.autClientData,
            domainList: state => state.aut.domainList
        })       
  },
  watch: {
        cid() {
          this.fetchAutData();
        }
  },
  
  methods: {
    fetchAutData() {
      this.$store.dispatch("aut/fetchAutClientData"); 
      this.$store.dispatch("aut/fetchDeploymentStatus");
      this.$store.dispatch("aut/fetchCountries"); 
      this.$store.dispatch("aut/getDomainLists");
      this.$store.dispatch("aut/fetchAutProdClientData");       
    }
  },
  beforeMount() {
    this.fetchAutData();
  }
}


</script>

<style>
#aut-admin-div .form-control, #aut-admin-div .form-select {
    width: 66.66667%;
}

#aut-admin-div .form-control:disabled, #aut-admin-div .form-control[readonly], #aut-admin-div .form-select:disabled {
    border: none;
    background-color: #FFFFFF;;
    color: #999;
    opacity: unset !important;
}

#aut-admin-div .header-body{
  border-bottom: none;
  padding-bottom: 0rem;

}

#aut-admin-div .form-group[disabled=true] {
  border-bottom: 2px solid ;
  border-color: #edf2f9BD;
}

.sub-input-group {
  border-bottom: 2px solid ;
  border-color: #edf2f9BD;
  margin-bottom: 0.5rem;
}

.sub-input-group:last-of-type {
  border: none;
}

.form-group:last-child {
  border: none;
}

.child-form-group {
  border: none;
}

.form-check-label {
    opacity: unset !important;
}
.form-check-inline {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#aut-admin-div .form-group.row {   
    margin-bottom: 5px;   
}
h4 {  
  text-transform: capitalize !important;
  padding-top: 0.75rem;
}
.card-title .btn {
  float: right;
  margin-left: 0.5em;;

}
.tooltip-inner {
  white-space:nowrap;
  max-width:none;
}

</style>
<template>
    <nav-header
        :navItems="[
            { name: 'Sign Ins', link: '/admin/reports/sign-ins' },
        ]"
        pretitle="Administration"
        title="Reports"
    />
    <div class="container-fluid">
        <router-view></router-view>
    </div>
</template>

<script>
import NavHeader from "../../../components/NavHeader.vue";
export default {
    components: { NavHeader },
};
</script>

<style>
</style>
<template>
    <div class="row">
        <div class="col-md-3">
            <div class="card">
                <datatable
                    tableId="dataChannels"
                    :columns="columns"
                    :data="this.$store.state.amdp.dataChannels"
                    :selectedItem="this.$store.state.amdp.currentDataChannel"
                    :lengthChange="false"
                    :info="false"
                    v-on:selected="handleSelect"
                />
            </div>
        </div>
        <div class="col-md-9" v-if="$store.state.amdp.currentDataChannel">
            <input-field
                label="Data Channel Name"
                v-model="$store.state.amdp.currentDataChannel.id"
            />
            <input-field
                label="Data Channel Type"
                type="select"
                v-model="$store.state.amdp.currentDataChannel.type"
                :options="dataChannelTypes"
            />

            <div
                v-if="
                    $store.state.amdp.currentDataChannel.type == 's3' ||
                    $store.state.amdp.currentDataChannel.type == 's3aq'
                "
            >
                <input-field
                    label="Base Path"
                    v-model="$store.state.amdp.currentDataChannel.base_path"
                />
                <input-field
                    label="Credentials"
                    v-model="
                        $store.state.amdp.currentDataChannel.credentials
                    "
                />
                <input-field
                    label="Bucket"
                    v-model="$store.state.amdp.currentDataChannel.bucket"
                />
                <input-field
                    label="Format"
                    v-model="$store.state.amdp.currentDataChannel.format"
                />
                <input-field
                    label="Options"
                    v-model="$store.state.amdp.currentDataChannel.options"
                />
                <input-field
                    label="Partition Key Names"
                    v-model="
                        $store.state.amdp.currentDataChannel
                            .partition_key_names
                    "
                />
                <input-field
                    label="Pattern"
                    v-model="$store.state.amdp.currentDataChannel.pattern"
                />
            </div>
            <div
                v-if="
                    $store.state.amdp.currentDataChannel.type ==
                    'athena_table'
                "
            >
                <input-field
                    label="Account Owner"
                    v-model="
                        $store.state.amdp.currentDataChannel.account_owner
                    "
                />
                <input-field
                    label="Database Name"
                    v-model="
                        $store.state.amdp.currentDataChannel.database_name
                    "
                />
                <input-field
                    label="Table Name"
                    v-model="$store.state.amdp.currentDataChannel.table_name"
                />
                <input-field
                    label="Pattern"
                    v-model="$store.state.amdp.currentDataChannel.pattern"
                />
                <input-field
                    label="S3 Bucket"
                    v-model="$store.state.amdp.currentDataChannel.s3_bucket"
                />
                <input-field
                    label="S3 Base Path"
                    v-model="
                        $store.state.amdp.currentDataChannel.s3_base_path
                    "
                />
                <input-field
                    label="S3 Credentials"
                    v-model="
                        $store.state.amdp.currentDataChannel.s3_credentials
                    "
                />
            </div>
            <div v-if="$store.state.amdp.currentDataChannel.type == 'api'">
                <input-field
                    label="Provider"
                    v-model="$store.state.amdp.currentDataChannel.provider"
                />
                <input-field
                    label="Credentials"
                    v-model="
                        $store.state.amdp.currentDataChannel.credentials
                    "
                />
            </div>
            <div v-if="$store.state.amdp.currentDataChannel.type == 'aio'">
                <input-field
                    label="API Domain"
                    v-model="$store.state.amdp.currentDataChannel.api_domain"
                />
                <input-field
                    label="Auth Domain"
                    v-model="
                        $store.state.amdp.currentDataChannel.auth_domain
                    "
                />
                <input-field
                    label="Credentials"
                    v-model="
                        $store.state.amdp.currentDataChannel.credentials
                    "
                />
                <input-field
                    label="Entitiy Domain Column Name"
                    v-model="
                        $store.state.amdp.currentDataChannel
                            .entity_domain_column_name
                    "
                />
                <input-field
                    label="Entitiy ID Column Name"
                    v-model="
                        $store.state.amdp.currentDataChannel
                            .entity_id_column_name
                    "
                />
                <input-field
                    label="Entitiy Type Column Name"
                    v-model="
                        $store.state.amdp.currentDataChannel
                            .entity_type_column_name
                    "
                />
                <input-field
                    label="Format"
                    v-model="$store.state.amdp.currentDataChannel.format"
                />
                <input-field
                    label="Host"
                    v-model="$store.state.amdp.currentDataChannel.host"
                />
                <input-field
                    label="Prefix"
                    v-model="$store.state.amdp.currentDataChannel.prefix"
                />
            </div>
            <div
                v-if="$store.state.amdp.currentDataChannel.type == 'derived'"
            >
                <input-field
                    label="Partition Key Values"
                    v-model="
                        $store.state.amdp.currentDataChannel
                            .partition_key_values
                    "
                />
            </div>
        </div>
    </div>
</template>

<script>
import Datatable from "../../../components/Datatable.vue";
import InputField from "../../../components/InputField.vue";
export default {
    components: { Datatable, InputField },
    name: "DataChannels",
    data() {
        return {
            columns: [{ data: "name" }],
            dataChannelTypes: [
                { label: "s3", value: "s3" },
                { label: "s3aq", value: "s3aq" },
                { label: "Athena Table", value: "athena_table" },
                { label: "API", value: "api" },
                { label: "AIO", value: "aio" },
                { label: "Derived", value: "derived" },
            ],
        };
    },
    methods: {
        handleSelect(data) {
            this.$store.dispatch("amdp/setCurrentDataChannel", data);
        },
    },
};
</script>

<style>
</style>
<template>
    <div><p>Placeholder for {{job.name}} configuration</p></div>  
</template>

<script>
export default {
    name: "Configuration",
    props:["job"]

}
</script>

<style>

</style>
<template>
    <detail-header
        pretitle="Clients"
        :title="client ? client.name : '&nbsp;'"
        :editable="true"
        :editMode="editMode"
        @editClick="onEditClick"
        @deleteClick="onDeleteClick"
        @saveClick="onSaveClick"
        @cancelClick="onCancelClick"
        @closeClick="$router.push('/admin/clients')"
    />

    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div v-if="error" class="alert alert-danger" role="alert">
                    {{ error }}
                </div>
                <div
                    v-if="successMessage"
                    class="alert alert-success"
                    role="alert"
                >
                    {{ successMessage }}
                </div>
                <div v-if="client" class="card mb-5">
                    <div class="card-body">
                        <form-fields
                            :fields="fields"
                            v-model="client"
                            :editMode="editMode"
                        />
                    </div>
                </div>
                <div v-else class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import Fields from "../../utils/field-constants/clientEditFields";
import { mapActions } from "vuex";
import FormFields from "../../components/FormFields.vue";
export default {
    name: "Client",
    components: { DetailHeader, FormFields },
    data() {
        return {
            editMode: false,
            client: null,
            snapshot: null,
            error: "",
            successMessage: "",
            fields: Fields,
        };
    },
    beforeMount() {
        this.fetchClient();
    },
    methods: {
        ...mapActions({
            readClient: "admin/readClient",
            updateClient: "admin/updateClient",
            deleteClient: "admin/deleteClient",
            showModal: "showModal",
        }),
        setEditMode(mode) {
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        async fetchClient() {
            let response;
            try {
                response = await this.readClient(this.$route.params.id);
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }

            this.client = await response.data;
            this.client.eTag = response.headers['etag']
            this.snapshot = { ...this.client };
        },
        onCancelClick() {
            this.setEditMode(false);
            this.client = { ...this.snapshot };
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true);
        },
        onDeleteClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete Client",
                    message: `Are you sure you want to delete ${this.client.name}?`,
                    async confirm() {
                        try {
                            const response = await self.deletClient(
                                self.client
                            );
                            self.$router.push("/admin/clients");
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    },
                },
            });
        },
        async onSaveClick() {
            var self = this;
            try {
                const response = await this.updateClient(this.client);
                this.setEditMode(false);
                this.snapshot = { ...this.client };
                this.successMessage = "Client updated successfully.";
                // fetch client again to reset the eTag
                this.fetchClient();
            } catch (error) {
                console.log(error.response.data.error)
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }
        },
    },
};
</script>

<style>
</style>
<template>
    <basic-header
        pretitle="Aqfer Marketing Data Platform"
        title="Sandbox"        
    />

    <div class="container-fluid job-list-view">
        <input-field
            label="Choose a Sandbox"
            type="select"
            :options="sandBoxListOptions"
            v-model="selectedSandbox"
        />
        <div class="row mb-1">
            <div class="col-md-8">
                <p> </p>
                <p>Jobs List</p>
            </div>
            <div class="col-md-4">
                <div class="text-end">
                    <button @click="showModal({ component: 'addSandboxJob' })" class="btn lift btn-primary" >
                         Create Job 
                    </button>                  
                </div>
            </div>
        </div>
        <div class="card">
            <datatable
                :columns="columns"
                :data="$store.state.sandbox.sandboxJobs"               
                :deletable="true"
                @deleteRows="showDeleteSandboxes"
                :compact="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:`${selectedSandbox}/jobs/`, identifier:'name'}"
            />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import InputField from "../../components/InputField.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
    components: { BasicHeader, InputField, Datatable},
    data() {
        return {
            jobList: [], 
            columns: [
                { 
                    data: "name",
                    title: "Job Name"
                },
                { 
                    data: "kind", 
                    title: "Job Type"
                },
                {
                    data: "created_by", 
                    title: "Created By"
                }           
            ]
        };
    },
    computed: {
        selectedSandbox: {
            get() {
                return this.$store.state.sandbox.currentSandbox;
            },
            set(value) {
                this.$store.dispatch('sandbox/setCurrentSandbox',value);               
                this.fetchSandboxJobs();
            }

        },
        sandBoxListOptions() {
            return  this.$store.state.sandbox.sandboxes.map((sandbox) => {
                return {"label": sandbox.name, "value": sandbox.name };                
            })
        },     
        ...mapState({    
            cid: state => state.currentClient.cid 
        }) 
    }, 
    watch: {
        cid() {
            this.fetchSandboxes();   
        },
        sandBoxListOptions(newSandboxList) {
            const currentStateSandbox = this.$store.state.sandbox.currentSandbox;
            if(newSandboxList.filter(function(e) { return e.value === currentStateSandbox; }).length > 0){
               this.selectedSandbox = currentStateSandbox;
            } else {
                this.selectedSandbox =  (newSandboxList.length)?newSandboxList[0].value:'';
            }                     
        }
    }, 
       
    beforeMount() {        
        this.fetchSandboxes();   
    },
       
    methods: {       
        ...mapActions({
            fetchSandboxJobs: "sandbox/fetchSandboxJobs",
            showModal: "showModal",
        }),  
        showDeleteSandboxes(rows) {            
            this.showModal({ component: "deleteSandboxJobs", data: rows });
        },
        fetchSandboxes() {
            this.$store.dispatch("sandbox/fetchSandboxes");
        },
        setCurrentSandbox(sandbox){
            this.$store.dispatch('sandbox/setCurrentSandbox',sandbox)
        }
    }   
};
</script>

<style scoped>
    .job-list-view::v-deep select {text-transform:capitalize !important}  
</style>
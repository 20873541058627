<template>
	<div>
		<div class="row">
			<div class="col-md-8">
				<form @submit.prevent="onGenerateTag()">
					<div class="card-title clearfix">
						<h4 style="display: inline-block">AUT Script Tag</h4>
						<span>
							<code>
							<submit-button
								:fields="fields"
								v-model="tagObject"
								btnText="Generate Tag"
								@tagEvent="setMessage($event)"
							/></code>
						</span>

					</div>
					<div class="card">
						<div class="card-body">
							<div v-if="error" class="text-danger">
								{{ error }}
							</div>
							<div v-if="message">
								{{ message }}
							</div>
							<form-fields
								:fields="fields"
								v-model="tagObject"
								:inlineField="true"
								:editMode="true"
							/>
						</div>
					</div>
				</form>
				<button @click="addVariable" class="btn btn-secondary mb-2">
					Add Variable
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Fields from "../../../utils/field-constants/autGenerateTagFields";
import FormFields from "../../../components/FormFields.vue";
import submitButton from "../../../components/SubmitButton.vue";
import { mapActions, mapState } from "vuex";
import { throwStatement } from "@babel/types";

export default {
	components: { FormFields, submitButton },
	data() {
		return {
			error: "",
			message: "",
			tagObject: {},
			fields: Fields,
			varLength: 0,
		};
	},
	computed: {
		...mapState({
			domainList: (state) => state.aut.domainList,
		}),
	},
	props: {
		cid: String,
		clientData: Object,
		deployStatus: Object,
	},
	methods: {
		...mapActions({
			getDomainList: "aut/getDomainLists",
			showModal: "showModal",
		}),
		onGenerateTag() {
			let scriptTagObject = { ...this.tagObject };
			scriptTagObject.label = this.clientData.label;
			scriptTagObject.vars = [];

			for (const [key, value] of Object.entries(
				this.tagObject.vars
			)) {
				scriptTagObject.vars.push({ k: key, v: value });
			}

			this.showModal({
				component: "ScriptTagView",
				data: scriptTagObject,
			});
		},
		addVariable() {
			const varField = {
				name: `vars.v${this.varLength}`,
				label: `v${this.varLength}`,
				type: "text",
				editable: true,
				visible: true,
			};
			this.fields.push(varField);
			this.varLength++;
		},
	},
	mounted() {
		this.tagObject.vars = {};
		this.tagObject.lm = "a";
		this.tagObject.tt = "t.dhj";

		let domainOptions = [];
		this.domainList.map((domainObj) => {
			if (domainObj.domains && domainObj.domains.items) {
				domainObj.domains.items.map((domainItem) => {
					domainOptions.push({
						label: domainItem.name,
						value: domainItem.name,
					});
				});
			}
		});

		this.fields = this.fields.map((field) => {
			if (field.name == "cdmn") {
				field.options = domainOptions;
			} else if (field.name == "lm") {
				let lmOptions = "abcdefghijklm".split("").map((option) => {
					return { label: option, value: option };
				});
				field.options = lmOptions;
			} else if (field.name == "tt") {
				let ttOptions =
					"t.dhj,tc.dhj,tcs.dhj,c.js,cs.js,s.js,c.gif,a.gif,r.gif"
						.split(",")
						.map((option) => {
							return { label: option, value: option };
						});
				field.options = ttOptions;
			} else if (field.name == "cls") {
				let clsOptions = this.clientData.classes.items.map(
					(clsObj) => {
						return { label: clsObj.cls, value: clsObj.cls };
					}
				);
				field.options = clsOptions;
			}
			return field;
		});
	},
};
</script>

<style>
</style>

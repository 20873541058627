<template>
    <div class="row">
        <div v-if="error" class="col-md-12">
            <p><code>{{error}}</code></p>
        </div>
        <div v-if="response" class="col-md-12">
            <p><code>{{response}}</code></p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <job-editor v-on:onLintFailure="onLintFailure" :jobType="jobType" v-model="yamlContent" :activeTab="activeTab"></job-editor>  
        </div>
        <div class="col-md-4">  
          <!-- Enable save button if needed -->
          <!-- <div class="text-end mb-3">
                <button @click="saveJob()" class="btn btn-primary"  style="width:200px;">
                    <div v-if="runClicked" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else>
                        Save Job
                    </div>
                </button>
            </div>            -->
          <documentation-viewer :iframeSrc="iframeSrc"></documentation-viewer>     
        </div>
    </div> 
</template>

<script>
import axios from "axios";
import JobEditor from '../../../components/sandbox/JobEditor.vue';
import DocumentationViewer from '../../../components/sandbox/DocumentationViewer.vue'
import { mapActions } from "vuex";

export default {
    name: "YAML Editor",
    components: {JobEditor, DocumentationViewer},
    data() { 
        let yamlContent = {};
        let activeTab ="";
        let yamlListFromStore;
        if(this.job.kind === "MAPPER") {
            yamlListFromStore = this.$store.state.sandbox.mapperFileList; 
        } else if(this.job.kind === "COLLATOR") {
            yamlListFromStore = this.$store.state.sandbox.collatorFileList;
        } else if( this.job.kind === "CONNECTOR") {
            yamlListFromStore = this.$store.state.sandbox.connectorFileList;
        }

        activeTab = yamlListFromStore[0];
        
        yamlListFromStore.map((yamlKey) => {          
            yamlContent[yamlKey]  = (this.job.config_files)?this.job.config_files[yamlKey]:undefined;  
        });
        

        return {
            jobObj: this.job,
            jobType: this.job.kind,
            activeTab: activeTab,            
            runClicked: false,
            response: '',
            error: '',
            isButtonDisabled: false,
            yamlContent: yamlContent
        };
    }, 
    computed: {      
      iframeSrc() {       
        if(this.job.kind === "MAPPER") {
           return  `${this.$store.state.docsUrl}/docs/amdp/services/data-integration-service/mapper/examples.html?docsToken=${this.$store.state.docsToken}#jobconfyaml`;
        } else if(this.job.kind === "COLLATOR") {
           return `${this.$store.state.docsUrl}/docs/amdp/services/collation-service/collator/examples.html?docsToken=${this.$store.state.docsToken}`;        
        } else if(this.job.kind === "CONNECTOR") {
            return `${this.$store.state.docsUrl}/docs/amdp/services/data-integration-service/connector/examples.html?docsToken=${this.$store.state.docsToken}`;
        } else {
           return "";
        }
      }
    },  
    props:["job", "runJobTrigger"],
    watch: {
      runJobTrigger() {
        this.saveJob();
        this.runJob();
      }
    },
    methods: {
       ...mapActions({
            readSandboxJob: "sandbox/readSandboxJob",
            updateSandboxJob: "sandbox/updateSandboxJob"
        }),
        async saveJob() {
            try {              
                this.jobObj.config_files = this.yamlContent;
                this.jobObj.config_files = (typeof this.job.config_files === 'string')?JSON.parse(this.job.config_files):this.job.config_files;
                const response = await this.updateSandboxJob(this.jobObj);
                this.response = 'Job updated successfully.'
            } catch (error) {
                if (error.response.data && error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            } finally {
                this.$emit('setRunClick', false);
            }
            
        },
        runJob(){
            //Placeholder for run job apis   
              
        },
        onLintFailure(isError) {
            this.$emit("onLintFailure", isError);
        } 
    },
    emits: ['onLintFailure', 'setRunClick']  

}
</script>

<style>

</style>
<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddUser()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add User
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields v-if="!message" :fields="fields" v-model="user" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
                <div v-if="message">
                    {{ message }}
                </div>
        </div>
        <div class="card-footer" v-if="!message">
            <div class="text-end">
                <submit-button :fields="fields" v-model="user" :loading="loading" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/userFields"
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            message: "",
            user: {},            
            fields: Fields
        }
    },
    name: "AddUser",    
    methods: {
        ...mapActions('admin', {
            createUser: 'createUser'
        }),
        async onAddUser() {
            this.loading = true;
            try {
                await this.createUser(this.user)
                this.message = "Success! A Welcome email has been sent to " + this.user.email + ".";
            } catch (error) {                
                // if (error.response.data.error) 
                //     this.error = error.response.data.error
                // else 
                //     this.error = error.message

                this.error = "Failed to send welcome email to " + this.user.email + ". Please contact support.";
                
                return
            }

            this.loading = false;

            //this.$store.dispatch('hideModal')
            this.$store.dispatch('admin/fetchUsers')
        }
    }
};
</script>

<style>
</style>
<template>
    <div class="modal-card card">
        <form @submit.prevent="onCreateSandbox()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Create Sandbox
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="sandboxfields" v-model="sandBox" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="sandboxfields" v-model="sandBox" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import getSandboxFields from "../../utils/field-constants/sandboxFields"
export default {
    components: { formFields, submitButton },
    data(){        
        return {
            error: "",
            sandBox: {},            
            sandboxfields: []
        }
    },
    name: "AddSandbox",  
    created(){        
        this.sandboxfields = getSandboxFields();        
    },  
    methods: {
        ...mapActions("sandbox", {
            createSandbox: "createSandbox"
        }),
        async onCreateSandbox() {
            try {
                await this.createSandbox(this.sandBox)
            } catch (error) {                
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }

           this.$store.dispatch('hideModal')
           this.$store.dispatch('sandbox/fetchSandboxes')
        }
    }
};
</script>

<style>
</style>
<template>
    <div class="header">
        <div class="container-fluid">
            <!-- Body -->
            <div class="header-body">
                <div class="row align-items-end">
                    <div class="col">
                        <!-- Pretitle -->
                        <h6 class="header-pretitle">{{ pretitle }}</h6>
                        <!-- Title -->
                        <h1 class="header-title">{{ title }}</h1>
                    </div>
                </div>

                <slot name="cards-content"></slot>              

                <!--- Nav Tabs -->
                <div class="row">
                    <div class="col-12">
                        <ul class="nav nav-tabs header-tabs">
                            <li v-for="item in navItems" :key="item.index" class="nav-item" :class="[(($route.name != item.name) && item.disable)?'hide':'']">
                                <router-link :to="item.link" :class="[$route.name == item.name ? 'active' : '', 'nav-link text-center']">
                                    {{item.name}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- / .row -->
            </div>
            <!-- / .header-body -->
        </div>
    </div>
</template>

<script>
export default {
    name: "NavHeader",
    props: {
        pretitle: String,
        title: String,
        navItems: Array
    }
};
</script>

<style scoped>
.hide {
    display: none;
}
</style>
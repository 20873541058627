export default {

    // data should be an array of objects
    exportCSV: (filename, data) => {
        let csvContent = "data:text/csv;charset=utf-8,";

        let columns = Object.keys(data[0])
        columns = columns.join(",");
        csvContent += columns + "\r\n";
        
        data.forEach(function(object) {
            let values = Object.values(object)
            let row = values.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename+".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
        link.remove();
    }

}
const Fields = [
    {
        name: "email",
        label: "Email",
        type: "text",
        editable: false,
        validation: [
            { type: "email"}, 
            { type: "required" }
        ]
    }, {
        name: "cids",
        label: "CIDs",
        type: "array",
        editable: true,
        validation: [
            { type: "required" }
        ]

    },{
        name: "roles",
        label: "Roles",
        type: "array",
        editable: true,
        validation: [
            { type: "required" }
        ]
    }
];

export default Fields;


<template>
    <div
        class="modal fade"
        id="modalBasic"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <component :is="$store.state.modalComponent" :modalShown="modalShown" :modalData="modalData" :key="modalKey" :modalValue="modalValue"/>
            </div>
        </div>
    </div>
</template>

<script>
import BootstapModal from 'bootstrap/js/dist/modal';

//Possible components
import AddUser from './modals/AddUser.vue';
import GenerateToken from './modals/GenerateToken.vue';
import DeleteClients from './modals/DeleteClients.vue';
import DeleteUsers from './modals/DeleteUsers.vue';
import DeleteClass from './modals/DeleteClass.vue';
import AddClient from './modals/AddClient.vue';
import AddClass from './modals/AddClass.vue';
import Confirm from './modals/Confirm.vue';
import DeleteSandboxes from './modals/DeleteSandboxes.vue';
import DeleteSandboxJobs from './modals/DeleteSandboxJobs.vue';
import AddSandbox from './modals/AddSandbox.vue';
import AddSandboxJob from './modals/AddSandboxJob.vue';
import CodemirrorModal from './modals/CodemirrorModal.vue';
import DiffViewModal from './modals/DiffView.vue';
import AddEdgeHost from './modals/AddEdgeHost';
import ScriptTagView from './modals/ScriptTagView';


export default {
    components: { AddUser, GenerateToken, DeleteClients, DeleteUsers, DeleteClass, AddClient, AddClass, Confirm, DeleteSandboxes, AddSandbox, AddSandboxJob, DeleteSandboxJobs, CodemirrorModal, DiffViewModal, AddEdgeHost, ScriptTagView},
    name: "Modal",
    data() {
        return {
            modalShown: false,
            modalEl: null,
            modal: null,
            modalKey: null,
            modalData: this.$store.state.modalData,
            modalValue:this.$store.state.modalValue
        }
    },
    mounted() {
        this.modalEl = document.getElementById("modalBasic");
        this.modal = new BootstapModal(this.modalEl);
        this.modalData = this.$store.state.modalData;
        this.modalValue = this.$store.state.modalValue;
        this.modalEl.addEventListener('shown.bs.modal', (e)=>{
            this.modalShown = true;
        });
        this.modalEl.addEventListener('hidden.bs.modal', (e)=>{
            this.modalShown = false;
        });
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === "setShowModal") {
                if (state.showModal === true) {
                    this.modalKey = Math.floor(Math.random() * 1000); //force reset component
                    this.modal.show()
                } else {
                    this.modal.hide()
                }
                this.modalData = state.modalData
                this.modalValue = state.modalValue
            }
        });
    },
    beforeUnmount() {
        this.unsubscribe();
    }
};
</script>

<style>
</style>
<template>
<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div
        class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center"
      >
        <div><img src="../assets/logo2.png" style="width:250px" class="mx-auto d-block mb-3" alt="aqfer logo" /></div>
        <!-- Heading -->
        <!-- <h1 class="display-4 text-center mb-3">Sign in</h1> -->

        <!-- Subheading -->
        <p class="text-muted text-center">Client and Administration Portal</p>

        <!-- Submit -->
        <button
          @click="loginClicked()"
          class="btn btn-lg w-100 btn-primary mb-3"
          :disabled="isLoginClicked"
        >
          <div v-if="isLoginClicked" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Sign in using Google</span>
        </button>

        <!-- Link -->
        <p class="text-center">
          <small class="text-muted text-center">
            Need Access? Contact us at <a href="mailto:support@aqfer.com">support@aqfer.com</a>.<br/>
            Version 0.0.1
          </small>
        </p>
      </div>
      <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
        <!-- Image -->
        <div
          class="bg-cover h-100 min-vh-100 mt-n1 me-n3"
          :style="{
            'background-image':
              'url(' + require('../assets/auth-side-cover.jpg') + ')',
          }"
        ></div>
      </div>
    </div>
    <!-- / .row -->
  </div>
</div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
        isLoginClicked: false
    }
  },
  methods: {
    loginClicked(){
      this.isLoginClicked = true;
      this.$store.dispatch('login');
    }
  }

};
</script>

<style>
</style>
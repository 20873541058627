<template>
    <div class="row">
        <div class="col">
            <!-- Nav -->
            <ul class="nav nav-tabs nav-overflow">
                <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
                    <a
                        class="nav-link"
                        :class="[index==0?'active':'']"
                        :id="'tab'+index"
                        data-bs-toggle="tab"
                        :data-bs-target="'#tab' + index + 'Content'"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        >{{ tab.name }}</a
                    >
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="tab-content" id="tabContent">
                <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    class="tab-pane fade"
                    :class="[index==0?'active show':'']"
                    :id="'tab' + index + 'Content'"
                    role="tabpanel"
                    :aria-labelledby="tab.id"
                >
                    <div v-if="tab.content">
                        {{ tab.content }}
                    </div>
                    <div v-if="tab.iframe">
                        <iframe class="tab-frame" :src="tab.iframe"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tabs",
    props: {
        tabs: Object,
    },
};
</script>

<style scoped>
.tab-frame{
    width:100%;
    height:70vh;
}
</style>
export default function useValidators() {

    const isEmpty = (fieldName, fieldValue, ruleParams) => {
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')?ruleParams.errMsg:`${fieldName} is required`;
        if(Array.isArray(fieldValue)) {
            const arrLen = fieldValue.length;
            return (arrLen === 0 || (arrLen === 1 && fieldValue[0]==""))? errMessage : "";            
        }
        return !fieldValue && fieldValue != 0 ? errMessage : "";
    }    

    const isEmail = (fieldName, fieldValue, ruleParams) => {
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')?ruleParams.errMsg:`The input is not a valid ${fieldName} address`;
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(fieldValue) ? errMessage : "";
    }

    const minLength = (fieldName, fieldValue, ruleParams) => {       
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')? ruleParams.errMsg : `The ${fieldName} field must be atleast ${ruleParams.length} characters long`;
        return fieldValue.length < ruleParams.length ? errMessage : "";
    }

    const isRegex = (fieldName, fieldValue, ruleParams) => {
        const regex = new RegExp(ruleParams.pattern); 
        return (!(regex).test(fieldValue))? `The ${fieldName} field must must match regex` : "";
    }
    return { isEmpty, isEmail , minLength, isRegex}
}
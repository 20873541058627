<template>
    <div class="row">
        <div class="col-md-3">
            <div class="card">
                <datatable
                    tableId="jobList"
                    :columns="columns"
                    :data="this.$store.state.amdp.jobs"
                    :lengthChange="false"
                    :selectedItem="this.$store.state.amdp.currentJob"
                    :info="false"
                    v-on:selected="handleSelect"
                />
            </div>
        </div>
        <div class="col-md-9">
            <ul class="nav nav-tabs mb-4">
                <li class="nav-item">
                    <a
                        href="#"
                        @click="setActiveTab('config')"
                        :class="[
                            activeTab == 'config' ? 'active' : '',
                            'nav-link',
                        ]"
                    >
                        Configuration
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        href="#"
                        @click="setActiveTab('yaml')"
                        :class="[
                            activeTab == 'yaml' ? 'active' : '',
                            'nav-link',
                        ]"
                    >
                        YAML Preview
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        href="#"
                        @click="setActiveTab('output')"
                        :class="[
                            activeTab == 'output' ? 'active' : '',
                            'nav-link',
                        ]"
                    >
                        Output Sample
                    </a>
                </li>
            </ul>
            <div
                v-show="activeTab == 'config'"
                v-if="$store.state.amdp.currentJob"
            >
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Job Name</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="job.parameters.jobName"
                            />
                        </div>
                        <div class="form-group" v-if="job.job_conf">
                            <label class="form-label">Job Type</label>
                            <select
                                class="form-select form-control"
                                v-model="job.job_conf.type"
                            >
                                >
                                <option>Other</option>
                                <option value="event_collation">
                                    Event Collation
                                </option>
                                <option value="event_import">
                                    Event Import
                                </option>
                                <option value="attribution">Attribution</option>
                                <option value="export_id_for_id_sync">
                                    Export ID for ID Sync
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Job Status</label>
                            <p><strong>Completed</strong></p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary float-end">
                            Run Job
                        </button>
                    </div>
                </div>

                <div class="row" v-if="job.job_conf">
                    <div class="col-md-6">
                        <h2 class="border-bottom pe-none pb-2 mb-3">Inputs</h2>
                        <input-ouput-box
                            v-for="(input, index) in job.job_conf.input"
                            :key="index"
                            :objectData="input"
                        />
                    </div>

                    <div class="col-md-6">
                        <h2 class="border-bottom pe-none pb-2 mb-3">Outputs</h2>
                        <input-ouput-box
                            v-for="(output, index) in job.job_conf.output"
                            :key="index"
                            :objectData="output"
                        />
                    </div>
                </div>
            </div>
            <div v-show="activeTab == 'yaml'">
                <textarea id="yaml">yrdy</textarea>
            </div>
            <div v-show="activeTab == 'output'">
                <textarea id="output"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import CodeMirror from "codemirror";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/python/python";
import "codemirror/mode/yaml/yaml";
import yaml from "js-yaml";
import InputOuputBox from "../../../components/admp/InputOuputBox.vue";
import Datatable from "../../../components/Datatable.vue";

export default {
    components: { InputOuputBox, Datatable },
    name: "Jobs",
    data() {
        return {
            activeTab: "config",
            columns: [
                {
                    data: "name",
                    title: "Job Name",
                },
            ],
        };
    },
    methods: {
        setActiveTab(tabNumber) {
            this.activeTab = tabNumber;
            const self = this;
            if (this.activeTab == "yaml")
                setTimeout(() => {
                    self.yamlEditor.refresh();
                }, 0);
            if (this.activeTab == "output")
                setTimeout(() => {
                    self.outputEditor.refresh();
                }, 0);
        },
        handleSelect(data) {
            this.$store.dispatch("amdp/setCurrentJob", data);
        },
    },
    computed: {
        job() {
            return this.$store.state.amdp.currentJob;
        },
    },
    mounted() {
        //subscribe to state to watch for changes to update codeblock
        this.yamlEditor = CodeMirror.fromTextArea(
            document.getElementById("yaml"),
            {
                lineNumbers: true,
                theme: "mdn-like",
                mode: "yaml",
                matchBrackets: true,
            }
        );

        this.yamlEditor
            .getDoc()
            .setValue(yaml.dump(this.$store.state.amdp.currentJob));

        this.outputEditor = CodeMirror.fromTextArea(
            document.getElementById("output"),
            {
                lineNumbers: true,
                theme: "mdn-like",
                mode: "javascript",
                matchBrackets: true,
            }
        );

        this.outputEditor
            .getDoc()
            .setValue(
                JSON.stringify(this.$store.state.amdp.currentJob, null, 4)
            );

        const self = this;
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === "amdp/setCurrentJob") {
                self.yamlEditor
                    .getDoc()
                    .setValue(yaml.dump(state.amdp.currentJob));
                self.outputEditor
                    .getDoc()
                    .setValue(
                        JSON.stringify(state.amdp.currentJob, null, 4)
                    );
            }
        });
    },
    beforeUnmount() {
        this.unsubscribe();
    },
};
</script>

<style>
</style>
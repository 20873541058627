
// App.vue
<template>  
    <div v-if="loading" style="height: 100vh" class="d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-5 col-xl-4 my-5">
            <div class="text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view v-else></router-view> 
    <modal /> 
</template>
<script>
import axios from 'axios';
import Modal from '../../../src/components/Modal.vue'
export default {
  name: 'App',
  components: { Modal },
  data: () => {
    return {
      loading: true
    }
  },
  mounted(){
       
    /* DEFINITIONS */
      var parentWindow = window.parent;
      var self = this;
      
      /*
      * RECEIVE MESSAGE FROM PARENT
      */
      window.addEventListener("message", (e) => {   
        var data = JSON.stringify(e.data);
        data = JSON.parse(data);        

        if(data.cid && data.authToken) {  
          self.loading = false;

          const accessToken = data.authToken.indexOf(" ") == -1 ? data.authToken : data.authToken.split(" ")[1];   
               
          this.$store.dispatch('setAccessToken', accessToken);         
          axios.get(this.$store.state.mgmtUrl + '/v1/staging/clients?detail=client', {
              headers: {
                  'Authorization': `Bearer ${this.$store.state.accessToken}`
              }
          })
          .then((res) => {            
              this.$store.dispatch('handleClientsData', res.data.items);
                this.$store.state.clients.forEach(client => {
                if(client.cid === data.cid) {
                  this.$store.dispatch('setCurrentClient', client);
                }                  
              });              
          });

          //get docs token
          axios.post(this.$store.state.docsUrl + '/api/docs-auth', [], {
                headers: {
                    'Authorization': `Bearer ${this.$store.state.accessToken}`
                }
            })
            .then((res) => {
                this.$store.dispatch('handleDocsAuthData', res.data)
            })
            .catch((error) => {
                console.error(error)
            })
        }
        
      });
      
      /* SHAKE HAND WITH PARENT */
      window.addEventListener("load", () => {
        parentWindow.postMessage("shakehand", "*");
      });
    }
}

</script>


<style lang="scss">
//Dashkit theme
$path-to-fonts: '../../../src/theme/fonts';
$path-to-img: '../../../src/assets'; 
@import '../../../src/theme/scss/theme.scss';

//Codemirror
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/material.css';
@import '~codemirror/theme/mdn-like.css';
@import '~codemirror/addon/lint/lint.css';

</style>




<template>
  <li class="nav-item">
    <router-link 
      :class="[active ? 'active' : '', 'nav-link']"
      v-if="item.route"
      :to="item.route"
    ><i :class="`fe fe-${item.icon}`"></i>{{item.name}}
    </router-link>
    <a 
      v-else-if="item.children"
      :class="[active ? 'active' : '', 'nav-link']"
      :href="`#${groupName}`"
      data-bs-toggle="collapse"
      role="button"
      :aria-expanded="expandChildren"
      :aria-controls="groupName"
    ><i :class="`fe fe-${item.icon}`"></i>{{item.name}}
    </a>
    <div v-if="item.children" :class="[expandChildren ? 'show' : '', 'collapse']" :id="groupName">
      <ul class="nav nav-sm flex-column">
        <NavItem v-for="child in item.children" :key="child.index" :item="child" :parent="item" /> 
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    item: Object,
    parent: Object,
  },
  data() {
    return {
      expandChildren: false
    }
  },
  mounted(){
      this.expandChildren = this.active
  },
  computed: {
    groupName() {
      let groupName = this.item.name;
      if(this.parent){
        groupName = this.parent.name + groupName
      }
      return groupName.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    active() {
      //check children for active class - and make parent active/expanded
      if(this.item.children){
        for (const child of this.item.children) {
          if (this.$route.path == child.route) {
            return true;
          }
          if(child.children){
            for (const grandchild of child.children) {
              if (this.$route.path == grandchild.route) {
                return true;
              }
            }
          }

          if(this.$route.path.indexOf(child.route+'/') > -1)
            return true
        }
      }

      if(this.$route.path.indexOf(this.item.route+'/') > -1)
          return true

      return this.$route.path === this.item.route;
    },
  },
};
</script>

<style scoped>
</style>
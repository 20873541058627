<template>
    <div v-if="currentCls">
        <div id="classes-div">
            <div class="row">
                <div class="col-md-12">
                    <div class="mb-2">Choose a Class</div>
                    <button
                        v-for="classItem in classItems"
                        :key="classItem.cls == 'NA' ? 'default' : classItem.cls"
                        @click="currentCls = classItem.cls"
                        class="btn class-btn card-header-title btn-secondary"
                        :class="[currentCls == classItem.cls ? 'active' : '']"
                    >
                        {{ classItem.cls == "NA" ? "default" : classItem.cls }}
                    </button>
                    <button @click="onAddClassClick" class="btn class-btn btn-outline-secondary "><i class="fe fe-plus" /></button>
                </div>
            </div>
            <div class="row" v-if="!loading">
                <div class="col-md-12">
                    <div class="">
                        <div class="card-header">
                            <h3 class="card-header-title">
                                {{
                                    currentCls == "NA" ? "default settings" : currentCls+" settings"
                                }}
                            </h3>
                            <span v-if="editMode">
                                <button
                                    @click.prevent="onSaveClick();"
                                    class="btn btn-success"
                                >
                                    <i class="fe fe-save"></i></button
                                >&nbsp;
                                <button
                                    @click.prevent="onDeleteClick();"
                                    class="btn btn-danger"
                                >
                                    <i class="fe fe-trash-2"></i></button
                                >&nbsp;
                                <button
                                    @click.prevent="onCancelClick()"
                                    class="btn btn-secondary"
                                >
                                    <i class="fe fe-x-circle"></i></button
                                >&nbsp;
                            </span>
                            <span v-else>
                                <button
                                    type="button"
                                    @click.prevent="onEditClick();"
                                    class="btn btn-primary"
                                >
                                    <i class="fe fe-edit"></i></button
                                >&nbsp;
                            </span>
                            <button
                                @click="collpaseToggle($event)"
                                class="btn btn-secondary collpase-icon"
                                data-bs-toggle="collapse"
                                data-bs-target="#cls-dtl-div"
                                role="button"
                                aria-expanded="false"
                                aria-controls="cls-dtl-div"
                            ></button>
                            
                        </div>
                        <div class="row show"  id="cls-dtl-div">
                            <div class="col-md-12 card-body">
                                <div class="card ">
                                    <div class="card-body" v-if="classObject">
                                        <div
                                            v-if="error"
                                            class="alert alert-danger"
                                            role="alert"
                                        >
                                            {{ error }}
                                        </div>
                                        <div
                                            v-if="successMessage"
                                            class="alert alert-success"
                                            role="alert"
                                        >
                                            {{ successMessage }}
                                        </div>
                                        <form-fields
                                            :fields="classFields"
                                            v-model="classObject"
                                            :inlineField="true"
                                            :editMode="editMode"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>						
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="!loading">
            <div id="pixel-div">
                <div class="row">
                    <div class="col-md-12">
                        <div class="">
                            <div class="card-header collapsed">
                                <h3 class="card-header-title">{{currentCls == "NA" ? "default pixels" : currentCls+" pixels"}}</h3>
                                <span v-if="editMode">
                                    <button
                                        @click.prevent="onSaveClick"
                                        class="btn btn-success"
                                    >
                                        <i class="fe fe-save"></i></button
                                    >&nbsp;
                                    <button
                                        @click.prevent="onCancelClick"
                                        class="btn btn-secondary"
                                    >
                                        <i class="fe fe-x-circle"></i></button
                                    >&nbsp;
                                </span>
                                <span v-else>
                                    <button
                                        type="button"
                                        @click.prevent="onEditClick"
                                        class="btn btn-primary"
                                    >
                                        <i class="fe fe-edit"></i></button
                                    >&nbsp;
                                </span>                                
                                <button
                                    class="
                                        btn btn btn-secondary
                                        collpase-icon
                                        collapsed
                                    "
                                    @click.self="collpaseToggle($event)"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#pixel-dtl-div"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="pixel-dtl-div"
                                ></button>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div id="pixel-dtl-div" class="collapse">
                    <div class="row card-body">
                        <div class="col-md-3">
                            <div class="input-container">
                                <label class="input-label"
                                    >Filter by Country &nbsp;</label
                                >
                                <select
                                    class="form-select"
                                    v-model="selectedCountry"
                                >
                                    <option value="ALL">All</option>
                                    <option
                                        v-for="option in countries"
                                        :key="option.id"
                                        :value="option.id"
                                    >
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row card-body">
                        <div class="col-md-3">
                            <div class="" v-if="pixels.length">
                                <list-group
                                    :editMode="editMode"
                                    :title="'Pixel List'"
                                    :list="pixels"
                                    :activeListItem="currentPixel.beaconId"
                                    listItemKey="beaconId"
                                    :orderList="true"
                                    @deleteBtnClick="onPixelDelete"
                                    @selectBtnclick="showPixel"
                                    @reOrderList="reOrderPixel"
                                ></list-group>
                            </div>
                            <div v-if="editMode">
                                <button
                                    @click="addPixel"
                                    class="btn btn-primary"
                                    style="float: right"
                                    type="button"
                                >
                                    Add Pixel
                                </button>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="card" v-if="pixels.length && pixelData">
                                <div class="card-body">
                                    <form-fields
                                        :fields="pixelFields"
                                        v-model="pixelData"
                                        :inlineField="true"
                                        :editMode="editMode"
                                        :key="newPixelKey"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			
            <div id="map-div">
                <div class="row">
                    <div class="col-md-12">
                        <div class="">
                            <div class="card-header collapsed">
                                <h3 class="card-header-title">{{currentCls == "NA" ? "default maps" : currentCls+" maps"}}</h3>
                                <span v-if="editMode">
                                    <button
                                        @click.prevent="onSaveClick"
                                        class="btn btn-success"
                                    >
                                        <i class="fe fe-save"></i></button
                                    >&nbsp;
                                    <button
                                        @click.prevent="onCancelClick"
                                        class="btn btn-secondary"
                                    >
                                        <i class="fe fe-x-circle"></i></button
                                    >&nbsp;
                                </span>
                                <span v-else>
                                    <button
                                        type="button"
                                        @click.prevent="onEditClick"
                                        class="btn btn-primary"
                                    >
                                        <i class="fe fe-edit"></i></button
                                    >&nbsp;
                                </span>                                
                                <button
                                    class="
                                        btn btn btn-secondary
                                        collpase-icon
                                        collapsed
                                    "
                                    @click.self="collpaseToggle($event)"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#map-dtl-div"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="map-dtl-div"
                                ></button>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="collapse" id="map-dtl-div">
                    <div class="row card-body">
                        <div class="col-md-3">
                            <list-group
                                v-if="mapData && maps"
                                :editMode="editMode"
                                :title="'Maps List'"
                                :list="maps"
                                listItemKey="name"
                                :activeListItem="mapData.name"
                                @deleteBtnClick="onMapDelete"
                                @selectBtnclick="showMap"
                            ></list-group>
                            <div v-if="editMode">
                                <button
                                    @click="addMap"
                                    class="btn btn-primary"
                                    style="float: right"
                                    type="button"
                                >
                                    Add Map
                                </button>
                            </div>                            
                        </div>                        
                        <div class="col-md-9">
                            <div class="card" v-if="mapData">
                                <div class="card-body">
                                    <form-fields
                                        :fields="mapFields"
                                        v-model="mapData"
                                        :inlineField="true"
                                        :editMode="editMode"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!mapData && !editMode" class="row card-body"> <div class="col-md-12"><p>Click edit to add a map</p></div></div>
                </div>
            </div>
		</div>
	</div>
	<div v-else>
		<h3>There are no classes available for this deployment</h3>
	</div>
</template>

<script>
import getclassFields from "../../../utils/field-constants/autClassFields";
import getMapFields from "../../../utils/field-constants/autMapFields";
import getPixelFields from "../../../utils/field-constants/autPixelFields";
import FormFields from "../../../components/FormFields.vue";
import ListGroup from "../../../components/ListGroup.vue";
import { mapActions, mapState } from "vuex";
export default {
    components: { FormFields, ListGroup},
    data() {        
        return {  
            editMode: false, 
            selectedCountry:'ALL',
            classFields: [],   
            currentClientData: this.clientData,   
            classItems: this.clientData.classes.items,
            currentCls: null,
            currentClassObj:{},    
            currentPixel:{},
            currentMap:{},
            newMapKey: 1,
            newPixelKey: 0,
            snapshot: null,
            error: "",
            successMessage: "",
            mapFields: [],
            pixelFields: [],
            mapData: null,
            pixelData: null,
            pixels: [],
            maps: [],
            loading: true
        };
    },
    props: {
        cid: String,
        clientData: Object
    },
    watch: {
        "clientData": {
            handler: function(newData) {
                this.loading = true;
                this.classItems = newData.classes.items;
                this.currentCls = this.$store.state.aut.currentClass[this.cid];
                if(!this.currentCls ) {
                    this.currentCls = (this.clientData.classes.items.length)?this.clientData.classes.items[0].cls:null;
                }                
                this.$store.dispatch("aut/fetchAutClassData", this.currentCls);
            }
        },
        "mapData": {
            handler: function(newMapData) {
                if(newMapData) {
                    const mapJSON = this.toMapJSON(newMapData);
                    if(this.currentMap == newMapData.key) {
                        this.maps[this.currentMap] = mapJSON;
                    } else {
                        this.maps[newMapData.key] = mapJSON;
                        delete this.maps[this.currentMap];
                        this.currentMap = newMapData.key;
                    }
                }                
            },
            deep: true       
        },  
        "pixelData": {
            handler: function(newPixelData) {
                if(newPixelData) {
                    this.currentPixel = newPixelData;
                    this.pixelFields = this.hideFields(  this.pixelFields, newPixelData);
                }                
            },
            deep: true       
        },        
        "currentCls": {
            handler: function(newVal) {
                if(newVal) {
                    this.loading = true;
                    this.$store.dispatch("aut/fetchAutClassData", newVal);
                }                
            }
        },
        "selectedCountry": {
            handler: function(newVal) {
                if(newVal) {
                    this.filterPixelByCountry();
                }                
            }

        },
        "currentClassData": {
            handler: function(newVal) {
                if(newVal) {
                    this.loading = false;
                    this.snapshot = JSON.parse(JSON.stringify(newVal));
                    let pixels = (newVal.pixels)?newVal.pixels.items:[];  
                    this.pixels = JSON.parse(JSON.stringify(pixels));
                    this.currentPixel = this.pixels[0] || {};
                    this.showPixel(this.currentPixel);

                    let maps = ((newVal.maps)?newVal.maps:{});
                    let mapsArr = [];
                    for (const key in maps) {
                        mapsArr.push({name:key, ...maps[key]});
                    }
                    this.maps = mapsArr;
                    this.mapData = mapsArr[0];
                    if(this.maps.length) {
                        this.showMap(mapsArr[0]);
                    }
                }              
            }


        }

    },   
    computed: {  
        ...mapState({    
            countries: state => state.aut.countries,
            currentClassData: state => state.aut.currentClassData
        }), 
        classObject: {
            get() { 
                let clsObj = this.$store.state.aut.currentClassData;
                if(clsObj) {
                    clsObj.loadType = (clsObj.pixelBatchSize == 0) ? "BATCH_LOAD" : "LAZY_LOAD";  
                    clsObj.logCookies = clsObj.log_cookies ? (clsObj.log_cookies.client ? ((clsObj.log_cookies.client.length == 1 && clsObj.log_cookies.client[0] == '*') ? 'ALL' : 'SPECIFIC') : 'ALL') : 'ALL';
                    clsObj.cookieList = clsObj.logCookies === 'SPECIFIC' ? clsObj.log_cookies.client.join(',') : []  
                }
                
                return clsObj;
            },
            set(val) {
                this.classFields = this.hideFields( this.classFields, val );
            }            
        }
    },
    methods: {
        ...mapActions({
            updateAutClientClass: "aut/updateAutClientClass",
            fetchAutClassData: "aut/fetchAutClassData",            
            showModal: "showModal"
        }),
        setEditMode(mode) {
            let self = this;            
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        onAddClassClick() {
            this.showModal({component:"AddClass", data:{}});
        },
        onDeleteClick() {
            this.showModal({component:"DeleteClass", data: {classObject:this.classObject, cls:this.currentCls}});
        },
        onEditClick() {
            this.setEditMode(true);            
        },
        onCancelClick() {
            this.setEditMode(false);
            this.$store.dispatch("aut/setAutClassData", this.snapshot);
            // this.showMap(this.currentMap);
        },
        hideFields(fields, obj) {
            return fields.map((field) => {
                if(field.suppressIfField) {                                        
                    const val = field.suppressIfField.split('.').reduce((o, i) => o[i], obj);
                    if(field.suppressIfValueContains && field.suppressIfValueContains.indexOf(val) > -1)
                        field.visible = false;
                    else if(field.suppressIfValueDoesNotContains && field.suppressIfValueDoesNotContains.indexOf(val) == -1)
                        field.visible = false;    
                    else
                        field.visible = true;

                                            
                    return field;   
                    
                }
                return field;
            }); 
        },
        onPixelDelete(pixelData) {
            this.pixels = this.pixels.filter(obj => obj.beaconId != pixelData.beaconId);
            this.classObject.pixels.items = this.pixels;
            this.pixelData = this.pixels[0] || null;
        },

        reOrderPixel(pixel, currentIndex, newIndex) {
            this.pixels.splice(newIndex, 0, this.pixels.splice(currentIndex, 1)[0]);  
            this.pixelData = pixel;
        },
        onMapDelete(mapData) {
            this.maps = this.maps.filter(obj => obj.name != mapData.name);
            delete this.classObject.maps[mapData.name];
            this.showMap(this.maps[0]);
        },
        async removePixelCustomProps() {
            const modifiedPixels = this.pixels.map(obj => {
                            let copyObj = Object.assign({}, obj);

                            copyObj.pubDeny = (copyObj.pub == "ALLOW" || copyObj.pub == "NONE")?[]:copyObj.pubDeny;
                            copyObj.pubAllow = (copyObj.pub == "DENY" || copyObj.pub == "NONE")?[]:copyObj.pubAllow;

                            copyObj.userDeny = (copyObj.country == "ALLOW" || copyObj.country == "NONE")?[]:copyObj.userDeny;
                            copyObj.userAllow = (copyObj.country == "DENY" || copyObj.country == "NONE")?[]:copyObj.userAllow;

                            delete copyObj.pub;
                            delete copyObj.country;

                            if(copyObj.beaconId && (copyObj.beaconId.indexOf("auto genretaed") !== -1)) {
                                delete copyObj.beaconId;
                            }
                            
                            return copyObj;                                                        
                       
                    });
            return modifiedPixels;
        },
        async removeClassCustomProps() {            
            let modifiedCls = JSON.parse(JSON.stringify(this.classObject));
            modifiedCls.log_cookies.client = modifiedCls.logCookies ? ( modifiedCls.logCookies === 'ALL' ?  ['*'] : modifiedCls.cookieList.split(',')) : ['*'];

            delete modifiedCls.loadType;
            delete modifiedCls.logCookies;
            delete modifiedCls.cookieList;
            return modifiedCls;
        },
        async onSaveClick() {
            this.currentClassObj = await this.removeClassCustomProps();
            const pixels = await this.removePixelCustomProps();
            this.currentClassObj.pixels.items = pixels;
            this.showModal({ component: 'DiffViewModal' ,  data: {current:this.currentClassObj, prev:this.snapshot, saveMethod:this.updateClass, cancelMethod: this.onCancelClick}});            
        },

        async updateClass() {
            try {                              
                const response = await this.updateAutClientClass({classObject:this.currentClassObj, cls:this.currentCls});
                this.setEditMode(false);
                const clsObj = await response.data;
                this.snapshot = JSON.parse(JSON.stringify(clsObj));
                this.pixels = clsObj.pixels.items;
                let self = this;
                this.pixelData = this.pixels.filter(obj => obj.beaconId == self.currentPixel.beaconId)[0] || this.pixels[this.pixels.length-1];
                this.successMessage = 'Class settings updated successfully.'
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
            }
        },

        addMap() {
            let newMapData = {
                name:"New_map_"+this.newMapKey,
                mappings:{},   
            };

            this.maps = [...this.maps, newMapData ];
            this.newMapKey++;
            this.showMap(newMapData);

        },
        
        showMap(mapData) {
            this.currentMap = Object.assign({},mapData);
            this.mapData = Object.assign({},mapData);
            this.mapData['defaultType'] = this.mapData.default?"SPECIFIC_DEFAULT_VALUE":"DIRECT_VALUE";
            this.mapData['csv'] = '';
            for (var k in this.mapData['mappings']) {
                var v = this.mapData['mappings'][k];
                for (var i = 0; i < v.length; i++) {
                    this.mapData.csv += v[i] + ',' + k + '\n'
                }
            }
            this.mapData.csv = this.mapData.csv.trim();
        },
        showPixel(pixelData) {
            this.pixelData = pixelData;
            this.addCustomPixelProperties();
        },
        addPixel() {
            const newPixelData = {
                beaconId: '*auto genretaed*_'+this.newPixelKey,
                userAllow: [],
                userDeny: [],
                pubAllow: [],
                pubDeny: [],
                req: true,
                cookie_req: false,
                type: 'DIR',
                frequency: 100,
                fire_conditions: [],
                continent_frequency: {},
                us_privacy_enabled: false,
                should_host_script: false,
                gvlId: null,
                rotationName: 'default'                
            };
            
            this.pixels = [...this.pixels, newPixelData ];
            this.newPixelKey++;
            this.pixelData = newPixelData;
        },
        addCustomPixelProperties() {
            if(Object.keys(this.pixelData).length > 1) {
                if(!this.pixelData.pubAllow.length && !this.pixelData.pubDeny.length) {
                    this.pixelData['pub'] = "NONE";
                } else  {
                    this.pixelData['pub'] = this.pixelData.pubAllow.length?"ALLOW":"DENY"
                }

                if(!this.pixelData.userAllow.length && !this.pixelData.userDeny.length) {
                    this.pixelData['country'] = "NONE";
                } else  {
                    this.pixelData['country'] = this.pixelData.userAllow.length?"ALLOW":"DENY"
                }
            }
        },
        filterPixelByCountry() {
            const filteredPixels = this.classObject.pixels.items.filter((obj) => {
                if(obj.userAllow.length == 0 || this.selectedCountry == "ALL") {
                    return true;
                } else if(obj.userAllow.includes(this.selectedCountry)){
                    return true;
                } else {
                    return false;
                }
           });
          
           this.pixels = [...filteredPixels];
           this.currentPixel = this.pixel.length?this.pixel[0].beaconId:{};

        },
        toMapJSON(newMapData) {
            let lines = newMapData.csv.split('\n');
            let m = {
                mappings: {}
            }
            for (let i = 0; i < lines.length; i++) {
              var fields = lines[i].split(',')
              if (fields.length > 1) {
                var key = fields.pop();
                if (!m['mappings'][key]) m['mappings'][key] = [];
                m['mappings'][key].push(fields.join(','))
              }
            }
            if (newMapData.defaultType === "SPECIFIC_DEFAULT_VALUE") {
              m['default'] = newMapData.default;
            }
            return m
        },
        collpaseToggle(event) {
            event.target.parentElement.classList.toggle("collapsed");
        },
    },
    mounted() {
        this.classFields = getclassFields();  
        this.classFields = this.classObject? this.hideFields( this.classFields, this.classObject ): this.classFields;
        this.mapFields = getMapFields();
		this.mapFields = this.mapData?this.hideFields(this.mapFields, this.mapData):this.mapFields;
        this.pixelFields = getPixelFields();
        this.pixelFields = this.pixelData? this.hideFields(  this.pixelFields, this.pixelData): this.pixelFields;
        this.currentCls = (this.clientData.classes.items.length)?this.clientData.classes.items[0].cls:null;
    },

    

}
</script>

<style scoped>
	.class-btn {
		margin-right: 1rem;
		margin-bottom: 2rem;
		text-transform: capitalize;
		padding: 0.5rem 1.5rem;
	}
	.class-btn.active {
		background-color: #ffffff;
		color: #586a82;
		border-color: #ffffff;
	}
	.input-container {
		display: flex;
		align-items: center;
	}
	.collpase-icon::after {
		content: "\e935";
		font-family: "Feather" !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: inherit;
		-webkit-font-smoothing: antialiased;
	}

	.collpase-icon.collapsed::after {
		content: "\e932";
		font-family: "Feather" !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: inherit;
		-webkit-font-smoothing: antialiased;
	}
	
	.card-header {
		border: 1px solid #B1C2D9;
		text-transform: capitalize !important;        
        border-bottom: none !important;  
        color: #586a82 !important;  
        border: 1px solid #B1C2D9;
            
	}

    .card-header.collapsed {
        background-color: #6e84a380 !important ;
        color:#ffffff !important;
        border: 1px solid #B1C2D9;
        border-radius: calc(0.5rem - 1px);
    }

	#cls-dtl-div,
	#pixel-dtl-div,
	#map-dtl-div {
        margin:0px;
		border: 1px solid #B1C2D9;
		border-top: none !important;
        border-bottom-left-radius: calc(0.5rem - 1px);
        border-bottom-right-radius: calc(0.5rem - 1px);
	}
    #classes-div,
    #pixel-div,
    #map-div {
        padding-bottom: 10px;
    }
</style>
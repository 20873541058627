const Fields = [
    {
        name: "cdmn",
        label: "Domain",
        type: "select",
        toolTip: "Subdomain that is CNAMEd to aUT and from which the aUT tags will be served",
        editable: true,
        visible: true,
        validation: [
            { type: "required", errMsg: "Domain is a required field" }
        ]
    }, {
        name: "cls",
        label: "Class",
        type: "select",        
        editable: true,
        visible: true,        
    }, {
        name: "lm",
        label: "Logging Mode",
        type: "select",
        toolTip: "Logging mode based on your logging requirements. (https://docs.aqfer.net/docs/aut.html#log-formats)",
        editable: true,
        visible: true
    }, {
        name: "tt",
        label: "Tag Type",
        type: "select",
        editable: true,
        visible: true
    }, {
        name: "pubid",
        label: "Publisher Id",
        type: "text",
        toolTip: "This is used for publisher id blacklist evaluation and logging",
        editable: true,
        visible: true
    }, {
        name: "uUserId",
        label: "Unique User Id",
        type: "text",
        tooltip: "Overrides any unique user id generated by aUT cookie generation logic.",
        editable: true,
        visible: true
    }, {
        name: "uStatUserId",
        label: "Statistical Unique User Id",
        type: "text",
        toolTip: "Overrides any unique user id generated by aUT cookie generation logic.",
        editable: true,
        visible: true
    }, {
        name: "payload",
        label: "Payload",
        type: "text",
        toolTip: "data that will be included in the beacon log output." +
        "Payload should be URL-safe encoded and key-value pairs are suggested.",
        editable: true,
        visible: true
    }, {
        name: "eventId",
        label: "Event Id",
        type: "text",
        toolTip: "Unique value generated for the container invocation event" +
        " used for reconciliation and debugging purposes etc.",
        editable: true,
        visible: true
    }, {
        name: "eventType",
        label: "Event Types",
        type: "text",
        toolTip: "Comma separated list of event types for the beacon." +
         " The beacon will be associated to the specified event type(s).",
        editable: true,
        visible: true
    }, {
        name: "aq_m",
        label: "Measurement Tag",
        type: "checkbox",
        toolTip: "Comma separated list of event types for the beacon." +
         " The beacon will be associated to the specified event type(s).",
        editable: true,
        visible: true
    }
];
export default Fields;
function getAutGeneralFields() { 
    return[
        {
           
            name: "version",
            label: "Version",
            type: "text",
            toolTip: "Version number of the configuration. Activation copies the version from STAGING to PRODUCTION.",
            editable: false,
            visible: true
        },
        {
            name: "name",
            label: "Name",
            type: "text",
            editable: true,
            visible: true,
            validation: [
                { type: "required", errMsg: "Name field is required" }
            ]
        },
        {
            name: "cookieName",
            label: "Cookie Name",
            toolTip: "Name of the cookie used by the client for identifying their unique users.",
            type: "text",
            editable: true,
            visible: true
        },
        {
            name: "cookieSameSite",
            label: "SameSite Cookie Status",
            type: "radio",
            editable: true,
            visible: true,
            options:[
                { label: "None", value: "None" },
                { label: "Strict", value: "Strict" },
                { label: "Lax", value: "Lax" },
            ] 
        },       
        {
            name: "pubDenyDomains",
            label: "Deny Publisher Domains",
            type: "array",
            editable: true,
            visible: true,
            toolTip: "List of publisher domains for which the container will not be loaded."
        },
        {
            name: "pubDenyIds",
            label: "Deny Publisher Ids",
            type: "array",
            editable: true,
            visible: true,
            toolTip: "List of publisher ids for which the container will not be loaded."
        },       
        {
            name: "notificationEmails",
            label: "Notification Emails",
            type: "array",
            editable: true,
            visible: true,
            toolTip: "List of email ids to which deployment information will be notified."
        },
        {
            name: "dailyCountEmails",
            label: "Daily Count Emails",
            type: "array",
            editable: true,
            visible: true,
            toolTip: "List of email ids to which daily counts emails will be sent."
        },
        {
            name: "gdprStatus",
            label: "GDPR Handling",
            type: "text",
            editable: false,
            visible: true,
            toolTip: "How the EEA countries traffic is handled? 1.BLOCKED:The traffic is blocked 2.DISABLED:GDPR has been handled 3.ENABLED:GDPR is handled"
        },      
        {
            name: "reqBehaviour",
            label: "GDPR Default Request Behavior",
            type: "text",
            editable: false,
            visible: true,
            toolTip: "The behavior when consent string is not present in the request. If the consent string is present, the request is GDPR processed",
            suppressIfField: 'gdprStatus', 
            suppressIfValueContains: ['DISABLED', 'BLOCKED'] 
        },        
        {
            name: "gdpr.consentStringParamName",
            label: "Consent String Parameter",
            toolTip: "Parameter name of consent string",
            type: "text",
            editable: false,
            visible: true,
            suppressIfField: 'gdprStatus', 
            suppressIfValueContains: ['DISABLED', 'BLOCKED'] 
        },        
        {
            name: "gdpr.gvlId",
            label: "Gvl Id",
            toolTip: "GDPR Global Vendor List Identifier",
            type: "text",
            editable: false,
            visible: true,
            suppressIfField: 'gdprStatus', 
            suppressIfValueContains: ['DISABLED', 'BLOCKED'] 
        },
        {
            name: "gdpr.queryStringLogList",
            label: "Query Parameters to log",
            toolTip: "The query strings to log when GDPR status is BLOCKED, or ENABLED with GDPR processing resulted in blocking the request",
            type: "array",
            editable: false,
            visible: true,
            suppressIfField: 'gdprStatus', 
            suppressIfValueContains: ['DISABLED', 'BLOCKED'] 
        },
        {
            name: "usPrivacyStatus",
            label: "CCPA Handling",
            toolTip: "How the US traffic is handled? 1.BLOCKED:The traffic is blocked 2.DISABLED:CCPA has been handled 3.ENABLED:CCPA is handled",
            type: "radio",
            editable: true,
            visible: true,
            readOnlyIfRoleOver: 1,
            options:[
                { label: "BLOCKED", value: "BLOCKED" },
                { label: "DISABLED", value: "DISABLED" },
                { label: "ENABLED", value: "ENABLED" },
            ] 
        },
        {
            name: "usReqBehaviour",
            label: "CCPA Default Request Behaviour", 
            type: "radio",
            editable: true,
            visible: true,
            readOnlyIfRoleOver: 1,
            options:[
                { label: "ALLOW", value: "ALLOW" },
                { label: "BLOCK", value: "BLOCK" }
            ],
            suppressIfField: 'usPrivacyStatus', 
            suppressIfValueContains: ['DISABLED', 'BLOCKED'] 
        },
        {
            name: "usPrivacy.privacyStringParamName",
            label: "CCPA Privacy String Parameter",
            toolTip: 'Parameter name of privacy string',
            type: "text",
            editable: true,
            visible: true,
            readOnlyIfRoleOver: 1,
            suppressIfField: 'usPrivacy.status', 
            suppressIfValueContains: ['DISABLED', 'BLOCKED'] 
        },
        {
            name: "customHeaders",
            label: "Custom Headers",
            toolTip: 'List of custom headers to be served through the client\'s responses.',
            type: "object",
            editable: true,
            visible: true
        },        
        {
           
            name: "akamaiProperty",
            label: "Akamai Property",
            type: "text",
            editable: true,
            visible: true
        },
        {
            name: "logCookies",
            label: "Log Request Cookies",
            type: "radio",
            editable: true,
            visible: true,
            options:[
                { label: "ALL", value: "ALL" },
                { label: "SPECIFIC", value: "SPECIFIC" }
            ] 
        },
        {
            name: "cookieList",
            label: "Specific Cookies to log",
            toolTip: 'List of case sensitive comma separated cookies to log',
            type: "text",
            editable: true,
            visible: true,
            suppressIfField: 'logCookies', 
            suppressIfValueContains: ['ALL'] 
        },
        
    ]

}

export default getAutGeneralFields;
<template>
  <div v-for="(arr, index) in inputVal" :key="index" class="sub-input-group">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label"> Continent Name </label>
      <div class="col-sm-10">
        <select
          class="form-select"
          v-model="inputVal[index][0]"
          :disabled="!editable"
          :readonly="!editable"
        >
          <option value="AS">Asia</option>
          <option value="NA">North America</option>
          <option value="EU">Europe</option>
          <option value="AF">Africa</option>
          <option value="OC">Oceania</option>
          <option value="SA">South America</option>
          <option value="OT">Others</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Throttle Percentage</label>
      <div class="col-sm-10">
        <input
          type="number"
          class="form-control"
          v-model.number="inputVal[index][1]"
          :disabled="!editable"
          :readonly="!editable"
        />
      </div>
    </div>
  </div>
  <button v-show="editable" @click="addContinent" class="btn btn-primary mb-2">
    Add Continent
  </button>
</template>

<script>
import { ref, computed, reactive } from "vue";
export default {
  data() {
    return {
      inputVal: this.modelValue ? Object.entries(this.modelValue) : [],
    };
  },
  watch: {
    inputVal: {
      handler(newVal) {
        const inputValObj = newVal.reduce(
          (acc, [k, v]) => ((acc[k] = v), acc),
          {}
        );
        this.$emit("update:modelValue", inputValObj);
      },
      deep: true,
    },
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    addContinent() {
      this.inputVal.push([]);
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>

<style></style>

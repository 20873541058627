<template>
  <div class="main-content scrollbar-container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "MainContentArea",
};
</script>

<style>  
</style>
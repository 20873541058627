<template>
  <div :class="[compact ? 'compact' : '', 'header']">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">{{pretitle}}</h6>
            <!-- Title -->
            <h1 class="header-title">{{title}}</h1>
            <!-- Secondary Title -->
            <h4 class="header-title">{{secondaryTitle}}</h4>
          </div>
          <!-- Button -->
          <div v-if="buttonText" class="col-auto">
            <button @click="btnClick" class="btn lift" :class="buttonClass"><slot></slot> {{buttonText}} </button>
          </div>
           <div v-if="buttonText2" class="col-auto">
            <button @click="btnClick2" class="btn lift" :class="buttonClass2"><slot></slot> {{buttonText2}} </button>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
</template>

<script>
export default {
    name: 'BasicHeader',
    props: {
        'pretitle': String,
        'title': String,
        'secondaryTitle': String,
        'buttonText': String,
        'compact': Boolean,
        'buttonClass': {
          type: String,
          default: "btn-primary"
        },
        'buttonText2': String,
        'buttonClass2': {
          type: String,
          default: "btn-secondary"
        }
    },
    methods:{
      btnClick() {
        this.$emit('btnClick')
      },
      btnClick2() {
        this.$emit('btnClick2')
      }
    },
    emits: [
      'btnClick',
      'btnClick2'
    ]
};
</script>

<style>
</style>
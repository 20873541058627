<template>
    <basic-header
        pretitle="Administration"
        title="Dashboards"
        :compact="true"
    />
    <div class="container-fluid">
        <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else>
            <div v-if="!dashboards.length">
                <p class="mt-4">
                    No Dashboards detected for client
                    <b>{{ $store.state.currentClient.cid }}</b>
                </p>
            </div>
            <tabs v-else :tabs="dashboards" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import BasicHeader from "../../components/BasicHeader.vue";
import Tabs from "../../components/Tabs.vue";
export default {
    components: { BasicHeader, Tabs },
    data() {
        return {
            loading: true,
            dashboards: [],
        };
    },
    methods: {
        async checkDashboards() {
            this.loading = true;
            this.dashboards = [];
            const features = this.$store.state.currentClient.config.features;
            for (const feature of features) {
                if (feature["dashboards"]) {
                    for (const dashboard of feature.dashboards) {
                        const response = await this.getQuicksightUrl(
                            dashboard.id
                        );
                        let dashboardData = await response.data;
                        this.dashboards.push({
                            name: dashboard.name,
                            iframe:
                                dashboardData.EmbedUrl +
                                "#p.Client=" +
                                this.$store.state.currentClient.cid,
                        });
                    }
                }
            }
            this.loading = false;
        },
        async getQuicksightUrl(id) {
            return axios.get(
                `${this.$store.state.pailUrl}/v1/cids/${this.$store.state.currentClient.cid}/quicksight/${id}?account=aqfer`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        },
    },
    mounted() {
        this.checkDashboards();
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === "setCurrentClient") {
                this.checkDashboards();
            }
        });
    },
    beforeUnmount() {
        this.unsubscribe();
    },
};
</script>

<style>
</style>
<template>
    <nav-header
        :navItems="[
            { name: 'Jobs', link: '/amdp/manage/jobs' },
            { name: 'Data Channels', link: '/amdp/manage/data-channels' },
            { name: 'Schemas', link: '/amdp/manage/schemas' },
            { name: 'Mappings', link: '/amdp/manage/mappings' },
        ]"
        pretitle="aqfer Marketing Data Platform"
        title="Data Management"
    />
    <div class="container-fluid">
        <router-view></router-view>
    </div>
</template>

<script>
import NavHeader from "../../../components/NavHeader.vue";

export default {
    components: { NavHeader },
    name: "Manage",
    methods: {},
    beforeMount() {
        this.$store.dispatch("amdp/fetchJobs");
        this.$store.dispatch("amdp/fetchMappings");
        this.$store.dispatch("amdp/fetchDataChannels");
    },
};
</script>

<style>
.CodeMirror {
    font-size: 14px;
}
</style>
<template>
    <BasicHeader
        pretitle="Overview"
        :title="`${$store.state.currentClient.name} Dashboard`"
    />

    <!-- CARDS -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-6 col-xl">
                <!-- Value  -->
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center gx-0">
                            <div class="col">
                                <!-- Title -->
                                <h6 class="text-uppercase text-muted mb-2">
                                    Daily Record Count
                                </h6>

                                <!-- Heading -->
                                <span class="h2 mb-0"> 59,855,518 </span>

                                <!-- Badge -->
                                <span class="badge bg-success-soft mt-n1">
                                    +25.38%
                                </span>
                            </div>
                            <div class="col-auto">
                                <!-- Icon -->
                                <span
                                    class="h2 fe fe-trending-up text-muted mb-0"
                                ></span>
                            </div>
                        </div>
                        <!-- / .row -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl">
                <!-- Hours -->
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center gx-0">
                            <div class="col">
                                <!-- Title -->
                                <h6 class="text-uppercase text-muted mb-2">
                                    Total daily job hours
                                </h6>

                                <!-- Heading -->
                                <span class="h2 mb-0"> 2.5 </span>
                            </div>
                            <div class="col-auto">
                                <!-- Icon -->
                                <span
                                    class="h2 fe fe-briefcase text-muted mb-0"
                                ></span>
                            </div>
                        </div>
                        <!-- / .row -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl">
                <!-- Exit -->
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center gx-0">
                            <div class="col">
                                <!-- Title -->
                                <h6 class="text-uppercase text-muted mb-2">
                                    aUT Daily Count
                                </h6>

                                <!-- Heading -->
                                <span class="h2 mb-0"> 1149 </span>
                            </div>
                            <div class="col-auto">
                                <!-- Chart -->
                                <div class="chart chart-sparkline">
                                    <canvas
                                        class="chart-canvas"
                                        id="sparklineChart"
                                        width="75"
                                        height="35"
                                        style="
                                            display: block;
                                            box-sizing: border-box;
                                            height: 35px;
                                            width: 75px;
                                        "
                                    ></canvas>
                                </div>
                            </div>
                        </div>
                        <!-- / .row -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl">
                <!-- Time -->
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center gx-0">
                            <div class="col">
                                <!-- Title -->
                                <h6 class="text-uppercase text-muted mb-2">
                                    Avg. Job Time
                                </h6>

                                <!-- Heading -->
                                <span class="h2 mb-0"> 2:37 </span>
                            </div>
                            <div class="col-auto">
                                <!-- Icon -->
                                <span
                                    class="h2 fe fe-clock text-muted mb-0"
                                ></span>
                            </div>
                        </div>
                        <!-- / .row -->
                    </div>
                </div>
            </div>
        </div>
        <!-- / .row -->
        <div class="row">
            <div class="col-12 col-xl-8">
                <!-- Convertions -->
                <div class="card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                            Record Count by Job Name
                        </h4>

                        <!-- Caption -->
                        <span class="text-muted me-3">
                            Last week comparision:
                        </span>

                        <!-- Switch -->
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="cardToggle"
                                data-toggle="chart"
                                data-target="#conversionsChart"
                                data-trigger="change"
                                data-action="add"
                                data-dataset="1"
                            />
                            <label
                                class="form-check-label"
                                for="cardToggle"
                            ></label>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Chart -->
                        <div class="chart">
                            <canvas
                                id="conversionsChart"
                                class="chart-canvas"
                                width="843"
                                height="300"
                                style="
                                    display: block;
                                    box-sizing: border-box;
                                    height: 300px;
                                    width: 843px;
                                "
                            ></canvas>
                            <div
                                id="chart-tooltip"
                                role="tooltip"
                                class="popover bs-popover-top"
                                style="
                                    visibility: hidden;
                                    left: 540.564px;
                                    top: 97.11px;
                                    transform: translateX(-50%)
                                        translateY(-100%) translateY(-1rem);
                                "
                            >
                                <div
                                    class="popover-arrow translate-middle-x"
                                ></div>
                                <div class="popover-content">
                                    <div>
                                        <h3
                                            class="
                                                popover-header
                                                text-center text-nowrap
                                            "
                                        >
                                            Oct 8
                                        </h3>
                                    </div>
                                    <div>
                                        <div
                                            class="
                                                popover-body
                                                d-flex
                                                align-items-center
                                                text-nowrap
                                                justify-content-center
                                            "
                                        >
                                            <span
                                                class="popover-body-indicator"
                                                style="
                                                    background-color: rgb(
                                                        44,
                                                        123,
                                                        229
                                                    );
                                                "
                                            ></span>
                                            26%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-4">
                <!-- Traffic -->
                <div class="card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">Record Counts by Job</h4>
                    </div>
                    <div class="card-body">
                        <!-- Chart -->
                        <div class="chart chart-appended">
                            <canvas
                                id="trafficChart"
                                class="chart-canvas"
                                data-toggle="legend"
                                data-target="#trafficChartLegend"
                            ></canvas>
                        </div>

                        <!-- Legend -->
                        <div id="trafficChartLegend" class="chart-legend"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / .row -->
        <div class="row">
            <div class="col-12 col-xl-4">
                <!-- Projects -->
                <div class="card card-fill">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                            Notifications and Alerts
                        </h4>

                        <!-- Link -->
                        <a href="project-overview.html" class="small"
                            >View all</a
                        >
                    </div>
                    <div class="card-body">
                        <!-- List group -->
                        <div
                            class="
                                list-group list-group-flush list-group-activity
                                my-n3
                            "
                        >
                            <div class="list-group-item">
                                <div class="row">
                                    <div class="col-auto">
                                        <!-- Avatar -->
                                        <div class="avatar avatar-sm">
                                            <div
                                                class="
                                                    avatar-title
                                                    fs-lg
                                                    bg-primary-soft
                                                    rounded-circle
                                                    text-primary
                                                "
                                            >
                                                <i class="fe fe-check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col ms-n2">
                                        <!-- Heading -->
                                        <h5 class="mb-1">
                                            Job #124 Configuration Approved
                                        </h5>

                                        <!-- Text -->
                                        <p class="small text-gray-700 mb-0">
                                            Ops has deployed version 12 of Job
                                            #124 to production
                                        </p>

                                        <!-- Time -->
                                        <small class="text-muted">
                                            1h ago
                                        </small>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                            <div class="list-group-item">
                                <div class="row">
                                    <div class="col-auto">
                                        <!-- Avatar -->
                                        <div class="avatar avatar-sm">
                                            <div
                                                class="
                                                    avatar-title
                                                    fs-lg
                                                    bg-primary-soft
                                                    rounded-circle
                                                    text-primary
                                                "
                                            >
                                                <i
                                                    class="fe fe-alert-triangle"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col ms-n2">
                                        <!-- Heading -->
                                        <h5 class="mb-1">
                                            aUT Monthly Threshold Warning
                                        </h5>

                                        <!-- Text -->
                                        <p class="small text-gray-700 mb-0">
                                            Please check your daily trend to
                                            prevent going over your budget
                                        </p>

                                        <!-- Time -->
                                        <small class="text-muted">
                                            3h ago
                                        </small>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                            <div class="list-group-item">
                                <div class="row">
                                    <div class="col-auto">
                                        <!-- Avatar -->
                                        <div class="avatar avatar-sm">
                                            <div
                                                class="
                                                    avatar-title
                                                    fs-lg
                                                    bg-primary-soft
                                                    rounded-circle
                                                    text-primary
                                                "
                                            >
                                                <i class="fe fe-archive"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col ms-n2">
                                        <!-- Heading -->
                                        <h5 class="mb-1">
                                            New Portal Features 1/12/22
                                        </h5>

                                        <!-- Text -->
                                        <p class="small text-gray-700 mb-0">
                                            More features to improve your
                                            sandbox experience
                                        </p>

                                        <!-- Time -->
                                        <small class="text-muted">
                                            12h ago
                                        </small>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                        </div>
                    </div>
                    <!-- / .card-body -->
                </div>
                <!-- / .card -->
            </div>
            <div class="col-12 col-xl-8">
                <!-- Sales -->
                <div class="card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">Hourly Number of Jobs</h4>

                        <!-- Nav -->
                        <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
                            <li
                                class="nav-item"
                                data-toggle="chart"
                                data-target="#salesChart"
                                data-trigger="click"
                                data-action="toggle"
                                data-dataset="0"
                            >
                                <a
                                    class="nav-link active"
                                    href="#"
                                    data-bs-toggle="tab"
                                >
                                    Yesterday
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                data-toggle="chart"
                                data-target="#salesChart"
                                data-trigger="click"
                                data-action="toggle"
                                data-dataset="1"
                            >
                                <a
                                    class="nav-link"
                                    href="#"
                                    data-bs-toggle="tab"
                                >
                                    One Week Ago
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <!-- Chart -->
                        <div class="chart">
                            <canvas
                                id="salesChart"
                                class="chart-canvas"
                                width="843"
                                height="300"
                                style="
                                    display: block;
                                    box-sizing: border-box;
                                    height: 300px;
                                    width: 843px;
                                "
                            ></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / .row -->
    </div>
</template>

<script>
import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from "chart.js";

function getCSSVariableValue(variable) {
    return getComputedStyle(document.documentElement).getPropertyValue(
        variable
    );
}

Chart.register(
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip
);

const colors = {
    gray: {
        300: getCSSVariableValue("--bs-chart-gray-300"),
        600: getCSSVariableValue("--bs-chart-gray-600"),
        700: getCSSVariableValue("--bs-chart-gray-700"),
        800: getCSSVariableValue("--bs-chart-gray-800"),
        900: getCSSVariableValue("--bs-chart-gray-900"),
    },
    primary: {
        100: getCSSVariableValue("--bs-chart-primary-100"),
        300: getCSSVariableValue("--bs-chart-primary-300"),
        700: getCSSVariableValue("--bs-chart-primary-700"),
    },
    black: getCSSVariableValue("--bs-dark"),
    white: getCSSVariableValue("--bs-white"),
    transparent: "transparent",
};

const fonts = {
    base: "Cerebri Sans",
};

const toggles = document.querySelectorAll('[data-toggle="chart"]');
const legends = document.querySelectorAll('[data-toggle="legend"]');

//
// Functions
//

function globalOptions() {
    Chart.defaults.responsive = true;
    Chart.defaults.maintainAspectRatio = false;

    // Default
    Chart.defaults.color = getCSSVariableValue("--bs-chart-default-color");
    Chart.defaults.font.family = fonts.base;
    Chart.defaults.font.size = 13;

    // Layout
    Chart.defaults.layout.padding = 0;

    // Legend
    Chart.defaults.plugins.legend.display = false;

    // Point
    Chart.defaults.elements.point.radius = 0;
    Chart.defaults.elements.point.backgroundColor = colors.primary[700];

    // Line
    Chart.defaults.elements.line.tension = 0.4;
    Chart.defaults.elements.line.borderWidth = 3;
    Chart.defaults.elements.line.borderColor = colors.primary[700];
    Chart.defaults.elements.line.backgroundColor = colors.transparent;
    Chart.defaults.elements.line.borderCapStyle = "rounded";

    // Bar
    Chart.defaults.elements.bar.backgroundColor = colors.primary[700];
    Chart.defaults.elements.bar.borderWidth = 0;
    Chart.defaults.elements.bar.borderRadius = 10;
    Chart.defaults.elements.bar.borderSkipped = false;
    Chart.defaults.datasets.bar.maxBarThickness = 10;

    // Arc
    Chart.defaults.elements.arc.backgroundColor = colors.primary[700];
    Chart.defaults.elements.arc.borderColor = getCSSVariableValue(
        "--bs-chart-arc-border-color"
    );
    Chart.defaults.elements.arc.borderWidth = 4;
    Chart.defaults.elements.arc.hoverBorderColor = getCSSVariableValue(
        "--bs-chart-arc-hover-border-color"
    );

    // Tooltips
    Chart.defaults.plugins.tooltip.enabled = false;
    Chart.defaults.plugins.tooltip.mode = "index";
    Chart.defaults.plugins.tooltip.intersect = false;
    Chart.defaults.plugins.tooltip.external = externalTooltipHandler;
    Chart.defaults.plugins.tooltip.callbacks.label =
        externalTooltipLabelCallback;

    // Doughnut
    Chart.defaults.datasets.doughnut.cutout = "83%";
    Chart.overrides.doughnut.plugins.tooltip.callbacks.title = function (ctx) {
        return ctx[0].label;
    };
    Chart.overrides.doughnut.plugins.tooltip.callbacks.label = function (ctx) {
        const callbacks = ctx.chart.options.plugins.tooltip.callbacks;
        const before = callbacks.beforeLabel() || "";
        const after = callbacks.afterLabel() || "";

        return before + ctx.formattedValue + after;
    };

    // yAxis
    Chart.defaults.scales.linear.grid = {
        borderDash: [2],
        borderDashOffset: [2],
        color: getCSSVariableValue("--bs-chart-grid-line-color"),
        drawBorder: false,
        drawTicks: false,
    };

    Chart.defaults.scales.linear.ticks.beginAtZero = true;
    Chart.defaults.scales.linear.ticks.padding = 10;
    Chart.defaults.scales.linear.ticks.stepSize = 10;

    // xAxis
    Chart.defaults.scales.category.grid = {
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
    };

    Chart.defaults.scales.category.ticks.padding = 20;
}

function getOrCreateTooltip(chart) {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.setAttribute("id", "chart-tooltip");
        tooltipEl.setAttribute("role", "tooltip");
        tooltipEl.classList.add("popover", "bs-popover-top");

        const arrowEl = document.createElement("div");
        arrowEl.classList.add("popover-arrow", "translate-middle-x");

        const contentEl = document.createElement("div");
        contentEl.classList.add("popover-content");

        tooltipEl.appendChild(arrowEl);
        tooltipEl.appendChild(contentEl);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
}

function externalTooltipHandler(context) {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.visibility = "hidden";
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const headEl = document.createElement("div");
        titleLines.forEach((title) => {
            const headingEl = document.createElement("h3");
            headingEl.classList.add(
                "popover-header",
                "text-center",
                "text-nowrap"
            );

            const text = document.createTextNode(title);

            headingEl.appendChild(text);
            headEl.appendChild(headingEl);
        });

        const bodyEl = document.createElement("div");
        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const indicatorEl = document.createElement("span");
            indicatorEl.classList.add("popover-body-indicator");
            indicatorEl.style.backgroundColor =
                chart.config.type === "line" &&
                colors.borderColor !== "rgba(0,0,0,0.1)"
                    ? colors.borderColor
                    : colors.backgroundColor;

            const contentEl = document.createElement("div");
            contentEl.classList.add(
                "popover-body",
                "d-flex",
                "align-items-center",
                "text-nowrap"
            );
            contentEl.classList.add(
                bodyLines.length > 1
                    ? "justify-content-left"
                    : "justify-content-center"
            );

            const text = document.createTextNode(body);

            contentEl.appendChild(indicatorEl);
            contentEl.appendChild(text);
            bodyEl.appendChild(contentEl);
        });

        const rootEl = tooltipEl.querySelector(".popover-content");

        // Remove old children
        while (rootEl.firstChild) {
            rootEl.firstChild.remove();
        }

        // Add new children
        rootEl.appendChild(headEl);
        rootEl.appendChild(bodyEl);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.visibility = "visible";
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.transform =
        "translateX(-50%) translateY(-100%) translateY(-1rem)";
}

function externalTooltipLabelCallback(ctx) {
    const scale = ctx.chart.scales[ctx.dataset.yAxisID || "y"];
    const label =
        ctx.chart.tooltip.dataPoints.length > 1
            ? " " + ctx.dataset.label + " "
            : " ";
    const content =
        label +
        scale.options.ticks.callback.apply(scale, [ctx.parsed.y, 0, []]);

    return content;
}

function toggleDataset(toggle) {
    const id = toggle.dataset.target;
    const action = toggle.dataset.action;
    const index = parseInt(toggle.dataset.dataset);

    const chart = document.querySelector(id);
    const chartInstance = Chart.getChart(chart);

    // Action: Toggle
    if (action === "toggle") {
        const datasets = chartInstance.data.datasets;

        const activeDataset = datasets.filter(function (dataset) {
            return !dataset.hidden;
        })[0];

        let backupDataset = datasets.filter(function (dataset) {
            return dataset.order === 1000;
        })[0];

        // Backup active dataset
        if (!backupDataset) {
            backupDataset = {};

            for (const prop in activeDataset) {
                if (prop !== "_meta") {
                    backupDataset[prop] = activeDataset[prop];
                }
            }

            backupDataset.order = 1000;
            backupDataset.hidden = true;

            // Push to the dataset list
            datasets.push(backupDataset);
        }

        // Toggle dataset
        const sourceDataset =
            datasets[index] === activeDataset ? backupDataset : datasets[index];

        for (const prop in activeDataset) {
            if (prop !== "_meta") {
                activeDataset[prop] = sourceDataset[prop];
            }
        }

        // Update chart
        chartInstance.update();
    }

    // Action: Add
    if (action === "add") {
        const dataset = chartInstance.data.datasets[index];
        const isHidden = dataset.hidden;

        // Toggle dataset
        dataset.hidden = !isHidden;
    }

    // Update chart
    chartInstance.update();
}

function toggleLegend(legend) {
    const chart = Chart.getChart(legend);
    const legendEl = document.createElement("div");

    chart.legend.legendItems?.forEach((item) => {
        legendEl.innerHTML += `<span class="chart-legend-item"><span class="chart-legend-indicator" style="background-color: ${item.fillStyle}"></span>${item.text}</span>`;
    });

    const id = legend.dataset.target;
    const container = document.querySelector(id);

    container.appendChild(legendEl);
}

//
// Events
//

// Global options
globalOptions();

// Toggle dataset
toggles.forEach(function (toggle) {
    const event = toggle.dataset.trigger;

    toggle.addEventListener(event, function () {
        toggleDataset(toggle);
    });
});

// // Toggle legend
document.addEventListener("DOMContentLoaded", function () {
    legends.forEach(function (legend) {
        toggleLegend(legend);
    });
});

// Make available globally
window.Chart = Chart;

import BasicHeader from "../components/BasicHeader.vue";
export default {
    components: { BasicHeader },
    name: "Dashboard",
    methods: {
        toggleLegend(legend) {
            const chart = Chart.getChart(legend);
            const legendEl = document.createElement("div");

            chart.legend.legendItems?.forEach((item) => {
                legendEl.innerHTML += `<span class="chart-legend-item"><span class="chart-legend-indicator" style="background-color: ${item.fillStyle}"></span>${item.text}</span>`;
            });

            const id = legend.dataset.target;
            const container = document.querySelector(id);

            container.appendChild(legendEl);
        },
    },
    mounted() {
        // Convertions chart
        const conversionsChart = document.getElementById("conversionsChart");

        if (conversionsChart) {
            new Chart(conversionsChart, {
                type: "bar",
                options: {
                    scales: {
                        y: {
                            ticks: {
                                callback: function (val) {
                                    return val;
                                },
                            },
                        },
                    },
                },
                data: {
                    labels: [
                        "generate attribution",
                        "import-inc-daily-data",
                        "produce sample data2",
                        "collate events",
                        "import impressions",
                        "merge collated",
                        "import inc daily maintenance",
                        "import conversions",
                        "import subevents",
                        "ingest into dynamo",
                    ],
                    datasets: [
                        {
                            label: "1/17/22",
                            data: [
                                32462638, 11786608, 4399358, 4365639, 3424561,
                                2804819, 210292, 68871, 2946, 0,
                            ],
                        },
                        {
                            label: "1/11/22",
                            data: [
                                33381964, 0, 4821041, 3952447, 3047333, 2571199,
                                210292, 83011, 1623, 0,
                            ],
                            backgroundColor: "#d2ddec",
                            hidden: true,
                        },
                    ],
                },
            });
        }
        //this.toggleLegend(conversionsChart)

        // Traffic chart

        const trafficChart = document.getElementById("trafficChart");

        if (trafficChart) {
            new Chart(trafficChart, {
                type: "doughnut",
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                afterLabel: function () {
                                    return "";
                                },
                            },
                        },
                    },
                },
                data: {
                    labels: [
                        "generate-attribution",
                        "import-inc-daily-data",
                        "produce-sample-data2",
                        "collate-events",
                        "import-impressions",
                        "merge-collated",
                        "import-inc-daily-maintenance-data",
                        "import-conversions",
                        "import-subevents",
                        "ingest-into-dynamo",
                    ],
                    datasets: [
                        {
                            data: [
                                33381964, 11786608, 4821041, 3952447, 3047333,
                                2571199, 210292, 83011, 1623, 0,
                            ],
                            backgroundColor: ["#2C7BE5", "#A6C5F7", "#D2DDEC"],
                        },
                        // {
                        //     data: [15, 45, 20],
                        //     backgroundColor: ["#2C7BE5", "#A6C5F7", "#D2DDEC"],
                        //     hidden: true,
                        // },
                    ],
                },
            });
        }
        this.toggleLegend(trafficChart);

        // Sales chart

        const salesChart = document.getElementById("salesChart");

        if (salesChart) {
            new Chart(salesChart, {
                type: "line",
                options: {
                    scales: {
                        y: {
                            ticks: {
                                callback: function (value) {
                                    return value;
                                },
                            },
                        },
                    },
                },
                data: {
                    labels: [
                        "00",
                        "01",
                        "02",
                        "03",
                        "04",
                        "05",
                        "06",
                        "07",
                        "08",
                        "09",
                        "10",
                        "11",
                        "12",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                        "21",
                        "22",
                        "23",
                    ],
                    datasets: [
                        {
                            label: "Yesterday",
                            data: [
                                14, 13, 13, 13, 13, 17, 13, 14, 13, 13, 13, 13,
                                18, 16, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13,
                                13,
                            ],
                        },
                        {
                            label: "This Day Last Week",
                            data: [
                                14, 13, 13, 13, 13, 17, 13, 14, 13, 13, 13, 13,
                                18, 9, 6, 13, 13, 28, 22, 13, 13, 6, 13, 13,
                            ],
                            hidden: true,
                        },
                    ],
                },
            });
        }

        // Sparkline chart

        const sparklineChart = document.getElementById("sparklineChart");

        if (sparklineChart) {
            new Chart(sparklineChart, {
                type: "line",
                options: {
                    scales: {
                        y: {
                            display: false,
                        },
                        x: {
                            display: false,
                        },
                    },
                    elements: {
                        line: {
                            borderWidth: 2,
                        },
                        point: {
                            hoverRadius: 0,
                        },
                    },
                    plugins: {
                        tooltip: {
                            external: () => false,
                        },
                    },
                },
                data: {
                    labels: new Array(12).fill("Label"),
                    datasets: [
                        {
                            data: [
                                0, 15, 10, 25, 30, 15, 40, 50, 80, 60, 55, 65,
                            ],
                        },
                    ],
                },
            });
        }

        const toggles = document.querySelectorAll('[data-toggle="chart"]');

        toggles.forEach(function (toggle) {
            const event = toggle.dataset.trigger;

            toggle.addEventListener(event, function () {
                toggleDataset(toggle);
            });
        });
    },
};
</script>

<style>
</style>
import router from '../../router'
import axios from 'axios'

const store = {
    namespaced: true,
    state: {
        users: [],
        clientList: [],
    },
    mutations: {
        setUsers(state, users) {
            state.users = users
        },
        setClientList(state, clientList) {
            state.clientList = clientList
        },
    },
    actions: {
        async createClient({rootState}, clientPayload) {
            return axios.post(rootState.mgmtUrl + "/v1/staging/clients", clientPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then(function(data) {
                const cidObj = {name: clientPayload.name, cid: clientPayload.cid}
                return axios.put(rootState.authUrl + '/v1/clients/' + cidObj.cid, cidObj, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
                }).then(function(data) {
                    //add new client to current user's client list
                    return axios.get(rootState.authUrl +  '/v1/users/' + rootState.currentUser.email, {
                        headers: {
                            Authorization: `Bearer ${rootState.accessToken}`,
                        },
                    }).then((response) => {
                        if(response.data.cids){
                            response.data.cids.push(cidObj.cid)
                            return axios.put(rootState.authUrl +  '/v1/users/' + rootState.currentUser.email, response.data, {
                                headers: {
                                    Authorization: `Bearer ${rootState.accessToken}`,
                                },
                            })
                        }
                    });
                })
            })
        },
        async updateClient({rootState}, clientPayload) {
            const eTag = clientPayload.eTag
            delete clientPayload.eTag
            return axios.put(rootState.mgmtUrl + "/v1/staging/clients/" + clientPayload.cid, clientPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                    'If-Match': eTag,
                },
            })
        },
        async deleteClient({rootState}, clientPayload) {
            return axios.delete(rootState.mgmtUrl + "/v1/staging/clients/" + clientPayload.cid, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then(function(data) {
                return axios.delete(rootState.authUrl + '/v1/clients/' + clientPayload.cid, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
                })
            })
        },
        async readClient({rootState}, id) {
            return axios.get(rootState.mgmtUrl + `/v1/staging/clients/${id}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async createUser({rootState, dispatch}, userPayload) {
            return dispatch('updateUser', userPayload).then(function(data) {
                return axios.post(rootState.mgmtUrl + "/v1/users/" + userPayload.email + "/send_welcome_email", {}, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
                })
            });
        },
        async updateUser({rootState}, userPayload) {
            return axios.put(rootState.authUrl + "/v1/users/" + userPayload.email, userPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteUser({rootState}, userPayload) {
            return axios.delete(rootState.authUrl + "/v1/users/" + userPayload.email, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readUser({rootState}, id) {
            return axios.get(rootState.authUrl + "/v1/users/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async generateToken({rootState}, userPayload) {
            return axios.post(rootState.authUrl + "/v1/refresh_tokens?sendmail=true", userPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        fetchUsers({rootState, commit}) {
            axios.get(rootState.authUrl + "/v1/users", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const users = response.data;
                commit('setUsers', users)
            });
        },
        fetchClientList({rootState, commit}){
            axios.get(rootState.mgmtUrl + "/v1/staging/clients?detail=pixel", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                if(response.data.items){
                    const clientList = response.data.items;
                    commit('setClientList', clientList)
                }
            });
        }
    }
}

export default store
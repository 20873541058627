import axios from 'axios'

const store = {
    namespaced: true,
    state: {
        sandboxes: [],
        sandboxJobs:[],
        currentSandbox: null,

        mapperFileList: [
            "jobconf",
            "dataChannels",
            "mappings",
            "credentials",
        ],         
        collatorFileList: [
            "config"
        ],
        connectorFileList: [
            "config"
        ]

    },
    mutations: {
        setSandboxes(state, sandboxes) {
            state.sandboxes = sandboxes;
        },
        setSandboxJobs(state, sandboxJobs) {
            state.sandboxJobs = sandboxJobs;
        },
        setCurrentSandbox(state, sandbox) {
            state.currentSandbox = sandbox;
        }      
    },
    actions: {
        //Sandbox actions
        setCurrentSandbox({ commit }, sandbox) {
            commit('setCurrentSandbox', sandbox)
        },
        fetchSandboxes({rootState, state, commit}) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setSandboxes', response.data);
            }).catch((err)=> {
                commit('setSandboxes', []);
            });
        },

        async createSandbox({rootState, state}, sandboxPayload) {
            return axios.post(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes`, sandboxPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readSandbox({rootState}, sandboxName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/` + sandboxName, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updateSandbox({rootState}, sandboxPayload) {
            return axios.put(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/` + sandboxPayload.name, sandboxPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteSandbox({rootState}, sandboxName) {
            return axios.delete(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        //Sandbox user actions
        async fetchSandboxUsers({rootState}, sandboxName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}/users`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async addSandboxUser({rootState}, params) {
            return axios({
                method: 'put',
                url: rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${params.sandboxName}/users/${params.email}`,
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            });           
        },        
        async deleteSandboxUser({rootState}, params) {
            return axios({
                method: 'delete',
                url: rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${params.sandboxName}/users/${params.email}`,
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            });           
        },

        // Sandbox job actions
        fetchSandboxJobs({rootState, state, commit}) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {                
                commit('setSandboxJobs', response.data || []);
            }).catch((err)=> {
                commit('setSandboxJobs', []);
            });
        },

        async readSandboxJob({rootState, state}, jobName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${jobName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async createSandboxJob({rootState, state}, payload) {
            return axios.post(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updateSandboxJob({rootState, state}, payload) {
            return axios.put(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${payload.name}`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteSandboxJob({rootState, state}, jobName) {
            return axios.delete(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${jobName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        }       
                
    }   
}

export default store;
<template>
    <div class="card">
        <div class="card-body">
            <div class="form-group" v-if="model.event_type">
                <label class="form-label">Event Type</label>
                <input type="text" class="form-control" v-model="model.event_type">
            </div>
            <div class="form-group" v-if="model.data_channel">
                <label class="form-label">Data Channel</label>
                <input type="text" class="form-control" v-model="model.data_channel">
            </div>
            <div class="form-group" v-if="model.event_schema">
                <label class="form-label">Event Schema</label>
                <div v-if="typeof model.event_schema === 'object'" class="input-group mb-3">
                    <input type="text" v-model="model.event_schema.name" class="form-control" placeholder="Name" >
                    <span class="input-group-text">@</span>
                    <input type="text" v-model="model.event_schema.version" class="form-control" placeholder="Version">
                </div>
                <input v-else type="text" class="form-control" v-model="model.event_schema">
            </div>
            <div class="form-group" v-if="model.data_channels">
                <label class="form-label">Data Channels</label>
                <ul class="list-group">
                    <li v-for="data_channel in model.data_channels" :key="data_channel.index" class="list-group-item">
                        {{data_channel}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputOutputBox',
    props: {
        objectData: Object
    },
    watch: {
        objectData(){
            this.model = this.objectData
        }
    },
    data() {
        return {
            model: this.objectData
        }
    }
}
</script>

<style>

</style>
<template>
    <basic-header pretitle="aqfer Universal Tag" title="Tag Reports" />
    <div class="container-fluid" id="tag_reports">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-2">Choose a Class</div>
                <button
                    style="margin-right:10px"
                    v-for="classItem in classItems"
                    :key="classItem.cls == 'NA' ? 'default' : classItem.cls"
                    @click="currentCls = classItem.cls"
                    class="btn class-btn card-header-title btn-secondary"
                    :class="[currentCls == classItem.cls ? 'active' : '']"
                    >
                    {{ classItem.cls == "NA" ? "default" : classItem.cls }}
                </button>
            </div>
        </div>
        <div class="row"> 
            <div class="col-md-3">
                <input-field
                label="Report"
                type="select"
                :options="reportOptions"
                v-model="reportType"
            />
            </div>
            <div class="col-md-3">
                <input-field
                label="Date(UTC)"
                type="select"
                :options="dateOptions"
                v-model="dateType"
            />
            </div> 
            <div class="col-md-3 my-auto" >
                <div class="custom-control form-switch">
                    <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="showDaySplit">
                    <label style="padding-left:5px" class="form-check-label" for="customSwitch1">Daily Report</label>
                </div>
            </div>
            <div class="col-md-3 my-auto text-end">                            
                <button class="btn btn-primary" @click="loadCounts">
                    Generate</button
                > 
            </div>
        </div> 
        <div class = "row">
            <div class="col-md-3" v-if="dateType == 'custom'">
                <input-field label="Start Date(UTC)" type="calendar" v-model="startDate" :inputConfig="config" />
            </div>
            <div class="col-md-3" v-if="dateType == 'custom'">
                <input-field label="End Date(UTC)" type="calendar" v-model="endDate" :inputConfig="config" />
            </div>
        </div>
        <div class="error" v-if="errMode">
            <p>{{errMsg}}</p>
        </div>
        <div v-if="reportType=='tag_type'&& !errMode">
            <div class="form-group row">
                <label for="thresholdTagtype" class="col-sm-1 col-form-label">Threshold:</label>
                <div class="col-sm-2">
                    <input type="number" class="form-control" id="thresholdTagType" v-model="threshold_tagType" step="1000">
                </div>
                <div class = "col-sm-2 col-centered"> <h3>Counts By Tag Type</h3> </div>
                <div class="col-sm-4 text-end" >
                    <button class="btn btn-primary" @click="(showDaySplit)?exportCSV('tagtype_day_counts', filteredCountsPixel):exportCSV('tagtype_count', filteredCountsPixel)">
                            Export CSV
                    </button>
                </div>
            </div>
            <div class="">
                <div class="card" v-if="showDaySplitTable && showDaySplit">
                    <datatable 
                        :key = "tagDailyColumns.length"                    
                        tableId="dailyTagCountList"
                        :columns="tagDailyColumns"
                        :data="filteredCountsPixel" 
                        :compact=true                          
                    /> 
                </div>
                <div class="card" v-if="!showDaySplit">
                    <datatable
                        tableId="tagCountList"
                        :columns="tagColumns"
                        :data="filteredCountsPixel" 
                        :compact=true                          
                    /> 
                </div>
                
            </div>
            <div class="form-group row">
                <label for="thresholdPartnerBeacon" class="col-sm-1 col-form-label">Threshold:</label>
                <div class="col-sm-2">
                    <input type="number" class="form-control" id="thresholdPartnerBeacon" v-model="threshold_partnerBeacon" step="1000">
                </div>
                <div class="col-sm-2 col-centered"> <h3>Counts By Partner Domain</h3> </div>
                <div class="col-sm-4 text-end" >
                    <button class="btn btn-primary" @click="(showDaySplit)?exportCSV('partner_domain_day_counts', filteredCountsPartnerBeacon):exportCSV('partner_domain_count', filteredCountsPartnerBeacon)">
                            Export CSV
                    </button>
                </div>
            </div>
            <div class="">
                <div class="card" v-if="showDaySplitTable && showDaySplit">                    
                    <datatable
                        tableId="partnerDailyCountList"
                        :columns="partnerDailyColumns"
                        :data="filteredCountsPartnerBeacon" 
                        :compact=true                             
                    />  
                </div>
                <div class="card" v-if="!showDaySplit">                    
                    <datatable
                        tableId="partnerCountList"
                        :columns="partnerColumns"
                        :data="filteredCountsPartnerBeacon" 
                        :compact=true                             
                    />  
                </div>
            </div>
            <div class="form-group row">
                <label for="thresholdRedirect" class="col-sm-1 col-form-label">Threshold:</label>
                <div class="col-sm-2">
                    <input type="number" class="form-control" id="thresholdRedirect" v-model="threshold_redirect" step="1000">
                </div>
                <div class="col-sm-2 col-centered"> <h3>Counts By Redirect Domain</h3> </div>
                <div class="col-sm-4 text-end" >
                    <button class="btn btn-primary" @click="(showDaySplit)?exportCSV('redirect_domain_day_counts', filteredCountsRedirectPixel):exportCSV('redirect_domain_counts', filteredCountsRedirectPixel)">
                            Export CSV
                    </button>
                </div>
            </div>
            <div class="">
                <div class="card" v-if="showDaySplitTable && showDaySplit">                    
                    <datatable
                        tableId="redirectDailyCountList"
                        :columns="directDailyColumns"
                        :data="filteredCountsRedirectPixel"  
                        :compact=true                            
                    /> 
                </div>
                <div class="card" v-if="!showDaySplit">                    
                    <datatable
                        tableId="redirectCountList"
                        :columns="directColumns"
                        :data="filteredCountsRedirectPixel"  
                        :compact=true                            
                    /> 
                </div>
            </div>
        </div>
        <div v-if="reportType=='country'&&!errMode">
            <div class="form-group row">
                <label for="thresholdTagtype" class="col-sm-1 col-form-label">Threshold:</label>
                <div class="col-sm-2">
                    <input type="number" class="form-control" id="thresholdTagType" v-model="threshold_country" step="1000">
                </div>
                <div class = "col-sm-2 col-centered"> <h3>Counts By Tag Type</h3> </div>
                <div class="col-sm-4 text-end" >
                    <button v-if="!showsplitByStatusTable" class="btn btn-primary" @click="(showDaySplit)?exportCSV('Tag_day_country_counts', filteredCountsCountry):exportCSV('Tag_country_counts', filteredCountsCountry)">
                            Export CSV
                    </button>
                    <button v-if="showsplitByStatusTable" class="btn btn-primary" @click="exportCSV('Tag_day_country_counts_by_status', filteredSplitByStatusCount)">
                            Export CSV
                    </button>
                </div>
            </div>
            <div class="">
                <div class="card"  v-if="!showDaySplit && showCountryListTable">
                    <datatable
                        tableId="CountryCountList"
                        :columns="countryColumns"
                        :data="filteredCountsCountry" 
                        :compact=true                          
                    />                       
                    
                </div>
                <div class="card"  v-if="showDaySplit"> 
                    <div class="row  card-body" >
                        <div class="col-sm-2"><h3>Filters:</h3></div>
                        <div class="col-sm-3">
                            <div class="input-container">
                                <label class="input-label"
                                    >Country &nbsp;</label
                                >
                                <select                                    
                                    v-model="selectedCountry"
                                >
                                    <option value="All:All">All</option>
                                    <option
                                        v-for="option in countriesUnique"
                                        :key="option.cc"
                                        :value="option.cc"
                                    >
                                        {{ option.cc }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4 " v-if="!splitByStatus">
                            <div class="input-container">
                                <label class="input-label"
                                    >TagType &nbsp;</label
                                >
                                <select                                    
                                    v-model="selectedTag"
                                >
                                    <option value="All:All">All</option>
                                    <option
                                        v-for="option in tagTypesUnique"
                                        :key="option.tagtype"
                                        :value="option.tagType"
                                    >
                                        {{ option.tagType }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div :class="showsplitByStatusTable?'col-md-7':'col-md-3'" class="my-auto text-end" >
                            <div class="custom-control form-switch" >
                                <input type="checkbox" class="form-check-input" id="customSwitch2" v-model="splitByStatus">
                                <label style="padding-left:5px" class="form-check-label" for="customSwitch2">Split By Status</label>
                            </div>
                        </div>
                    </div>
                    <div v-if="showCountryDailyTable && showDaySplit && !splitByStatus">
                        <datatable                        
                        tableId="dailyCountryCountList"
                        :columns="dailyCountryColumns"
                        :data="filteredCountsCountry" 
                        :compact=true                          
                        />
                    </div>
                    <div  v-if="showsplitByStatusTable && showDaySplit && splitByStatus">
                        <complexHeaderDatatable                       
                        tableId="dailyCountrySplitCountList"
                        :columns="dailyCountryStatusColumns"
                        :data="filteredSplitByStatusCount"                    
                        />  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
import axios from 'axios';
import { mapActions , mapState} from "vuex";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from "dayjs";
import InputField from "../../components/InputField.vue";
import Datatable from "../../components/Datatable.vue";
import complexHeaderDatatable from "../../components/ComplexHeaderDatatable";
import csv from "../../utils/tools/csv"
dayjs.extend(customParseFormat)


export default {
    components: { BasicHeader, InputField, Datatable, complexHeaderDatatable },
    data() {
        return {
                classItems: null,
                currentCls: null,
                selectedCountry: "All:All",
                selectedTag: "All:All",
                splitByStatus: false,
                showDaySplitTable: false,
                showsplitByTable: false,
                showsplitByStatusTable: false,
                showCountryListTable: false,
                showCountryDailyTable: false,
                reportOptions: [
                    {label:"Tag Type", value:"tag_type"},
                    {label:"Country", value: "country"}
                ],
                reportType: "tag_type",
                dateOptions: [
                    { label: "Today("+dayjs().format('YYYY-MM-DD')+")", value: "today"},
                    { label: "Yesterday("+dayjs().subtract(1, "day").format('YYYY-MM-DD')+")", value: "yesterday"},
                    { label: "Custom Range", value: "custom"}
                ], 
                config: {
                    wrap: true, 
                    enableTime: true,
                    dateFormat: 'm-d-Y H',
                    time_24hr: true
                },          
                dateType: "yesterday",    
                today: dayjs().format('YYYYMMDD'),
                yesterday: dayjs().subtract(1, "day").format('YYYYMMDD'),
                startDate: dayjs().subtract(1, "day").hour(0).format('MM-DD-YYYY HH'),
                endDate: dayjs().subtract(1, "day").hour(23).format('MM-DD-YYYY HH'),
                startTime: dayjs().hour(0).format('HH'),
                endTime: dayjs().hour(23).format('HH'),
                tagColumns: [
                    { 
                        data: "tagType",
                        title: "Tag Type"
                    },
                    { 
                        data: "count", 
                        title: "Count"
                    }           
                ],
                tagDailyColumns: [],
                partnerDailyColumns: [],
                directDailyColumns:[],
                partnerColumns: [
                    { 
                        data: "beaconId",
                        title: "Beacon Id"
                    },
                    { 
                        data: "description", 
                        title: "Description"
                    },
                    { 
                        data: "partnerDomain", 
                        title: "Partner Domain"
                    },
                    { 
                        data: "count", 
                        title: "count"
                    }             
                ],
                directColumns: [
                    { 
                        data: "partnerDomain",
                        title: "Redirect Domain"
                    },
                    { 
                        data: "count", 
                        title: "Count"
                    }           
                ],
                countryColumns: [],   
                dailyCountryColumns: [],     
                dailyCountryStatusColumns: [],
                showDaySplit: false,
                threshold_tagType: 1000,
                threshold_partnerBeacon: 1000,
                threshold_redirect: 1000,
                threshold_country: 1000,
                countsPixel: [],
                countsPartnerBeacon: [],
                countsRedirectPixel: [],
                countsCountry: [],
                filteredCountsPixel: null,
                filteredCountsPartnerBeacon: null,
                filteredCountsRedirectPixel: null,
                filteredCountsCountry:null,
                filteredSplitByStatusCount: null,
                counts: {},
                dailyTagColumns: [],
                tagTypesUnique: [],
                countriesUnique: [],
                splitByStatusCounts: [],
                errMode: false,
                errMsg: ''

            }
        },
    mounted() {
        this.readClient();
    },
    computed: {
     ...mapState({    
            cid: state => state.currentClient.cid       
        })
    },
    watch: {
        dateType() {            
            this.loadCounts(); 
        },
        cid() {
            this.loadCounts(); 
        },
        showDaySplit(val) {
            this.loadCounts(); 
        },
        splitByStatus(val) {
            this.showSplitByStatus(val);
        },
        selectedCountry(val) {
            this.filterDailyCountsCountryTT(this.selectedTag, this.selectedCountry);
        },
        selectedTag(val) {
            this.filterDailyCountsCountryTT(this.selectedTag, this.selectedCountry);
        },
        threshold_country(val) {
            if(!this.showDaySplit) {
                this.filteredCountsCountry = this.countsCountry.filter(obj => {
                    return obj.total > val;
                });
            } else {
                this.filteredCountsCountry = this.countriesUnique.filter(obj => {
                    return obj.count > val;
                });
            }           
        },
        threshold_tagType(val) {
            if(!this.showDaySplit) {
                this.filteredCountsPixel = this.countsPixel.filter(obj => {
                    return obj.count > val;
                });
            } else {
                this.filteredCountsPixel = this.tagTypesUnique.filter(obj => {
                    return obj.count > this.threshold_tagType;
                });
            }            
        },
        threshold_partnerBeacon(val) {
            if(!this.showDaySplit) {
                this.filteredCountsPartnerBeacon = this.countsPartnerBeacon.filter(obj => {
                    return obj.count > val;
                });
            } else {
                this.filteredCountsPartnerBeacon = this.partnerDomainsUnique.filter(obj => {
                    return obj.count > this.threshold_partnerBeacon && obj.beaconId!="All";
                });               
            }
        },
        threshold_redirect(val) {
            if(!this.showDaySplit) {
                this.filteredCountsRedirectPixel = this.countsRedirectPixel.filter(obj => {
                    return obj.count > val;
                });
            } else {
                this.filteredCountsRedirectPixel = this.redirectDomainsUnique.filter(obj => {
                    return obj.count > this.threshold_redirect;
                });
            }
        }
    },
    methods: {
    ...mapActions({
            fecthClient: "aut/fetchClient"
        }),
        
        async readClient() {
            let response;
            try {
                response = await this.fecthClient();
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }

            this.clientData = await response.data;
            this.classItems = this.clientData.classes.items;   
            this.currentCls = this.classItems[0].cls;
            this.loadCounts();
        },

        async loadCounts() {
            let self = this;
            let clsString = ''; 
            this.errMode = false;

            this.filteredCountsPixel= null;
            this.filteredCountsPartnerBeacon= null;
            this.filteredCountsRedirectPixel= null;
            this.filteredCountsCountry= null;
            this.showCountryListTable = false;
            
            if(this.dateType == "today") {
                this.toDate = this.today;
                this.fromDate = this.today;
            } else if(this.dateType == "yesterday") {
                this.toDate  = this.yesterday;
                this.fromDate = this.yesterday;
            } else if(this.dateType == "custom") {
                this.fromDate = dayjs(this.startDate, 'MM-DD-YYYY HH', true).format("YYYYMMDD");
                this.startTime = dayjs(this.startDate, 'MM-DD-YYYY HH', true).format("HH"); 
                this.toDate = dayjs(this.endDate, 'MM-DD-YYYY HH', true).format("YYYYMMDD");  
                this.endTime = dayjs(this.endDate, 'MM-DD-YYYY HH', true).format("HH");
            }

            if(this.dateDiffInDays(this.fromDate, this.toDate) > 95) {
                this.errMsg = "Request Denied. Requested date range is more than 3 months.";
                this.errMode = true;
                return '';
            }
            
            if(this.currentCls) {
                clsString = '/clss/' + this.currentCls;
            }

            if (this.clientData && this.clientData.config.pidCounts == true) {
                this.getPidCounts = true;
            } else {
                this.getPidCounts = false;
            }

            // Daily Counts
            if (this.showDaySplit) {
                axios.get(`${this.$store.state.countsUrl}/v1/latency/cids/${this.cid}${clsString}/from/${this.fromDate}/until/${this.toDate}?daily=true&bg=true`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },
                    }
                ).then(function (response) {
                    self.watchCountsQuery(response.data.query_execution_id, 'latency', 30000, function(response) {

                        axios.get(`${self.$store.state.countsUrl}/v1/latency/cids/${self.cid}${clsString}/from/${self.fromDate}/until/${self.toDate}?daily=true`,
                        {
                            headers: {
                                Authorization: `Bearer ${self.$store.state.accessToken}`,
                            },
                        }
                    ).then(function (response) {
                            self.countsLoadingLatency = false;
                            self.countriesPBLatency = {};                           
                            // Get all column indexes for data row from columnDefs
                            let cIndex, ccIndex, dIndex, pdIndex, sIndex, tIndex;
                            for (let i = 0; i < response.data.columnDefs.length; i++) {
                                switch (response.data.columnDefs[i].name) {
                                    case "date":
                                    dIndex = i;
                                    break;
                                    case "tag_type":
                                    tIndex = i;
                                    break;
                                    case "country_code":
                                    ccIndex = i;
                                    break;
                                    case "partnerDomain":
                                    pdIndex = i;
                                    break;
                                    case "load_status":
                                    sIndex = i;
                                    break;
                                    case "count":
                                    cIndex = i;
                                    break;
                                }
                                
                            }
                            // use above indexes to extract data from array of data rows
                            for (let i = 0; i < response.data.data.length; i++) {
                                let obj = response.data.data[i]
                                let ccode =  obj[ccIndex].toUpperCase()
                                if (obj[cIndex].match(/\d+/) != null && obj[tIndex] === 'partner_beacon' && !obj[pdIndex].includes('NA:')) {
                                let count = parseInt(obj[cIndex])
                                let key = obj[dIndex] + obj[pdIndex] + obj[ccIndex] + obj[sIndex]
                                let allKey = obj[dIndex] + 'All:All' + obj[ccIndex] + obj[sIndex]
                                let colTotalKey = obj[dIndex] + obj[pdIndex] + "_" + obj[sIndex]
                                let colTotalAllKey = obj[dIndex] + 'All:All' + "_" + obj[sIndex]
                                let rowTotalKey = obj[dIndex] + obj[pdIndex] + obj[ccIndex] + "_"
                                let rowTotalAllKey = obj[dIndex] + 'All:All' + obj[ccIndex] + "_"
                                let allTotalKey = obj[dIndex] + obj[pdIndex] + "_" + "_"
                                let allTotalAllKey = obj[dIndex] + 'All:All' + "_" + "_"
                                self.updateCountriesPBLatencyCounts(key,count)
                                self.updateCountriesPBLatencyCounts(colTotalKey,count)
                                self.updateCountriesPBLatencyCounts(rowTotalKey,count)
                                self.updateCountriesPBLatencyCounts(allTotalKey,count)
                                self.updateCountriesPBLatencyCounts(allKey,count)
                                self.updateCountriesPBLatencyCounts(colTotalAllKey,count)
                                self.updateCountriesPBLatencyCounts(rowTotalAllKey,count)
                                self.updateCountriesPBLatencyCounts(allTotalAllKey,count)
                                }
                            }
                        
                        });
                       
                    });
                });
                axios.get(`${self.$store.state.countsUrl}/v1/cids/${self.cid}${clsString}/from/${self.fromDate}/00/until/${self.toDate}/23?skipCountry=true&daily=true`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },

                    }
                ).then(function (response) {
                    self.updateDailyReports(response.data)
                });


                axios.get(`${self.$store.state.countsUrl}/v1/cids/${self.cid}${clsString}/from/${self.fromDate}/00/until/${self.toDate}/23?daily=true&bg=true`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },

                    }
                ).then(function (response) {
                    self.watchCountsQuery(response.data.query_execution_id, 'skipCountry', 30000, function(response) {
                        axios.get(`${self.$store.state.countsUrl}/v1/cids/${self.cid}${clsString}/from/${self.fromDate}/00/until/${self.toDate}/23?daily=true`,{
                            headers: {
                                Authorization: `Bearer ${self.$store.state.accessToken}`,
                            },
                        }).then(function (response) {
                            self.countsLoading = false;
                            if (!response.data) {
                                return;
                            }
                            self.updateDailyReports(response.data)
                        });
                    });
                }); 

            } else {  //Counts between given time period 
                this.showDaySplitTable = false;  
                this.showCountryDailyTable = false;
                let response;

                try {
                    response = await this.dailyCounts(
                                clsString,
                                this.fromDate,
                                this.toDate,
                                this.startTime,
                                this.endTime
                                )
                } catch(error) {
                    this.errMode = true;
                    if (error.response.data.error)
                        this.errMsg = error.response.data.error;
                    else this.errMsg = error.message;
                    return;
                }  

                this.updateReports(response.data);
                
                axios.get(`${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/from/${this.fromDate}/${this.startTime}/until/${this.toDate}/${this.endTime}?bg=true`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },

                    }
                ).then(function (response) {
                    self.watchCountsQuery(response.data.query_execution_id, 'skipCountry', 30000, function(response) {
                        self.countsLoading = false
                        if (!response.data) {
                            return;
                        }
                        self.updateReports(response.data)
                    });
                });
            }
            
            

            // Revisit Code if client data has pidCounts set 
            // if (this.getPidCounts && this.reportType != "tagType") {
            //     this.loadPidCount();
            // }
            
        },
        updateReports(d) {
            let self = this;
            this.countsPartnerBeacon = [];
            this.countsPixel = [];
            this.countsRedirectPixel = [];
            this.totalCount = d.count;
            this.countries= [];
            this.countsCountry = [];
            this.countryColumns = [];
            this.countryColumns.push({
                data: "country",
                title: "Country"
            })

            if (d["tagType_countryCode"]) {
                this.countsCountries = d.tagType_countryCode;
            }
            
                for (let cc in d.countryCode) {              
                    this.countries.push({
                        country: cc,
                        count: d.countryCode[cc]
                    })              
                }
                for(let cData in this.countsCountries) {
                    this.countryColumns.push({
                        data: cData,
                        title: cData
                    });                
                }

                for( const country of this.countries) {
                    let countryData = {};
                    countryData.country = country.country;
                    let total = 0;
                    for(let cData in this.countsCountries) {                    
                        if(this.countsCountries[cData][country.country]) {
                            countryData[cData] = this.countsCountries[cData][country.country];
                        } else  {
                            countryData[cData] = 0;
                        } 
                        total = total + countryData[cData];            
                                
                    }
                    countryData.total = total;
                    this.countsCountry.push(countryData);

                }
                this.countryColumns.push({
                    data: "total",
                    title: "Total"
                });
                this.filteredCountsCountry = this.countsCountry.filter(obj => {
                    return obj.total > self.threshold_country;
                })

                this.totalCount = d.count;
                this.appendCounts(d.tagType, this.countsPixel, 'tagType');     
                this.appendCounts(d.partnerBeacon, this.countsPartnerBeacon, 'partnerDomain', 'beaconId'); 
                this.appendCounts(d.redirect, this.countsRedirectPixel, 'partnerDomain', 'beaconId');  

                
                this.filteredCountsPixel = this.countsPixel.filter(obj => {
                    return obj.count > this.threshold_tagType;
                });

                this.filteredCountsPartnerBeacon = this.countsPartnerBeacon.filter(obj => {
                    return obj.count > this.threshold_partnerBeacon;
                });
        
                this.filteredCountsRedirectPixel = this.countsRedirectPixel.filter(obj => {
                    return obj.count > this.threshold_redirect;
                });

                this.showCountryListTable = true;

            
         
            this.mergeCounts(this.countsPartnerBeacon, 'beaconId', 'partnerDomain');
        },

        updateDailyReports(d) {
            let self = this; 
            this.showDaySplitTable = false;
            this.showCountryDailyTable = false;
            this.countsPartnerBeaconSP = [];
            this.countsPixelSP = [];
            this.countsRedirectPixelSP = [];
            this.dailyCountsCountries = [];
            this.countsCountriesTTSP = [];
            this.countsCountriesPBSP = [];
            this.tagTypesUnique = [];
            this.partnerDomainsUnique = [{
                count: 0,
                partnerDomain: 'All',
                beaconId: 'All'
            }];

            this.tagDailyColumns = [];
            this.directDailyColumns = [];
            this.partnerDailyColumns = [];
            this.tagDailyColumns.push({ 
                            data: "tagType",
                            title: "Tag Type"
                        });   
            this.partnerDailyColumns.push(
                { 
                    data: "beaconId",
                    title: "Beacon Id"
                },
                { 
                    data: "description", 
                    title: "Description"
                },
                { 
                    data: "partnerDomain", 
                    title: "Partner Domain"
                },

            );
            this.directDailyColumns.push(
                { 
                    data: "redirectDomain",
                    title: "Redirect Domain"
                }  
            );
            this.dailyCountryColumns = [];
            this.dailyCountryColumns.push({
                data: "cc",
                title: "Country"
            });       
            this.redirectDomainsUnique = [];
            this.countriesUnique = [];
            var tempTtObj = {};
            var tempPdObj = {};
            var tempRdObj = {};
            var tempCcObj = {};
            for (let day in d) {        
                if (this.$store.state.environment === "test" && day.substring(9) != "") {
                    continue;
                }
                let dayStr = day.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                this.tagDailyColumns.push({data: dayStr, title: dayStr});
                this.partnerDailyColumns.push({data: dayStr, title: dayStr});
                this.directDailyColumns.push({data: dayStr, title: dayStr});
                this.dailyCountryColumns.push({data: dayStr, title: dayStr});
                this.countsPixelSP.push({
                    day: dayStr,
                    tagType: d[day].tagType
                });
                this.countsPartnerBeaconSP.push({
                    day: dayStr,
                    partnerDomain: d[day].partnerBeacon
                });
                this.countsRedirectPixelSP.push({
                    day: dayStr,
                    partnerDomain: d[day].redirect
                });
                this.countsCountriesTTSP.push({
                    day: dayStr,
                    tagType_cc: d[day].tagType_countryCode
                });
                this.countsCountriesPBSP.push({
                    day: dayStr,
                    partnerDomain_cc: d[day].partnerBeacon_countryCode
                });
                this.transformCounts(d[day].tagType, tempTtObj);
                this.transformCounts(d[day].partnerBeacon, tempPdObj);
                this.transformCounts(d[day].redirect, tempRdObj);
                this.transformCounts(d[day].countryCode, tempCcObj);
                
                
            }

            this.tagDailyColumns.push({ 
                            data: "count", 
                            title: "Count"
                        });
            this.partnerDailyColumns.push({ 
                            data: "count", 
                            title: "Count"
                        });
            this.directDailyColumns.push({ 
                            data: "count", 
                            title: "Count"
                        });

            this.appendCounts(tempTtObj, this.tagTypesUnique, 'tagType');
            this.appendCounts(tempPdObj, this.partnerDomainsUnique, 'partnerDomain', 'beaconId');
            this.appendCounts(tempRdObj, this.redirectDomainsUnique, 'redirectDomain', 'beaconId');
            this.appendCounts(tempCcObj, this.countriesUnique, 'cc');
            let dupkeys = this.mergeCounts(this.partnerDomainsUnique, 'beaconId', 'partnerDomain')
            this.mergeDuplicates(dupkeys, this.countsPartnerBeaconSP, "partnerDomain")
            this.mergeDuplicates(dupkeys, this.countsCountriesPBSP, "partnerDomain_cc")
                this.countsPixelSP.forEach((cPSP, i) => {
                    self.tagTypesUnique.forEach((ttu) => {
                        ttu[cPSP.day] = cPSP.tagType[ttu.tagType];
                    })
                })

                this.countsRedirectPixelSP.forEach((cRPSP, i) => {                
                    self.redirectDomainsUnique.forEach((rdu) => { 
                        let k = rdu.beaconId+":"+rdu.redirectDomain                
                        rdu[cRPSP.day] = cRPSP.partnerDomain[k];
                    })
                })

            this.countsPartnerBeaconSP.forEach((cPBSP, i) => {
                if (!self.countsPartnerBeaconSP[i].partnerDomain) {
                self.countsPartnerBeaconSP[i].partnerDomain = {}
                }
                self.countsPartnerBeaconSP[i].partnerDomain["All:All"] = 0
                for (let key in cPBSP.partnerDomain) {
                if (key !== "All:All" && cPBSP.partnerDomain[key]) {
                    self.countsPartnerBeaconSP[i].partnerDomain["All:All"] += cPBSP.partnerDomain[key]
                }
                }
                self.partnerDomainsUnique.forEach((pdu) => {
                let k = pdu.beaconId+":"+pdu.partnerDomain
                if (cPBSP.partnerDomain[k]) {
                    pdu[cPBSP.day] = self.countsPartnerBeaconSP[i].partnerDomain[k] || 0
                }
                if (k == "All:All") {
                    pdu.count += self.countsPartnerBeaconSP[i].partnerDomain[k]
                }
                });
            });

            this.countsCountriesTTSP.forEach((ccTTSP, i) => {
                if (!self.countsCountriesTTSP[i].tagType_cc) {
                    self.countsCountriesTTSP[i].tagType_cc = {}
                }

                self.countsCountriesTTSP[i].tagType_cc["All:All"] = {};
                for(let key in ccTTSP.tagType_cc) {
                    for(let k in ccTTSP.tagType_cc[key]) {
                        if(key !== "All:All" && ccTTSP.tagType_cc[key][k]) {
                            if (!self.countsCountriesTTSP[i].tagType_cc["All:All"][k]) {
                                self.countsCountriesTTSP[i].tagType_cc["All:All"][k] = ccTTSP.tagType_cc[key][k]
                            } else {
                                self.countsCountriesTTSP[i].tagType_cc["All:All"][k] += ccTTSP.tagType_cc[key][k]
                            }
                        }
                    }
                }
            })

            this.countsCountriesPBSP.forEach((ccPBSP, i) => {
                if (!self.countsCountriesPBSP[i].partnerDomain_cc) {
                self.countsCountriesPBSP[i].partnerDomain_cc = {}
                }
                self.countsCountriesPBSP[i].partnerDomain_cc["All:All"] = {}
                for (let key in ccPBSP.partnerDomain_cc) {
                if (ccPBSP.partnerDomain_cc[key]) {
                    for (let k in ccPBSP.partnerDomain_cc[key]) {
                    if (key !== "All:All" && ccPBSP.partnerDomain_cc[key][k]) {
                        if (!self.countsCountriesPBSP[i].partnerDomain_cc["All:All"][k]) {
                        self.countsCountriesPBSP[i].partnerDomain_cc["All:All"][k] = ccPBSP.partnerDomain_cc[key][k]
                        } else {
                        self.countsCountriesPBSP[i].partnerDomain_cc["All:All"][k] += ccPBSP.partnerDomain_cc[key][k]
                        }
                    }
                    }
                }
                }
            });

            this.filteredCountsPixel = this.tagTypesUnique.filter(obj => {
                    return obj.count > this.threshold_tagType;
                });
            this.filteredCountsPartnerBeacon = this.partnerDomainsUnique.filter(obj => {
                return obj.count > this.threshold_partnerBeacon && obj.beaconId!="All";
            });
            this.filteredCountsRedirectPixel = this.redirectDomainsUnique.filter(obj => {
                return obj.count > this.threshold_redirect;
            });
            this.filterDailyCountsCountryTT("All:All", "All:All");
            this.showDaySplitTable = true;
            this.showCountryDailyTable = true;

        },

        filterDailyCountsCountryTT(tagType, country) {
            let self = this;

            this.countsCountriesTTSP.forEach((cCTTSP, i) => {
                self.countriesUnique.forEach((ccu) => {
                    ccu[cCTTSP.day] = cCTTSP.tagType_cc[tagType][ccu.cc];
                })
            });
            this.filteredCountsCountry = this.countriesUnique.filter(obj => {
                return (country == "All:All")?obj.count > this.threshold_country : ((obj.count > this.threshold_country) && obj.cc == country);
            });

        },

        showSplitByStatus(splitByStatus) {
            let self = this;
            this.filteredSplitByStatusCount = null;
            if(splitByStatus) {
                this.dailyCountryStatusColumns = [];
                
                this.dailyCountryStatusColumns.push({
                    data: "cc",
                    title: "Country",
                    rowspan: 2
                }); 
                
                this.countsCountriesPBSP.forEach((cCTTSP, i) => {
                    self.dailyCountryStatusColumns.push({
                        data: cCTTSP.day,
                        title: cCTTSP.day,
                        colspan: 4
                    }); 
                    self.dailyCountryStatusColumns.push({
                        data: "success",
                        title: "Success",
                    }); 
                    self.dailyCountryStatusColumns.push({
                        data: "failed",
                        title: "Falied",
                    }); 
                    self.dailyCountryStatusColumns.push({
                        data: "timeout",
                        title: "Timeout",
                    });
                    self.dailyCountryStatusColumns.push({
                        data: "total",
                        title: "Total",
                    });  
                        
                });

                this.splitByStatusCounts= [];
                for (let obj of this.countriesUnique){
                    let splitByStatusCountObj = {};
                    splitByStatusCountObj.cc = obj.cc;
                   for (let PBSPObj of this.countsCountriesPBSP) {
                       splitByStatusCountObj.success = this.getDayCountStatusSplit(PBSPObj.day,"All:All",obj.cc,"success");
                       splitByStatusCountObj.failed = this.getDayCountStatusSplit(PBSPObj.day,"All:All",obj.cc,"failure");
                       splitByStatusCountObj.timeout = this.getDayCountStatusSplit(PBSPObj.day,"All:All",obj.cc,"timeout");
                       splitByStatusCountObj.total = this.getDayCountStatusSplit(PBSPObj.day,"All:All",obj.cc,"_");

                   }  
                   this.splitByStatusCounts.push(splitByStatusCountObj);  
                } 
                this.filteredSplitByStatusCount = this.splitByStatusCounts.filter(obj => {
                    return (typeof obj.total !== 'undefined' && obj.total !== null);
                });
                             
                this.showsplitByStatusTable = true;

            } else {
                this.showsplitByStatusTable = false;
            }
        },

        getDayCountStatusSplit(date, pd, cc, status) {
            let d = date.replace(/-/g, '')
            let key = d + pd + cc + status;
            return this.countriesPBLatency[key]
        },

        loadPidCount() {
            let self = this;
            let s = this.today,
            e = this.today,
            query_name = this.cid + '_counts_by_pid',
            query_params = 'where=[{"key":"event_date","op":">=","val":[' + s +
                ']},{"key":"event_date","op":"<=","val":[' + e + ']}]';

            if (this.reportType == 'partner') {
            query_params += '&select=event_date AS Date, pid AS Pid, host_name ' +
                'AS HostName, tag_type AS TagType, sum(counts) AS Counts' +
                '&groupby=GROUP BY event_date,pid,host_name,tag_type';
            } else {
            query_name = this.cid + '_counts_cc_by_pid';
            query_params += '&select=event_date AS Date, pid AS Pid, host_name ' +
                'AS HostName, country_code AS CC, tag_type AS TagType, counts AS Counts';
            }
            axios.post(this.$store.state.pailUrl +
            '/v1/cids/' + this.cid +
            '/named_queries/' + query_name + '/execute?' +
            encodeURI(query_params)).then(function (response) {
                var query_id = response.data.query_execution_id
                this.loadingPidData = true;
                if (this.$store.state.environment === "test") {
                    switch (this.reportType) {
                    case 'partner':
                        this.pidCounts = []
                        query_id = "pidReport"
                        break;
                    case 'countries':
                        this.pidCcCounts = []
                        query_id = "pidCCReport"
                        break;
                    }
                    self.sleep(100).then(function () {
                        self.getQueryData(query_id, 120);
                    });
                } else {
                    if (query_id != "" && query_id != undefined) {
                        self.sleep(2000).then(function () {
                            self.getQueryData(query_id, 120);
                        });
                    } else {
                        alert("Failed to submit query on athena, please contact support...")
                        self.loadingPidData = false;
                    }
                }
            });
        },

        getQueryData(query_id, counter) {
            let self = this;
            axios.get(this.$store.state.pailUrl + '/v1/cids/' +
            this.cid + '/query_executions/' + query_id).then(function (response) {
            if (response.data.message) {
                var errMsg = JSON.stringify(response.data.message)
                if ((errMsg.includes("QUEUED") || errMsg.includes("RUNNING")) && counter > 0) {
                counter--;
                self.sleep(5000).then(function () {
                    self.getQueryData(query_id, counter, self.cid);
                });
                } else {
                    self.loadingPidData = false;
                }
            } else {
                self.loadingPidData = false;
                if (self.getPidCounts) {
                if (response.data.partial_result) {
                    axios.get(self.$store.state.pailUrl + '/v1/cids/' + self.cid +
                    '/query_result_file/' + query_id).then(function (resp) {
                    var data = resp.data.replace(/"/g, '').split('\n');
                    var column_info = data.shift().split(',');
                    switch (self.reportType) {
                        case 'partner':
                            self.pidCounts = self.convertToTableJson({column_info: column_info, data: data})
                            break;
                        case 'countries':
                            self.pidCcCounts = self.convertToTableJson({column_info: column_info, data: data})
                            break;
                    }
                    });
                } else {
                    switch (self.reportType) {
                    case 'partner':
                        self.pidCounts = self.convertToTableJson(response.data);
                        break;
                    case 'countries':
                        self.pidCcCounts = self.convertToTableJson(response.data);
                        break;
                    }
                }
                } else {
                self.pidCounts = [];
                self.pidCcCounts = [];
                }
            }
            });
        },

        convertToTableJson(obj) {
            var c = [];
            for (var i = 0; i < obj.data.length; i++) {
            var temp = {}
            for (var j = 0; j < obj.column_info.length; j++) {
                if (obj.column_info[j].name == 'Counts') {
                temp[obj.column_info[j].name] = parseInt(obj.data[i][j]) | 0
                } else {
                temp[obj.column_info[j].name] = obj.data[i][j]
                }
            }
            c.push(temp);
            }
            return c;
        },

        sleep(time) {
            return new Promise(function (resolve) {
            setTimeout(resolve, time);
            });
        },

        mergeCounts(src, pkey, skey) {
            let uniqKeys = {};
            let dupKeys = {};
            for (let i = 0; i < src.length; i++) {
                if (uniqKeys[src[i][pkey]]) {
                if (src[i][skey] == 'NA' || src[i][skey] == uniqKeys[src[i][pkey]][skey]) {
                    uniqKeys[src[i][pkey]].count += src[i].count;
                    dupKeys[uniqKeys[src[i][pkey]][pkey] + ":" + uniqKeys[src[i][pkey]][skey]] =
                    src[i][pkey] + ":" + src[i][skey]
                    src.splice(i, 1);
                    i--;
                } else if (uniqKeys[src[i][pkey]][skey] == 'NA') {
                    uniqKeys[src[i][pkey]][skey] = src[i][skey];
                    uniqKeys[src[i][pkey]].count += src[i].count;
                    src.splice(i, 1);
                    i--;
                }
                } else {
                uniqKeys[src[i][pkey]] = src[i];
                }
            }
            return dupKeys;
        },

        appendCounts(input, output, keyName, splitKeyname) {            
          for (let k in input) {            
                let v = {
                    count: input[k]
                };
                if (splitKeyname) {
                    let m = k.match(/^(([a-z]-[a-z0-9]*)|NA):(.*)$/);
                    if (m) {
                        v[splitKeyname] = m[1]
                        v[keyName] = m[3]
                    } else {
                        v[splitKeyname] = 'NA'
                        v[keyName] = k
                    }
                } else {
                    v[keyName] = k;
                }
                if (keyName == 'partnerDomain' && splitKeyname == 'beaconId') {
                    v['description'] = this.getBeaconDescription(v[splitKeyname])
                }
                output.push(v);            
            }
        },

        transformCounts(src, dst) {
          for (let tt in src) {           
            if (tt in dst) {
                dst[tt] += src[tt];
            } else {
                dst[tt] = src[tt];
            }
            
          }
        },

        transformDailyCounts(date, src, dst) {
          for (let tt in src) {           
            if (tt in dst) {
                dst[tt] += src[tt];
            } else {
                dst[tt] = src[tt];
            }
            
          }
        },

        mergeDuplicates(dupKeys, dst, key) {
        let self = this;
          if (dst) {
            for (let k in dupKeys) {
              dst.forEach(function (obj) {
                if (obj[key][k] && obj[key][dupKeys[k]]) {
                  obj[key][k] = self.mergeObjs(obj[key][k], obj[key][dupKeys[k]])
                } else if (obj[key][dupKeys[k]]) {
                  obj[key][k] = obj[key][dupKeys[k]];
                }
              });
            }
            
          }
        },

        mergeObjs(src, dst) {
          if (typeof(src) == "number") {
            src += dst;
          } else if (typeof(src) == "object") {
            for (var k in src) {
              if (src[k] && dst[k]) {
                src[k] = this.mergeObjs(src[k], dst[k]);
              }
            }
          }
          return src;
        },

        getBeaconDescription(bID) {
            let desc = "";  
            let found = false;          
            for (let i = 0; i < this.classItems.length; i++) {
            if (found) { break; }
            let clsObj = this.classItems[i]
            if (!this.currentCls || this.currentCls == clsObj.cls) {
                if (clsObj && clsObj.pixels && clsObj.pixels.items) {
                for (let j = 0; j < clsObj.pixels.items.length; j++) {
                    if (clsObj.pixels.items[j].beaconId == bID) {
                    found = true
                    desc = clsObj.pixels.items[j].description
                    break;
                    }
                }
                }
            }
            }            
            return desc
        },

        exportCSV(sufix, data) {            
            let fileName = `${this.fromDate}_${this.toDate}_${sufix}`; 
            csv.exportCSV(fileName, data)
        },

        watchCountsQuery(queryId, threadId, delay, callback, firstCall=true) {
            let self = this;
          // Must keep track of the latest query being checked, and abandon all others
          if (firstCall) {
            this.counts['currentCountsQuery'+threadId] = queryId;
          } else if (!this.counts['currentCountsQuery'+threadId] || this.counts['currentCountsQuery'+threadId] != queryId) {
            return
          }
          
          var queryStatusUrl = this.$store.state.countsUrl + "/v1/cids/"+this.cid+"/query/"+queryId;
            axios.get(queryStatusUrl,{
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },

                    }
                ).then(function (response) {
            if (response.data) {
              if (response.data.status == "RUNNING") {
                setTimeout(function(){
                  self.watchCountsQuery(queryId, threadId, delay, callback, false)
                }, delay)
              } else {
                callback(response.data);
              }
            }
          });
        },

        updateCountriesPBLatencyCounts(key, count) {
            if (this.countriesPBLatency[key]) {
                this.countriesPBLatency[key] += count
            } else {
                this.countriesPBLatency[key] = count
            }
        },

        dateDiffInDays(fromDate, toDate) {
            let hours = dayjs(toDate).diff(dayjs(fromDate), 'hours');
            const diffDays = Math.floor(hours / 24);
            return diffDays;
        },
        

        async dailyCounts(clsString, fromDate, toDate, startHour, endHour) {
            return axios.get(
                `${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/from/${fromDate}/${startHour}/until/${toDate}/${endHour}?skipCountry=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        }      
    }

}
</script>

<style scoped>
    #tag_reports .row {
        margin-bottom: 1.5rem;
    }
    .class-btn.active {
        background-color: #ffffff;
        color: #586a82;
        border-color: #ffffff;
    }

    .col-centered{
        float: none;
        margin: 0 auto;
    }

    .form-check-input:checked {
        background-color: #2C7BE5 !important;
        border-color: #2C7BE5;
    }

    .form-check-input {
        background-color: #6E84A3;
    }

</style>
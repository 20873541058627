<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-header-title">Documentation</h4>
        </div>
        <div class="card-body">
            <iframe @load="iframeLoaded" :src="frameSource"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        iframeSrc: String,
    },
    data() {
        return {
            frameSource: `${this.iframeSrc}?docsToken=${this.$store.state.docsToken}`
        }
    },
    methods: {
        iframeLoaded(event) {
            //remove iframe header
            var frame = event.target;
            var frameDocument =
                frame.contentDocument || frame.contentWindow.document;
            frameDocument.getElementsByTagName("header")[0].remove();
        },
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'setDocsToken') {
                this.frameSource = `${this.iframeSrc}?docsToken=${this.$store.state.docsToken}`
            }
        });
    },
    unmounted() {
        this.unsubscribe();
    }
};
</script>

<style scoped>

iframe {
    width:100%;
    height: 45vh;
}

</style>
<template>
    <div class="row" id="general-settings-div" v-if="generalSettings">
        <div class="col-md-7" >
            <div class="card-title clearfix">
                <h4 style="display: inline-block">{{cid}}</h4> 
                 <span v-if='editMode'>
                    <button @click="onSaveClick" class="btn btn-success"> Save </button>&nbsp;
                    <button @click="onCancelClick" class="btn btn-secondary"> Cancel </button>&nbsp;
                </span>
                <span v-else>
                    <button type="button" @click="onEditClick" class="btn btn-primary">Edit</button>
                </span>
            </div>
            <div class="card">                
                <div class="card-body">   
                    <div v-if="error" class="alert alert-danger" role="alert">
                        {{error}}
                    </div>
                    <div v-if="successMessage" class="alert alert-success" role="alert">
                        {{successMessage}}
                    </div>                
                    <form-fields :fields="autGeneralFields" v-model="generalSettings" v-if="generalSettings" :inlineField=true :editMode="editMode"/>                
                </div>
            </div>
        </div>
         <div class="col-md-5">
            <div class="card-title clearfix">
                <h4 style="display: inline-block">Deployment Status</h4>
                 <button type="button" class="btn btn-primary" @click="onActivateClick">Activate</button>
            </div>
            <div class="card" v-if="autdeploymentStatus">               
                <div class="card-body">
                    <div v-if="actError" class="alert alert-danger" role="alert">
                        {{actError}}
                    </div>
                    <div v-if="actSuccessMessage" class="alert alert-success" role="alert">
                        {{actSuccessMessage}}
                    </div>  
                    <form-fields :fields="deploymentStatusFields" v-model="autdeploymentStatus" v-if="autdeploymentStatus" :inlineField=true :editMode=false />                
                </div>
            </div>
             <div v-else class="text-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>             
        </div>
    </div>
    <div v-else class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div> 
  
</template>

<script>
import getAutGeneralFields from "../../../utils/field-constants/autGeneralFields";
import getDeploymentStatusFields from "../../../utils/field-constants/deploymentStatusFields";
import FormFields from "../../../components/FormFields.vue";
import { mapActions, mapState } from "vuex";

export default {
    components: { FormFields },
    data() {
        return {
            editMode: false,
            autGeneralFields: [],
            deploymentStatusFields: [],
            snapshot: null,
            error: "",
            successMessage: "",
            actError: "",
            actSuccessMessage: "",
            generalSettings: {}
        };
    }, 
    props: {
        cid: String,
        clientData: Object
    },
    computed: {
     ...mapState({    
            autdeploymentStatus: state => state.aut.deploymentStatus,
            prodClientData: state => state.aut.autProdClientData
        })       
   },
    watch: {
        generalSettings: {
            deep: true,
            handler(newVal) {
                this.autGeneralFields = this.hideFields(this.autGeneralFields, newVal); 
            }            
        },
        clientData() {
            this.initializeData();
        }
    },
    methods: {
        ...mapActions({
            updateAutClientData: "aut/updateAutClientData",
            fetchAutProdClientData: "aut/fetchAutProdClientData",            
            activateClientConfig: "aut/activateClientConfig",
            showModal: "showModal"
        }),
        initializeData(){  
            const autClientData =  {...this.$store.state.aut.autClientData};        
            this.generalSettings = autClientData;
            //Adding custom feilds and values
            this.generalSettings.gdprStatus = autClientData.gdpr.status.includes('ENABLED') ? 'ENABLED' : autClientData.gdpr.status;
            this.generalSettings.reqBehaviour = autClientData.gdpr.status.includes('ENABLED') ? autClientData.gdpr.status.slice(8, autClientData.gdpr.status.length) : '';
            this.generalSettings.usPrivacyStatus = autClientData.usPrivacy.status.includes('ENABLED') ? 'ENABLED' : autClientData.usPrivacy.status;
            this.generalSettings.usReqBehaviour = autClientData.usPrivacy.status.includes('ENABLED') ? autClientData.usPrivacy.status.slice(8, autClientData.usPrivacy.status.length) : '';
            this.generalSettings.logCookies = autClientData.log_cookies ? (autClientData.log_cookies.client ? ((autClientData.log_cookies.client.length == 1 && autClientData.log_cookies.client[0] == '*') ? 'ALL' : 'SPECIFIC') : 'ALL') : 'ALL';
            this.generalSettings.cookieList =  this.generalSettings.logCookies === 'SPECIFIC' ? autClientData.log_cookies.client.join(',') : []
            let generalFields = getAutGeneralFields(); 
            this.autGeneralFields = this.generalSettings? this.hideFields(generalFields, this.generalSettings): generalFields;
            this.deploymentStatusFields = getDeploymentStatusFields();
            this.snapshot = {...this.generalSettings};
        },
        setEditMode(mode) {           
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true)
        },
        onCancelClick() {
            this.setEditMode(false);
            this.generalSettings = Object.assign({}, this.snapshot);
            this.error = null
        },
        hideFields(fields, obj) {
            return fields.map((field) => {
                if(field.suppressIfField) {                                        
                    const val = field.suppressIfField.split('.').reduce((o, i) => o[i], obj);
                    if(field.suppressIfValueContains.indexOf(val) > -1)
                        field.visible = false;
                    else
                        field.visible = true;
                                            
                    return field;   
                    
                }
                return field;
            }); 
        },
       
        async onSaveClick() {
            try {

                let updatedGeneralSettings = { ...this.generalSettings };
                // Change custom variables to api compatible
                updatedGeneralSettings.log_cookies = {};
                updatedGeneralSettings.log_cookies.system = this.$store.state.aut.autClientData.log_cookies.system;
                updatedGeneralSettings.log_cookies.client = updatedGeneralSettings.logCookies ? ( updatedGeneralSettings.logCookies === 'ALL' ?  ['*'] : updatedGeneralSettings.cookieList.split(',')) : ['*'];
                updatedGeneralSettings.gdpr.status = (updatedGeneralSettings.gdprStatus === 'ENABLED') ? updatedGeneralSettings.gdprStatus + '_' + updatedGeneralSettings.reqBehaviour : updatedGeneralSettings.gdprStatus;
                updatedGeneralSettings.usPrivacy.status = (updatedGeneralSettings.usPrivacyStatus === 'ENABLED') ? updatedGeneralSettings.usPrivacyStatus + '_' + updatedGeneralSettings.usReqBehaviour : updatedGeneralSettings.usPrivacyStatus;
                delete updatedGeneralSettings.logCookies;
                delete updatedGeneralSettings.cookieList;
                delete updatedGeneralSettings.usPrivacyStatus;
                delete updatedGeneralSettings.usReqBehaviour;
                delete updatedGeneralSettings.gdprStatus;
                delete updatedGeneralSettings.reqBehaviour;

                const response = await this.updateAutClientData(updatedGeneralSettings);
                this.setEditMode(false);
                this.snapshot = {...this.generalSettings}
                this.successMessage = 'Client settings updated successfully.'
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
            }            
        },

        async onActivateClick() {
            this.showModal({ component: 'DiffViewModal' ,  data: {prev:this.prodClientData, current:this.$store.state.aut.autClientData, saveMethod:this.activateClient, cancelMethod: this.onCancelClick}}); 
        },

        async activateClient() {
            try {
                const response = await this.activateClientConfig();                
                this.actSuccessMessage = 'Activation successful';
                this.$store.dispatch("aut/fetchDeploymentStatus");
            } catch (error) {
                if (error.response.data.error)
                    this.actError = error.response.data.error;
                else this.actError = error.message;
            }
        }
    },
    created() {
        this.initializeData();
    }  


}
</script>

<style>
</style>
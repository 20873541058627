<template>

      <!-- NAVIGATION -->
    <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-dark scrollbar-container"  id="sidebar">
      <div class="container-fluid">
    
        <!-- Toggler -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
    
        <!-- Brand -->
        <router-link class="navbar-brand" to="/">
          <img src="../assets/logo.png" class="navbar-brand-img mx-auto" alt="aqfer logo">
        </router-link>
    
        <!-- User (xs) -->
        <div class="navbar-user d-md-none">
    
          <!-- Dropdown -->
          <div class="dropdown">
    
            <!-- Toggle -->
            <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="avatar avatar-sm avatar-online">
                <img :src="`https://ui-avatars.com/api/?name=${$store.state.currentUser.name}&background=random`" class="avatar-img rounded-circle" alt="...">
              </div>
            </a>
    
            <!-- Menu -->
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
              <!-- <a href="./profile-posts.html" class="dropdown-item">Profile</a>
              <a href="./account-general.html" class="dropdown-item">Settings</a>
              <hr class="dropdown-divider"> -->
              <router-link to="/logout" class="dropdown-item">Logout</router-link>
            </div>
    
          </div>
    
        </div>
    
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidebarCollapse">

          <!-- Client Drop Down -->
          <h6 class="navbar-heading mb-0"> CLIENT </h6>
          <div class="client-dropdown dropdown mb-2 d-grid gap-2">
            <button class="btn btn-secondary btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ $store.state.currentClient ? $store.state.currentClient.cid + ' - ' + $store.state.currentClient.name : 'Loading...' }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="">
              <a 
                v-for="client in $store.state.clients" 
                :key="client.cid" 
                :class="[$store.state.currentClient.cid == client.cid ? 'active pe-none':'', 'dropdown-item']" 
                @click="setCurrentClient(client)">
                  {{client.cid}} - {{client.name}}
                </a>
            </div>
          </div>

          <!-- Divider -->
          <hr class="navbar-divider my-3">

          <!-- Navigation -->
          <ul class="navbar-nav">
            <NavItem v-for="item in navItems" :key="item.index" :item="item"  /> 
          </ul>
    
          <!-- Divider -->
          <hr class="navbar-divider my-3">
    
          <!-- Heading -->
          <h6 class="navbar-heading">
            Documentation
          </h6>
    
          <!-- Navigation -->
          <ul class="navbar-nav mb-md-4">
            <li class="nav-item">
              <a class="nav-link" href="#sidebarBasics" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarBasics">
                <i class="fe fe-clipboard"></i> Basics
              </a>
              <div class="collapse " id="sidebarBasics">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <a href="./docs/getting-started.html" class="nav-link ">
                      Getting Started
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="./docs/design-file.html" class="nav-link ">
                      Design File
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#sidebarComponents" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarComponents">
                <i class="fe fe-book-open"></i> Components
              </a>
              <div class="collapse " id="sidebarComponents">
                <ul class="nav nav-sm flex-column">
                  <li>
                    <a href="./docs/components.html#alerts" class="nav-link">
                      Alerts
                    </a>
                  </li>
                  <li>
                    <a href="./docs/components.html#autosize" class="nav-link">
                      Autosize
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="./docs/changelog.html">
                <i class="fe fe-git-branch"></i> Changelog <span class="badge bg-primary ms-auto">v2.1.0</span>
              </a>
            </li>
          </ul>
    
          <!-- Push content down -->
          <div class="mt-auto"></div>
    
    
            <!-- User (md) -->
            <div class="navbar-user d-none d-md-flex" id="sidebarUser">
              <!-- Icon -->
              <a class="navbar-user-link" target="_blank" href="https://support.aqfer.net/portal/home" data-bs-toggle="tooltip" data-bs-placement="top" title="Support Page">
                <span class="icon">
                  <i class="fe fe-life-buoy"></i>
                </span>
              </a>
    
              <!-- Dropup -->
              <div class="dropup">
    
                <!-- Toggle -->
                <a href="#" id="sidebarIconCopy" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div class="avatar avatar-sm">
                    <img :src="`https://ui-avatars.com/api/?name=${$store.state.currentUser.name}&background=random`" class="avatar-img rounded-circle" alt="...">
                  </div>
                </a>
    
                <!-- Menu -->
                <div class="dropdown-menu" aria-labelledby="sidebarIconCopy">
                  <!-- <a href="./profile-posts.html" class="dropdown-item">Profile</a>
                  <a href="./account-general.html" class="dropdown-item">Settings</a>
                  <hr class="dropdown-divider"> -->
                  <span class="dropdown-item">{{ $store.state.currentUser.email }}</span>
                  <hr class="dropdown-divider"> 
                  <router-link to="/logout" class="dropdown-item">Logout</router-link>
                </div>
    
              </div>
    
              <!-- Icon -->
              <a class="navbar-user-link" target="_blank" href="mailto:support@aqfer.com?Subject=CX Portal Support" data-bs-toggle="tooltip" data-bs-placement="top" title="Contact Us">
                <span class="icon">
                  <i class="fe fe-mail"></i>
                </span>
              </a>
    
            </div>
    
        </div> <!-- / .navbar-collapse -->
    
      </div>
    </nav>
</template>

<script>
import NavItem from './NavItem.vue'
import {Tooltip} from 'bootstrap'

export default {
    name: 'Sidebar',
    components:{
        NavItem
    },
    props: {
        msg: String
    },
    computed: {
        navItems() {

        const clientFeatures = this.$store.state.currentClient.config.features;

        // Filter optional nav items based on current client config features array
        let filteredNavItems  = this.$store.state.navItems.filter((item) => {
            if(item.optional) {
                return clientFeatures.filter(function(feature){                 
                    return feature.name === item.featureId;
                }).length != 0
            } else {
                return true;
            }
        });       
        
        // Populate "apps" nav item based on current client features array objects with key uri     
        let appsArray = [];
        clientFeatures.forEach(obj => {
            if(obj.uri){                    
                let rObj = {}
                rObj["name"] = obj.name;
                rObj["route"] = obj.uri;
                appsArray.push(rObj);
            }
            
        });    
        
        if(appsArray.length) {          
            filteredNavItems = filteredNavItems.map(obj =>
                obj.name === "Apps" ? { ...obj, children: appsArray } : obj
            );
        } else {                 
            filteredNavItems = filteredNavItems.filter((el) => el.name != "Apps");         
        }

            

        // Show "Admin" nav item based on current user roles
        let adminRoles = ["aqfer_admin","client_admin"];
        let currentUserRoles = this.$store.state.currentUser.roles;

        const isAdmin = adminRoles.some(r=> currentUserRoles.includes(r));
        if(!isAdmin) {
            filteredNavItems = filteredNavItems.filter((el) => el.name!= "Admin");
        }      

        return filteredNavItems;   
        }
    },
    methods: {
        setCurrentClient(client){
        this.$store.dispatch('setCurrentClient',client)
        }
    },
    mounted(){
        //activate tooltips
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
        })
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="modal-card card">
        <form @submit.prevent="onEdgeHostName()">  
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add EdgeHostName
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="fields" v-model="domainList" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="fields" v-model="domainList" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/domainListFields"
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            domainList: {},
            fields: Fields
        }
    },
    props: {       
        modalData: {
            type: [Object, Array, String],
        }
    },
    beforeMount() {
        if(this.modalData) {
            this.fields =  this.fields.map((field) => {
                if(field.name == "edgeHostname") { 
                    field.visible = false;                                            
                    return field;                      
                }
                return field;
            }); 
        }
    },
    methods: {
        ...mapActions('aut', {
            updateEdgeHostname: 'updateEdgeHostname'
        }),
        async onEdgeHostName() {            
            let domainListObj = {};
            let domainArr = [];
            let ordinal = 0;
            if(this.modalData && this.modalData.domains && this.modalData.domains.items) {
                this.modalData.domains.items.map((domainObj) => {
                    if(ordinal < domainObj.ordinal)
                        ordinal = domainObj.ordinal;               
                });
                ordinal++;
            }
            
            if(this.domainList.domains && this.domainList.domains.length) {
                domainArr = this.domainList.domains.map((domainName) => {
                    let domainObj = {};
                    domainObj.name = domainName;
                    domainObj.ordinal = ordinal;
                    domainObj.status= "PENDING";
                    ordinal++;
                    return domainObj;
                });
            }

            if(this.modalData) {
                domainListObj.edgeHostname =  this.modalData.edgeHostname;
                domainListObj.domains = this.modalData.domains;
                domainListObj.domains.items = [...this.modalData.domains.items, ...domainArr];
            } else {
                domainListObj.edgeHostname = this.domainList.edgeHostname;
                domainListObj.domains = {"items": domainArr};
            }               
            this.onUpdateEdgeHostname(domainListObj); 
           
        },

        async onUpdateEdgeHostname(domainListObj) {
             try {
                await this.updateEdgeHostname(domainListObj)
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }

            this.$store.dispatch('hideModal')
            this.$store.dispatch("aut/getDomainLists");  

        }
    }
}
</script>

<style>

</style>
const Fields = [
    {
        name: "edgeHostname",
        label: "Edge Hostname",
        type: "text",
        editable: true,
        toolTip: "Name of edge-hostname to add",
        placeholder: "ex:a.emaple.com",
        validation: [
            { type: "required", errMsg: "Name is required" }
        ]
    },
    {
        name: "domains",
        label: "CNames",
        type: "array",
        toolTip: "Comma seperated list of Cnames to add",
        placeholder: "ex: aaa.example.com,bbb.example.com",
        editable: true,
        validation: [
            { type: "required" }
        ]
    }
];

export default Fields;
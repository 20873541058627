<template>
    <div class="modal-card card">
        <form @submit.prevent="onFormSubmit()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Delete Class</h4>
                <!-- Close -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="card-body">
                <p>Are you sure you want to delete the <b>{{clientClass.cls}}</b> class?</p>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">Delete</button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            error: "",
            clientClass: this.modalData,
        };
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "DeleteClass",
    methods: {
        ...mapActions("aut", {
            deleteClass: "deleteAutClientClass",
        }),
        async onFormSubmit() {
            
            try {
                await this.deleteClass(this.clientClass)

            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }

            //TBD: change active class

            this.$store.dispatch('hideModal')
            this.$store.dispatch('aut/fetchAutClientData')
        },
    },
};
</script>

<style>
</style>
//router
import { createRouter, createWebHistory } from "vue-router";
import store from '../store'
import axios from "axios";

//Main Sections
import Portal from '../views/Portal'
import Login from '../views/Login'
import Logout from '../views/Logout'
import Dashboard from '../views/Dashboard'

//Admin Section
import Users from '../views/admin/Users'
import User from '../views/admin/User'
import Clients from '../views/admin/Clients'
import Client from '../views/admin/Client'
import Reports from '../views/admin/reports/Reports'
import SignIns from "../views/admin/reports/SignIns.vue";
import AdminDashboards from '../views/admin/Dashboards'

//aUT
import AutAdmin from '../views/aut/admin/Admin'
import GeneralSettings from '../views/aut/admin/GeneralSettings'
import Classes from '../views/aut/admin/Classes'
import GenerateTag from '../views/aut/admin/GenerateTag'
import ConfigureHosts from '../views/aut/admin/ConfigureHosts'
import ConfigureCnames from '../views/aut/admin/ConfigureCNames'
import AutReports from '../views/aut/Reports'
import AutDashboards from '../views/aut/Dashboards'

//aMDP
import Queries from '../views/amdp/Queries'
import Credentials from '../views/amdp/Credentials'
import Manage from '../views/amdp/manage/Manage'
import Jobs from '../views/amdp/manage/Jobs'
import DataChannels from '../views/amdp/manage/DataChannels'
import Schemas from '../views/amdp/manage/Schemas'
import Mappings from '../views/amdp/manage/Mappings'

//aGM
import agmDashboard from '../views/agm/Dashboard'

//aIO
import aioDashboard from '../views/aio/Dashboard'

//Sandbox
import UnitTest from '../views/sandbox/UnitTest'
import UnitTestExecution from '../views/sandbox/UnitTestExecution'
import Management from '../views/sandbox/Management'
import SandboxDetail from '../views/sandbox/SandboxDetail'
import SandboxJobList from '../views/sandbox/SandboxJobList'
import SandboxJobDetail from '../views/sandbox/SandboxJobDetail'

import Configuration from '../views/sandbox/job-management/Configuration'
import YAMLEditor from '../views/sandbox/job-management/YamlEditor'
import RunHistory from '../views/sandbox/job-management/RunHistory'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '',
        component: Portal,
        children: [
            {
                path: '',
                name: 'Home',
                component: Dashboard
            },
            {
                path: '/admin/users',
                name: 'Users',
                component: Users
            },
            {
                path: '/admin/users/:id',
                name: 'User',
                component: User
            },
            {
                path: '/admin/clients',
                name: 'Clients',
                component: Clients
            },
            {
                path: '/admin/clients/:id',
                name: 'Client',
                component: Client
            },
            {
                path: '/admin/reports',
                name: 'Reports',
                component: Reports,
                children:[
                    {
                        path: '/admin/reports/sign-ins',
                        name: 'Sign Ins',
                        component: SignIns
                    },
                ]
            },
            {
                path: '/admin/dashboards',
                name: 'Dashboards',
                component: AdminDashboards
            },
            {
                path: '/aut/admin',
                name: 'Tag Admin',
                component: AutAdmin,
                children:[
                    {
                        path: '/aut/admin/general-settings',
                        name: 'General Settings',
                        component: GeneralSettings
                    },
                    {
                        path: '/aut/admin/classes',
                        name: 'Classes',
                        component: Classes
                    },
                    {
                        path: '/aut/admin/generate-tag',
                        name: 'Generate Tag',
                        component: GenerateTag
                    },
                    {
                        path: '/aut/admin/configure-hosts',
                        name: 'Configure Hosts',
                        component: ConfigureHosts

                    },
                    {
                        path: '/aut/admin/configure-cnames/:hostName',
                        name: 'Configure CNames',
                        component: ConfigureCnames

                    }
                ]
            },
            {
                path: '/aut/reports',
                name: 'Tag Reports',
                component: AutReports,
            },
            {
                path: '/aut/dashboards',
                name: 'Tag Dashboards',
                component: AutDashboards,
            },
            {
                path: '/amdp/queries',
                name: 'Named Queries',
                component: Queries,
            },
            {
                path: '/amdp/Credentials',
                name: 'Credentials',
                component: Credentials,
            },
            {
                path: '/amdp/manage',
                name: 'Manage',
                component: Manage,
                children: [
                    {
                        path: '/amdp/manage/jobs',
                        name: 'Jobs',
                        component: Jobs,
                    },
                    {
                        path: '/amdp/manage/data-channels',
                        name: 'Data Channels',
                        component: DataChannels,
                    },
                    {
                        path: '/amdp/manage/schemas',
                        name: 'Schemas',
                        component: Schemas,
                    },
                    {
                        path: '/amdp/manage/mappings',
                        name: 'Mappings',
                        component: Mappings,
                    },
                ]
            },
            {
                path: '/aio/dashboard',
                name: 'aIO Dashboard',
                component: aioDashboard,
            },
            {
                path: '/agm/dashboard',
                name: 'Graph Dashboard',
                component: agmDashboard,
            },
            {
                path: '/amdp/sandbox/unit-test',
                name: 'Unit Test',
                component: UnitTest,
            },
            {
                path: '/amdp/sandbox/unit-test/:id',
                name: 'Unit Test Execution',
                component: UnitTestExecution,
            },
            {
                path: '/amdp/sandbox/management',
                name: 'Management',
                component: Management,
            },
            {
                path: '/amdp/sandbox/:id',
                name: 'SandboxDetail',
                component: SandboxDetail,
            },{
                
                path: '/amdp/sandbox/Job-list',
                name: 'Job List',
                component: SandboxJobList
            },{
                
                path: '/amdp/sandbox/:sandboxId/jobs/:id',
                name: 'Job Detail',
                component: SandboxJobDetail,
                children: [
                    {
                        path: '/amdp/sandbox/:sandboxId/jobs/:id/configuration',
                        name: 'Configuration',
                        component: Configuration
                    },{
                        path: '/amdp/sandbox/:sandboxId/jobs/:id/yaml-editor',
                        name: 'YAML Editor',
                        component: YAMLEditor
        
                    },{
                        path: '/amdp/sandbox/:sandboxId/jobs/:id/run-history',
                        name: 'Run History',
                        component: RunHistory
        
                    }

                ]
            }
            
            
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), 
    routes
})

router.beforeEach((to, from, next) => { 
    store.dispatch('setLoading', false)
    const {$cookies} = router.app.config.globalProperties

    //Authentication Logic
    if (to.name !== 'Login' && to.name !== 'Logout' && store.state.currentUser == null) {
        store.dispatch('setLoading', true)

        //check for incoming access Token through tkn parameter and redirect
        if (to.name == "Home" && to.query.tkn) {
            $cookies.set('access-token', to.query.tkn)
            next({ name: 'Home' })
        }
        //resolve incoming access token
        //console.log($cookies.get('access-token'))
        const accessToken = $cookies.get('access-token');
        if (accessToken) {
            store.dispatch('setAccessToken', accessToken)
        } else if(!store.state.accessToken && store.state.environment != 'test') {
            //redirect to login page
            next({ name: 'Login' })
        }
        
        const authPromise = axios
            .get(store.state.authUrl + '/v1/who-am-i', {
                headers: {
                    'Authorization': `Bearer ${store.state.accessToken}`
                }
            })
            .then((res) => {
                store.dispatch('handleUserData', res.data)
            })
            .catch((error) => {
                console.error(error)
                next({ name: 'Login' })
            })
        
        const clientsPromise = axios
            .get(store.state.mgmtUrl + '/v1/staging/clients?detail=client', {
                headers: {
                    'Authorization': `Bearer ${store.state.accessToken}`
                }
            })
            .then((res) => {
                store.dispatch('handleClientsData', res.data.items)
                if(store.state.toastNotif == true){
                const options ={
                    message: "Successfully logged in as " + store.state.currentUser.email + ".",
                    type: "success",
                }
                store.dispatch('addToast', options)
                store.state.toastNotif = false;
            }
            })

        const docsPromise = axios
            .post(store.state.docsUrl + '/api/docs-auth', [], {
                headers: {
                    'Authorization': `Bearer ${store.state.accessToken}`
                }
            })
            .then((res) => {
                store.dispatch('handleDocsAuthData', res.data)
            })
            .catch((error) => {
                console.error(error)
            })
        
        Promise.all([authPromise, clientsPromise]).then(() => {
            store.dispatch('setLoading', false)
            next()
        });
        
    }

    else next()


})

export default router
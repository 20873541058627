<template>
  <div class="modal-card card">
    <div class="card-header">
      <!-- Title -->
      <h3 class="card-header-title">
        Update Config? (scroll till end to save/cancel)
      </h3>
      <!-- Close -->
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="card-body">
      <Diff
        :mode="mode"
        :theme="theme"
        :language="language"
        :prev="prev"
        :current="current"
        :folding="folding"
        :virtual-scroll="virtualScroll"
      />
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button @click="onSaveClick" class="btn btn-success">Save</button>&nbsp;
        <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-secondary">Cancel</button
        >&nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mode: "split",
      theme: "light",
      language: "json",
      folding: false,
      prev: JSON.stringify(this.modalData.prev, null, " "),
      current: JSON.stringify(this.modalData.current, null, " "),
      virtualScroll: {
        height: 500,
        lineMinHeight: 24,
        delay: 100,
      },
    };
  },

  props: {
    modalData: {
      type: [Object, Array, String],
    },
  },

  methods: {
    onSaveClick() {
      this.modalData.saveMethod();
      this.$store.dispatch("hideModal");
    },
    onCancelClick() {
      this.modalData.cancelMethod();
      this.$store.dispatch("hideModal");
    },
  },
};
</script>

<style lang="scss">
</style>
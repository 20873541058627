import { createStore } from 'vuex'
import router from '../router'
import axios from 'axios'
import admin from './modules/admin.store'
import amdp from './modules/amdp.store'
import aut from './modules/aut.store'
import sandboxUnitTest from './modules/sandbox-unit-test.store'
import sandbox from './modules/sandbox.store'
import VuexPersistence from 'vuex-persist'
import { useToast } from 'vue-toastification'
import { FAILSAFE_SCHEMA } from 'js-yaml'

const toast = useToast()

const vuexLocal = new VuexPersistence({
    //Specifiy the field(s) you want to persist on refesh. If empty, retains everything by default.
    reducer: (state) => ({currentClient : state.currentClient}, {toastNotif: state.toastNotif}),
    storage: window.localStorage
  })
  
const initialState = () => {

    var environment =
        (function (h) {
            if (h.includes('preprod')) {
                return "pre_prod";
            } else if (h.includes('localhost')) {
                return "test";
            } else if (h.includes('admin-dev')) {
                return "pre_prod";
            } else if (h.includes('dev')) {
                return "dev";
            } else {
                return (h.includes('staging')) ? "pre_prod" : "prod";
            }
        })(document.location.hostname);

    var authPortalUrl = null;
    var authUrl = null;
    var countsUrl = null;
    var dmsUrl = null;
    var docsUrl = null;
    var imdsUrl = null;
    var lakeviewUrl = null;
    var mgmtUrl = null;
    var pailUrl = null;
    var cookieDomain = null;

    switch (environment) {
        case 'dev':
            cookieDomain = 'api-dev.aqfer.net'
            authPortalUrl = 'https://auth.api-dev.aqfer.net'
            authUrl = 'https://auth.api-dev.aqfer.net'
            countsUrl = 'https://counts.api-dev.aqfer.net'
            docsUrl = 'https://docs-dev.aqfer.net'
            dmsUrl = 'https://dms.api-dev.aqfer.net'
            lakeviewUrl = 'https://lakeview.api-dev.aqfer.net'
            imdsUrl = 'https://imds.api-dev.aqfer.net'
            mgmtUrl = 'https://atm-config.api-dev.aqfer.net'
            pailUrl = 'https://pail.api-dev.aqfer.net'
            break;
        case 'test':
            cookieDomain = 'api-dev.aqfer.net'
            authPortalUrl = 'http://localhost:3000/auth'
            authUrl = 'http://localhost:3000/auth'
            countsUrl = 'http://localhost:3000/counts'
            docsUrl = 'http://localhost:3000/docs'
            dmsUrl = 'http://localhost:3000/dms'
            lakeviewUrl = 'http://localhost:3000/lakeview'
            imdsUrl = 'http://localhost:3000/imds'
            mgmtUrl = 'http://localhost:3000/mgmt'
            pailUrl = 'http://localhost:3000/pail'
            break;
        case 'pre_prod':
            cookieDomain = 'portal-staging.aqfer.net'
            authPortalUrl = 'https://auth.portal-staging.aqfer.net'
            authUrl = 'https://auth.api-preprod.aqfer.net'
            countsUrl = 'https://counts.api-preprod.aqfer.net'
            dmsUrl = 'https://dms.api-preprod.aqfer.net'
            docsUrl = 'https://docs-staging.aqfer.net'
            lakeviewUrl = 'https://lakeview.api-preprod.aqfer.net'
            imdsUrl = 'https://imds.api-preprod.aqfer.net'
            mgmtUrl = 'https://atm-config.api-preprod.aqfer.net'
            pailUrl = 'https://pail.api-preprod.aqfer.net'
            break;
        default:
            cookieDomain = 'portal.aqfer.net'
            authPortalUrl = 'https://auth.portal.aqfer.net'
            authUrl = 'https://auth.api.aqfer.net'
            countsUrl = 'https://counts.api.aqfer.net'
            dmsUrl = 'https://dms.api.aqfer.net'
            docsUrl = 'https://docs.aqfer.net'
            lakeviewUrl = 'https://lakeview.api.aqfer.net'
            imdsUrl = 'https://imds.api.aqfer.net'
            mgmtUrl = 'https://atm-config.api.aqfer.net'
            pailUrl = 'https://pail.api.aqfer.net'
            break;
    }


    return {
        environment: environment,
        authPortalUrl: authPortalUrl,
        authUrl: authUrl,
        countsUrl: countsUrl,
        dmsUrl: dmsUrl,
        docsUrl: docsUrl,
        lakeviewUrl: lakeviewUrl,
        imdsUrl: imdsUrl,
        mgmtUrl: mgmtUrl,
        pailUrl: pailUrl,
        currentUser: null,
        currentClient: null,
        clients: null,
        docsToken: null,
        loading: false,
        showModal: false,
        modalComponent: null,
        modalData: null,
        toastNotif: true,
        navItems: [
            {
                name: 'Home',
                icon: 'home',
                route: '/'
            }, {
                name: 'Admin',
                icon: 'unlock',
                children: [{
                    name: 'Users',
                    route: '/admin/users',
                }, {
                    name: 'Clients',
                    route: '/admin/clients',
                }, {
                    name: 'Reports',
                    children: [                        
                        {
                            name: 'Sign Ins',
                            route: '/admin/reports/sign-ins'
                        },
                    ]
                }, {
                    name: 'Dashboards',
                    route: '/admin/dashboards',
                }]
            }, {
                name: 'aUT',
                featureId: 'TAG_MANAGER',
                icon: 'tag',
                optional: true,
                children: [{
                    name: 'Admin',
                    children: [                        
                        {
                            name: 'General Settings',
                            route: '/aut/admin/general-settings'
                        },
                        {
                            name: 'Classes',
                            route: '/aut/admin/classes'    
                        },
                        {
                            name: "Generate Tag",
                            route: '/aut/admin/generate-tag'
                        },
                        {
                            name: "Configure Hosts",
                            route: '/aut/admin/configure-hosts'
                        }                         
                    ],
                }, {
                    name: 'Reports',
                    route: '/aut/reports',
                }, {
                    name: 'Dashboard',
                    route: '/aut/dashboards'
                }]
            }, {
                name: 'aMDP',
                featureId: 'DATA_LAKE',
                icon: 'database',
                optional: true,
                children: [{
                    name: 'Named Queries',
                    route: '/amdp/queries',
                }, {
                    name: 'Credentials',
                    route: '/amdp/credentials',
                }, {
                    name: 'Manage',
                    children: [
                        {
                            name: 'Jobs',
                            route: '/amdp/manage/jobs',
                        },
                        {
                            name: 'Data Channels',
                            route: '/amdp/manage/data-channels',
                        },
                        {
                            name: 'Schemas',
                            route: '/amdp/manage/schemas',
                        },
                        {
                            name: 'Mappings',
                            route: '/amdp/manage/mappings',
                        }
                    ]
                },{
                    name: 'Sandbox',
                    icon: 'box',
                    children: [{
                        name: 'Unit Test',
                        route: '/amdp/sandbox/unit-test',
                    },{
                        name: 'Management',
                        route:'/amdp/sandbox/management'
                    },{
                        name: 'Job List',
                        route:'/amdp/sandbox/job-list'
                    }]
                },]
            }, {
                name: 'aGM',
                icon: 'share-2',
                optional: true,
                children: [{
                    name: 'Dashboard',
                    route: '/agm/dashboard',
                }]
            }, {
                name: 'aIO',
                icon: 'repeat',
                optional: true,
                children: [{
                    name: 'Dashboard',
                    route: '/aio/dashboard',
                }]
            },  {
                name: 'Apps',
                icon: 'grid',
                children: [{
                    name: 'Demo 1',
                    route: '/apps/demo1',
                }, {
                    name: 'Demo 2',
                    route: '/apps/demo2',
                }]
            }

        ]
    }
};

const store = createStore({
    state: initialState(),
    mutations: {
        setInitialState(state) {
            state = initialState()
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setShowModal(state, showModal) {
            state.showModal = showModal
        },
        setModalComponent(state, modalComponent) {
            state.modalComponent = modalComponent
        },
        setModalData(state, modalData) {
            state.modalData = modalData
        },
        setModalValue(state, modalValue) {
            state.modalValue = modalValue;
        },
        
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken
        },
        setCurrentUser(state, currentUser) {
            state.currentUser = currentUser
        },
        setCurrentClient(state, currentClient) {
            state.currentClient = currentClient
        },
        setClients(state, clients) {
            state.clients = clients
        },
        setDocsToken(state, docsToken) {
            state.docsToken = docsToken
        },
    },
    actions: {
        setInitialState({ commit }) {
            commit('setInitialState')
        },
        setLoading({ commit }, loading) {
            commit('setLoading', loading)
        },
        showModal({ commit }, {component, data, value}) {
            commit('setModalComponent', component)
            commit('setModalData', data)
            commit('setModalValue', value )
            commit('setShowModal', true)
        },
        hideModal({ commit }) {
            commit('setShowModal', false)
        },
        setModalComponent({ commit }, modalComponent) {
            commit('setModalComponent', modalComponent)
        },
        setModalData({ commit }, modalData) {
            commit('setModalData', modalData)
        },
        setModalValue({ commit }, modalValue) {            
            commit('setModalValue', modalValue)
        },
        setAccessToken({ commit }, accessToken) {
            commit('setAccessToken', accessToken)
        },
        setCurrentClient({ commit }, client) {
            commit('setCurrentClient', client)
        },
        addToast({commit}, options) {
            if(options.type == "error") {
                toast.error(options.message);
            } 
            else if(options.type == "info") {
                toast.info(options.message);
            } 
            else if(options.type == "warning") {
                toast.warning(options.message);
            } 
            else if(options.type == "success"){
                toast.success(options.message);
            }
            else {
                toast(options.message);
            }
        },
        login({ state }) {
            state.toastNotif = true;
            const failureUrl = encodeURIComponent(window.location.href)
            const successUrl = encodeURIComponent(window.location.origin)
            const gConfigUrl = `${state.authUrl}/v1/config?success_redirect_url=${successUrl}&failure_redirect_url=${failureUrl}`

            axios(gConfigUrl).then((response) => {
                //console.log(response)
                const gConfig = response.data.result
                const returnPath = `${state.authPortalUrl}/v1/openid-connect`
                const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${gConfig.clientId}&response_type=code&scope=openid%20email&redirect_uri=${returnPath}&login_hint=sub&state=${gConfig.state}`
                //console.log(googleUrl)
                location.replace(googleUrl);
            });
        },
        handleUserData({ commit }, userData) {
            if (userData.email) {
                commit('setCurrentUser', userData)
            }
        },
        handleClientsData({ commit }, clientsData) {
            if (clientsData) {
                commit('setClients', clientsData)
                commit('setCurrentClient', clientsData[0])
            }
        },
        handleDocsAuthData({ commit }, docsAuthData) {
            if (docsAuthData.docsToken) {
                commit('setDocsToken', docsAuthData.docsToken)
            }
        },
        logout({ state, commit }) {
            commit('setInitialState')
            axios.post(state.authUrl + '/v1/logout', null, {
                headers: {
                    'Authorization': `Bearer ${state.accessToken}`
                }
            })
            .finally((res) => {
                const {$cookies} = router.app.config.globalProperties
                $cookies.set('access-token', '')
                router.push('/login')
            })
        }
    },
    getters: {
        userRoles() {
            const rolesRank = {
                aqfer_admin: 1,
                aqfer_user: 2,
                client_admin: 3,
                client_user: 4,
            };
        
            let currentUserRoles = store.state.currentUser.roles || ["client_user"];    
        
            const isAqferAdmin = currentUserRoles.includes("aqfer_admin")
                                ? true
                                : false;
        
            const getRoleRank = (roles) => {
                let rank = 5;
                roles.forEach(function (role) {
                    if (rolesRank[role] < rank) {
                        rank = rolesRank[role];
                    }
                });
                return rank;
            };
        
            const userRoleRank = getRoleRank(currentUserRoles);
        
            return { isAqferAdmin, getRoleRank, userRoleRank };
        }
    },
    modules: {
        admin,
        amdp,
        aut,
        sandboxUnitTest,
        sandbox

    },
    plugins: [vuexLocal.plugin]
})

export default store
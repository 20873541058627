function getMapFields() { 
return[
    {
       
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        visible: true,
    },  
    {
       
        name: "csv",
        label: "CSV",
        type: "object",
        inputType: "file",
        editable: true,
        visible: true,
    },    
    {
       
        name: "defaultType",
        label: "If No Match",
        type: "select",
        editable: true,
        visible: true,
        options:[
            { label: "Don't map, use direct", value: "DIRECT_VALUE" },
            { label: "Use default value", value: "SPECIFIC_DEFAULT_VALUE" }
        ]    

    },  
    {
       
        name: "default",
        label: "Default Value",
        type: "text",
        editable: true,
        visible: true,
        suppressIfField: 'defaultType', suppressIfValueContains: ["DIRECT_VALUE"]
    }


]
}    
export default getMapFields;
<template>
  <nav-header
    v-if="job.name"
    :navItems="[
      {
        name: 'Configuration',
        link: {
          name: 'Configuration',
          params: { sandboxId: `${selectedSandbox}`, id: `${job.name}` },
        },
      },
      {
        name: 'YAML Editor',
        link: {
          name: 'YAML Editor',
          params: { sandboxId: `${selectedSandbox}`, id: `${job.name}` },
        },
      },
      {
        name: 'Run History',
        link: {
          name: 'Run History',
          params: { sandboxId: `${selectedSandbox}`, id: `${job.name}` },
        },
      },
    ]"
    pretitle="Job"
    :title="job.name"
  >
    <template v-slot:cards-content>
      <div class="row align-items-center mt-4">
        <div class="col-3">
          <div class="card mb-0">
            <div class="card-body p-2">
              <div class="align-items-center g-0 row">
                <div class="col">
                  <h6 class="text-uppercase text-muted mb-0">Job Type</h6>
                  <span class="h4 mb-0">{{ job.kind }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card mb-0">
            <div class="card-body p-2">
              <div class="align-items-center row">
                <div class="col">
                  <h6 class="text-uppercase text-muted mb-0">Job Status</h6>
                  <span class="h4 mb-0">Pending</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Button -->
        <div class="col-auto ms-auto">
          <button
            @click="btnClick"
            class="btn btn-primary"
            :disabled="isButtonDisabled"
          >
            <div
              v-if="runClicked"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <div v-else><slot></slot> Run Job</div>
          </button>
        </div>
      </div>
    </template>
  </nav-header>
  <div class="container-fluid" v-if="job.name">
    <router-view
      :job="job"
      :runJobTrigger="runJobTrigger"
      v-on:setRunClick="setRunClick"
      v-on:onLintFailure="setRunJobBtnState"
    ></router-view>
  </div>
</template>

<script>
import NavHeader from "../../components/NavHeader.vue";
import { mapActions } from "vuex";
export default {
  name: "SandboxJobDetail",
  components: { NavHeader },
  data() {
    return {
      runJobTrigger: false,
      job: {},
      selectedSandbox: this.$store.state.sandbox.currentSandbox,
      snapshot: null,
      error: "",
      successMessage: "",
      runClicked: false,
      isButtonDisabled: false,
    };
  },
  beforeMount() {
    this.$store.dispatch(
      "sandbox/setCurrentSandbox",
      this.$route.params.sandboxId
    );
    this.fetchSandboxJob();
  },
  mounted() {
    this.$router.push({
      name: "Configuration",
      params: {
        sandboxId: `${this.selectedSandbox}`,
        id: `${this.$route.params.id}`,
      },
    });
  },
  methods: {
    ...mapActions({
      readSandboxJob: "sandbox/readSandboxJob",
    }),
    async fetchSandboxJob() {
      let response;
      try {
        response = await this.readSandboxJob(this.$route.params.id);
      } catch (error) {
        if (error.response.data.error) this.error = error.response.data.error;
        else this.error = error.message;
        return;
      }

      this.job = await response.data;
    },
    btnClick() {
      this.runClicked = true;
      this.runJobTrigger = !this.runJobTrigger;
    },
    setRunJobBtnState(btnState) {
      this.isButtonDisabled = btnState;
    },
    setRunClick(runClick) {
      this.runClicked = runClick;
    },
  },
};
</script>

<style scoped>
</style>
<template>
    <basic-header
        pretitle="aqfer Marketing Data Platform"
        title="Credentials"
    />
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <div class="card">
                    <datatable
                        tableId="credentials"
                        :columns="columns"
                        :data="this.$store.state.amdp.credentials"
                        :selectedItem="
                            this.$store.state.amdp.currentCredential
                        "
                        :lengthChange="false"
                        :info="false"
                        v-on:selected="handleSelect"
                    />
                </div>
            </div>
            <div class="col-md-9" v-if="$store.state.amdp.currentCredential">
                <input-field
                    label="Credential Name"
                    type="text"
                    v-model="$store.state.amdp.currentCredential.id"
                />
                <input-field
                    label="Credential Type"
                    type="select"
                    v-model="$store.state.amdp.currentCredential.type"
                    :options="[
                        { label: 'AWS', value: 'aws' },
                        { label: 'aqfer', value: 'aqfer' },
                        { label: 'Trade Desk', value: 'trade_desk' },
                    ]"
                />
                <div
                    v-if="
                        $store.state.amdp.currentCredential.type == 'aqfer'
                    "
                >
                    <input-field
                        label="Refresh Token"
                        type="text"
                        v-model="
                            $store.state.amdp.currentCredential.refresh_token
                        "
                    />
                </div>
                <div
                    v-if="
                        $store.state.amdp.currentCredential.type ==
                        'trade_desk'
                    "
                >
                    <input-field
                        label="Username"
                        type="text"
                        v-model="
                            $store.state.amdp.currentCredential.user_name
                        "
                    />
                    <input-field
                        label="Password"
                        type="text"
                        v-model="
                            $store.state.amdp.currentCredential.password
                        "
                    />
                </div>
                <div
                    v-if="$store.state.amdp.currentCredential.type == 'aws'"
                >
                    <input-field
                        label="Access Key"
                        type="text"
                        v-model="
                            $store.state.amdp.currentCredential.access_key
                        "
                    />
                    <input-field
                        label="Secret Key"
                        type="text"
                        v-model="
                            $store.state.amdp.currentCredential.secret_key
                        "
                    />
                    <input-field
                        label="Role ARN"
                        type="text"
                        v-model="
                            $store.state.amdp.currentCredential.role_arn
                        "
                    />
                    <input-field
                        label="Region"
                        type="text"
                        v-model="$store.state.amdp.currentCredential.region"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import InputField from "../../components/InputField.vue";
export default {
    components: { Datatable, InputField, BasicHeader },
    name: "Credentials",
    data() {
        return {
            columns: [
                {
                    data: "name",
                    title: "Credential Name",
                },
            ],
        };
    },
    methods: {
        handleSelect(data) {
            this.$store.dispatch("amdp/setCurrentCredential", data);
        },
    },
    beforeMount() {
        this.$store.dispatch("amdp/fetchCredentials");
    },
};
</script>

<style>
</style>
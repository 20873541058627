<template>
    <table  id="tableId" class="table table-hover compact">
        <thead>
            <tr>                
                <template v-for="(column,index) in columns" :key="index">
                    <th v-if="column.rowspan" :rowspan="column.rowspan" colspan="1" style="height:0px">
                        {{ column.title }}
                    </th>
                    <th v-if="column.colspan" rowspan="1" :colspan="column.colspan" style="height:0px">
                        {{ column.title }}
                    </th>
                </template>
            </tr>
            <tr>
                <template v-for="(column, index) in columns" :key="index">                    
                    <th v-if="!column.rowspan && !column.colspan" rowspan="1" colspan="1" style="height:0px">
                        {{ column.data }}
                    </th>                    
                </template> 
            </tr>
        </thead>
        <tbody>
            <tr v-for="(rowData,index) in data" :key="index">
                <template v-for="(column, index) in columns" :key="index">                    
                    <td v-if="!column.colspan" >
                        {{ rowData[column.data] }}
                    </td>                    
                </template>               
            </tr>            
        </tbody>
    </table>  
</template>

<script>
import DataTable from "datatables.net";
import "datatables.net-bs5";
import "datatables.net-select-bs5";
import "datatables.net-buttons-bs5";

export default {
    props: {
        data: Array,
        columns: Array,       
        tableId: {
            type: String,
            default: "datatable",
        },

    },
    data() {
        return {}
    },
    methods: {
        initTable() {
            console.log("init table");
            const self = this;
            this.table = new DataTable("#" + this.tableId, {
                "scrollX": true
                });
        }
    },
    beforeUnmount() {
        this.table.destroy();
    },
    mounted() {
        this.initTable();
    }
    

}
</script>

<style>

@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";
@import "~datatables.net-select-bs5/css/select.bootstrap5.min.css";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
    background-color: #2c7be5;
}
th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
 
th:first-child {
  border-left: 1px solid #dddddd;
}

</style>
<template>
    <div class="row">
        <div class="col-md-3">
            <div class="card">
                <datatable
                    tableId="schemas"
                    :columns="columns"
                    :data="this.$store.state.amdp.schemas"
                    :selectedItem="this.$store.state.amdp.currentSchema"
                    :lengthChange="false"
                    :info="false"
                    v-on:selected="handleSelect"
                />
            </div>
        </div>
        <div class="col-md-9" v-if="$store.state.amdp.currentSchema">
            <input-field label="Schema Name" v-model="$store.state.amdp.currentSchema.id" />
            <codemirror-editor v-model="$store.state.amdp.currentSchema"></codemirror-editor>
        </div>
    </div>
</template>

<script>
import CodemirrorEditor from '../../../components/CodemirrorEditor.vue';
import Datatable from '../../../components/Datatable.vue';
import InputField from '../../../components/InputField.vue';
export default {
    components: { Datatable, InputField, CodemirrorEditor },
    name: 'Schemas',
    data() {
        return {
            columns: [{ data: "name" }],
        };
    },
    methods: {
        handleSelect(data) {
            this.$store.dispatch("amdp/setCurrentSchema", data);
        },
    },
};
</script>

<style>
</style>
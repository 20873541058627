function getJobFields() { 
    return [
        {
            key: true,
            name: "name",
            label: "Name",
            type: "text",
            editable: true,
            validation: [
                { type: "required", errMsg: "Name field is required" }
            ]
        },
        {
            name: "kind",
            label: "Kind",
            type: "select",
            editable: true,
            options:[
                { label: "Connector", value: "CONNECTOR" },
                { label: "Mapper", value: "MAPPER" },
                { label: "Collator", value: "COLLATOR" },
                { label: "Cmapper", value: "CMAPPER" },
                { label: "Transformer", value: "TRANSFORMER" },
                { label: "Custom", value: "CUSTOM" },
                { label: "Misc", value: "MISC" }
            ]   
        },
        {
            name: "config_files",
            label: "Config Files",
            type: "object",
            editable: true
        },        
        {
            name: "created_at",
            label: "Created At",
            type: "text",
            editable: false
        }, {
            name: "created_by",
            label: "Created By",
            type: "text",
            editable: false
        }, {
            name: "updated_at",
            label: "Updated At",
            type: "text",
            editable: false
        }

    ];
}

export default getJobFields;


<template>
	<div class="row align-items-end card-title">
		<div class="col">
			<h4 class="header-title">Edge Hostnames</h4>
		</div>

		<div class="col-auto">
			<button
				@click="showModal({ component: 'addEdgeHost' })"
				class="btn btn-primary lift"
			>
				Add Entry
			</button>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<datatable
					:columns="columns"
					:data="$store.state.aut.domainList"
					:compact="true"
					:detailColumn="{
						baseUrl: 'configure-cnames/',
						identifier: 'edgeHostname',
					}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import Datatable from "../../../components/Datatable.vue";
	import { mapActions } from "vuex";
	export default {
		components: { Datatable },
		data() {
			return {
				columns: [
					{ data: "edgeHostname" },
					{
						data: "domains",
						render: function (data, type, row) {
							if (!data) return "";
							return Array.isArray(data.items)
								? data.items.length
								: 0;
						},
					},
				],
			};
		},
		beforeMount() {
			this.$store.dispatch("aut/getDomainLists");
		},
		methods: {
			...mapActions({
				showModal: "showModal",
			}),
		},
		props: {
			cid: String,
			clientData: Object,
		},
	};
</script>

<style></style>

<template>
    <div class="modal-card card">
        <form @submit.prevent="onCreateSandboxJob()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Create Job
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="jobFields" v-model="job" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="jobFields" v-model="job" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import getSandboxJobFields from "../../utils/field-constants/sandboxJobFields"
export default {
    components: { formFields, submitButton },
    data(){        
        return {
            error: "",
            job: {},            
            jobFields: []
        }
    },
    name: "AddSandboxJob",  
    created(){        
        this.jobFields = getSandboxJobFields();        
    },  
    methods: {
        ...mapActions("sandbox", {
            createSandboxJob: "createSandboxJob"
        }),
        async onCreateSandboxJob() {
            this.job.config_files = (typeof this.job.config_files === 'string')?JSON.parse(this.job.config_files):this.job.config_files;
            try {
                await this.createSandboxJob(this.job)
            } catch (error) {                
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }

           this.$store.dispatch('hideModal')
           this.$store.dispatch('sandbox/fetchSandboxes')
        }
    }
};
</script>

<style>
</style>
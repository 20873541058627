<template>
    <unit-test-job :jobType="jobType" :activeTab="activeTab" :advancedProps="advancedProps" :iframeSrc="iframeSrc" v-model="yamlContent"></unit-test-job>
</template>

<script>
import UnitTestJob from './UnitTestJob.vue';

export default {
    components: { UnitTestJob },
    data() { 
        return {
            jobType: "Connector",
            activeTab: this.$store.state.sandboxUnitTest.connectorActiveTab,
            yamlContent: this.$store.state.sandboxUnitTest.connectorYamlList,
            advancedProps: this.$store.state.sandboxUnitTest.connectorAdvancedProps,
            iframeSrc: `${this.$store.state.docsUrl}/docs/amdp/services/data-integration-service/connector/examples.html`    
        };
    },    
    watch: {        
        'yamlContent.config': {
            handler (newVal) {
                this.$store.dispatch("sandboxUnitTest/setConnectorConfigYaml", newVal);
            },
        }
    },
};
</script>

<style scoped>
</style>
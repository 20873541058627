export default {

    process: (content, template, replacements) => {
        Object.keys(replacements).forEach(key => {
            const pattern = new RegExp(template.replace('xx', key), 'g');
            content = content.replace(pattern, replacements[key]); 
        });
        return content;
    }

}
const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Client name is required" }
        ]
    },
    {
        name: "cid",
        label: "Client ID",
        type: "text",
        editable: true,
        validation: [
            { type: "required" }
        ]
    }, {
        name: "label",
        label: "Label",
        type: "text",
        editable: true,
        validation: [
            { type: "required" }
        ]
    }, {
        name: "cookieName",
        label: "Cookie Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required" }
        ],
    }, {
        name: "cookieFormat",
        label: "Cookie Format",
        type: "radio",
        editable: true,
        visible: true,
        options: [
            { label: "aqfer", value: "aqfer" },
            { label: "aqfer24", value: "aqfer24" },
            { label: "apache_moduid2", value: "apache_moduid2" },
            { label: "compact", value: "compact" },
        ]
    }, {
        name: "cookieDomain",
        label: "Cookie Domain",
        type: "text",
        editable: true,
    }, {
        name: "akamaiProperty",
        label: "Akamai Property",
        type: "text",
        editable: true,
    }, {
        name: "httpOnlyCookie",
        label: "HTTP-Only Cookie",
        type: "checkbox",
        // options: [
        //     { label: "Enabled", value: true },
        // ],
        editable: true,
    }, {
        name: "pubDenyIds",
        label: "Deny Publisher IDs",
        type: "array",
        editable: true,
    }, {
        name: "pubDenyDomains",
        label: "Deny Publisher Domains",
        type: "array",
        editable: true,
    }, {
        name: "notificationEmails",
        label: "Notification Email(s)",
        type: "array",
        editable: true,
    }, {
        name: "dailyCountEmails",
        label: "Daily Count Email(s)",
        type: "array",
        editable: true,
    }, {
        name: "dailyCountLimit",
        label: "Daily Count Limit",
        type: "number",
        editable: true,
    },
];

export default Fields;

const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Client name is required" }
        ]
    }, {
        name: "enabled",
        label: "Is Enabled",
        type: "checkbox",
        options: [
            { label: "Enabled", value: true },
        ],
        editable: true,
    }, {
        name: "cid",
        label: "Client ID",
        type: "text",
        editable: false,
        validation: [
            { type: "required" }
        ]
    }, {
        name: "label",
        label: "Label",
        type: "text",
        editable: false,
        validation: [
            { type: "required" }
        ]
    }, {
        name: "akamaiProperty",
        label: "Akamai Property",
        type: "text",
        editable: true,
    }, {
        name: "cookieName",
        label: "Cookie Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required" }
        ],
    }, {
        name: "cookieDomain",
        label: "Cookie Domain",
        type: "text",
        editable: true,
    }, {
        name: "cookieFormat",
        label: "Cookie Format",
        type: "radio",
        editable: true,
        visible: true,
        options: [
            { label: "aqfer", value: "aqfer" },
            { label: "aqfer24", value: "aqfer24" },
            { label: "apache_moduid2", value: "apache_moduid2" },
            { label: "compact", value: "compact" },
        ]
    }, {
        name: "cookieSameSite",
        label: "Cookie SameSite",
        type: "radio",
        editable: true,
        visible: true,
        options: [
            { label: "None", value: "None" },
            { label: "Strict", value: "Strict" },
            { label: "Lax", value: "Lax" },
        ]
    }, {
        name: "cookieSecure",
        label: "Cookie Secure",
        type: "checkbox",
        options: [
            { label: "Enabled", value: true },
        ],
        editable: true,
    },  {
        name: "cookieUrlsafe",
        label: "URL-Safe Cookie",
        type: "checkbox",
        options: [
            { label: "Enabled", value: true },
        ],
        editable: true,
    }, {
        name: "optoutCookie",
        label: "Opt-Out Cookie",
        type: "object",
        inputType: "json",
        editable: true,
    }, {
        name: "httpOnlyCookie",
        label: "HTTP-Only Cookie",
        type: "checkbox",
        options: [
            { label: "Enabled", value: true },
        ],
        editable: true,
    }, {
        name: "pubDenyIds",
        label: "Deny Publisher IDs",
        type: "array",
        editable: true,
    }, {
        name: "pubDenyDomains",
        label: "Deny Publisher Domains",
        type: "array",
        editable: true,
    }, {
        name: "shouldSendImage",
        label: "Should Send Image",
        type: "checkbox",
        options: [
            { label: "Enabled", value: true },
        ],
        editable: true,
    }, {
        name: "notificationEmails",
        label: "Notification Email(s)",
        type: "array",
        editable: true,
    }, {
        name: "dailyCountEmails",
        label: "Daily Count Email(s)",
        type: "array",
        editable: true,
    }, {
        name: "dailyCountLimit",
        label: "Daily Count Limit",
        type: "number",
        editable: true,
    }, {
        name: "gdpr",
        label: "GDPR",
        type: "object",
        inputType: "json",
        editable: true,
    }, {
        name: "customHeaders",
        label: "Custom Headers",
        type: "object",
        inputType: "json",
        editable: true,
    }, {
        name: "config",
        label: "Features",
        type: "object",
        inputType: "json",
        editable: true,
    }, 
];

export default Fields;


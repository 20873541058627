<template>
  <Sidebar />
  <MainContentArea />
  <Modal />
</template>

<script>
import Sidebar from '../components/Sidebar.vue'
import MainContentArea from '../components/MainContentArea.vue'
import Modal from '../components/Modal.vue'

export default {
  name: 'Portal',
  components: {
    Sidebar,
    MainContentArea,
    Modal
  },
  beforeMount(){
    
  }
}
</script>

<style>
</style>
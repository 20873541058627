<template>
    <div class="row align-items-end card-title">
		<div class="col">
            <h6 class="header-pretitle">CName Entrees</h6>
			<h4 class="header-title">{{$route.params.hostName}}</h4>
		</div>

		<div class="col-auto">
			<button
				@click="addCname"
				class="btn btn-primary lift"
			>
				Add Entry
			</button>
		</div>
        <div class="col-auto">
			<button
				@click="$router.push({name: 'Configure Hosts'})"
				class="btn btn-secondary lift"
			>
				close
			</button>
		</div>
	</div>
    <div class="row">
		<div class="col-md-12">
            <div class="card">
                <datatable
                    :columns="columns"
                    :data="cNames"
                    :compact="true"
                />
            </div>
        </div>
    </div>

  
</template>

<script>
import Datatable from "../../../components/Datatable.vue";
import { mapActions, mapState } from "vuex";
export default {
    components: { Datatable },
    data() {
        return {
            columns: [
                { data: "name", title: "CName" },
                {data: "status"}
            ]
        };
    },
    computed: {
     ...mapState({    
            domainList: state => state.aut.domainList
        }) ,
        cNames() {
            const filteredDomain =  this.$store.state.aut.domainList.filter(domainObj => domainObj.edgeHostname == this.$route.params.hostName);
            return (filteredDomain[0].domains && filteredDomain[0].domains.items )?filteredDomain[0].domains.items:[];
        },
        domainListObj() {
            const filteredDomain = this.$store.state.aut.domainList.filter(domainObj => domainObj.edgeHostname == this.$route.params.hostName)
            return filteredDomain[0]?filteredDomain[0]:{};
        }    
    },
     methods: {
        ...mapActions({
            showModal: "showModal",
        }),
        addCname() {
            this.showModal({ component: 'addEdgeHost', data: this.domainListObj });
        }
    },
    props: {
        cid: String,
        clientData: Object
    }
   

}
</script>

<style>

</style>
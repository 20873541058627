<template>
  <basic-header pretitle="aqfer Marketing Data Platform" title="Named Queries" />
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
export default {
  components: { BasicHeader },

}
</script>

<style>

</style>
<template>         
    <ul class="list-group">
        <a v-for="(listItem, index) in list" :key="listItem.index" class="list-group-item d-flex align-items-center " :class="[activeListGroupItem == `${listItem[listItemKey]}` ? 'active' : '']" @click.self="selectBtnclick(listItem)">
            <span class="mr-auto p-2">{{listItem[listItemKey] || listItem}}</span>
            <div v-if="editMode && orderList" class="p-4">
                <span class="btn-link"  @click ="reOrderList(listItem, index, index+1)">
                    <span class="icon">
                        <i class="fe fe-arrow-down"></i>
                    </span>
                </span>
                <span class="btn-link"  @click ="reOrderList(listItem, index, index-1)">
                    <span class="icon">
                        <i class="fe fe-arrow-up"></i>
                    </span>
                </span>
            </div>
           <span v-if="editMode" @click="deleteBtnClick(listItem)" class="btn p-2"> <span class="icon"> <i class="fe fe-trash-2"></i></span></span>
        </a>        
    </ul>
</template>
<script>
export default {   
    computed: {
        activeListGroupItem() {
            return this.activeListItem;
        }
    },
    props: {
        list: [Array, Object],     
        editMode: Boolean,
        title: String,
        activeListItem: String,
        listItemKey: {
            type: String,
            default: null
        },
        orderList: {
            type: Boolean,
            default: false
        }
        
    },
    methods:{
        deleteBtnClick(listItem) {
            this.$emit('deleteBtnClick', listItem)
        },
        selectBtnclick(listItem) {
            this.activeListGroupItem = listItem[this.listItemKey];
            this.$emit('selectBtnclick', listItem)
        },
        reOrderList(listItem, currentIndex, newIndex) {
            this.activeListGroupItem = listItem[this.listItemKey];
            this.$emit('reOrderList', listItem, currentIndex, newIndex);
        }

    },
    emits: [
      'deleteBtnClick',
      'selectBtnclick',
      'reOrderList'

    ]
}
</script>

<style scoped>
    .list-group-item {
        height: 3rem;
        background-color: #EDF2F9;
        padding: 0.5rem 0.75rem;
        border: 1px solid #D2DDEC !important;
    }
    .list-group-item.active {
        z-index: 2;
        color: #283E59;
        background-color: #FFFFFF;
    }
    .list-group {
        padding-bottom: 0.5rem;
    }
    .mr-auto {
        margin-right: auto!important;
    }
    .list-group i.fe:hover {
        display: inline-block;
        border-radius: 50%;
        box-shadow: 0 0 2px #888;
        background-color: #FFFFFF;
        cursor: pointer;
    }

    .list-group-item:first-child .fe-arrow-up{
        display: none;
    }
    .list-group-item:last-child .fe-arrow-down{
        display: none;
    }

</style>>


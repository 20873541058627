<template>
    <div v-if="$store.state.amdp.currentMapping">
    <input-field
        type="select"
        :options="mappingOptions"
        label="Mapping Name"
        v-model="$store.state.amdp.currentMapping.id"
        @change="updateCurrentMapping"
    />
    <div class="row">
        <div class="col-md-3">
            <h3 class="border-bottom mb-3 pb-3">Input Schema</h3>
            <codemirror-editor
                editorId="inputSchema"
                :readOnly="true"
                theme="material"
                v-model="inputSchema"
            />
        </div>
        <div class="col-md-6">
            <h3 class="border-bottom mb-3 pb-3 text-center">Mapping</h3>
            <codemirror-editor
                editorId="mapping"
                v-model="this.$store.state.amdp.currentMapping"
            />
        </div>
        <div class="col-md-3">
            <h3 class="border-bottom mb-3 pb-3 text-end">Output Schema</h3>
            <codemirror-editor
                editorId="outputSchema"
                :readOnly="true"
                theme="material"
                v-model="outputSchema"
            />
        </div>
    </div>
    </div>
</template>

<script>
import CodemirrorEditor from "../../../components/CodemirrorEditor.vue";
import InputField from "../../../components/InputField.vue";
export default {
    name: "Mappigs",
    components: { InputField, CodemirrorEditor },
    computed: {
        mappingOptions: {
            get() {
                const mappings = this.$store.state.amdp.mappings;
                const mappingOptions = mappings.map(function (x) {
                    return { label: x.name, value: x.id };
                });
                return mappingOptions;
            },
        },
        inputSchema: {
            get() {
                for(const schema of this.$store.state.amdp.schemas) {
                    if(schema.id == this.$store.state.amdp.currentMapping.input_schema)
                        return schema
                }
                return ''
            },
        },
        outputSchema: {
            get() {
                for(const schema of this.$store.state.amdp.schemas) {
                    if(schema.id == this.$store.state.amdp.currentMapping.output_schema)
                        return schema
                }
                return ''
            },
        },
    },
    methods: {
        updateCurrentMapping(value) {
            for(const mapping of this.$store.state.amdp.mappings) {
                if(mapping.id == value){
                    this.$store.dispatch('amdp/setCurrentMapping', mapping)
                    return
                }
            }
        }
    }
};
</script>

<style>
</style>
<template>
    <table
        :id="tableId"
        :class="[
            'table table-hover card-table',
            compact ? 'compact' : '',
        ]"
        style="width: 100%"
    >
        <thead>
            <tr>
                <th v-if="selectCheckboxes" style="width: 30px">
                    <a style="cursor: pointer" @click="selectAllClicked"
                        ><i :class="selectAllIconClass"></i
                    ></a>
                </th>
                <th v-if="detailColumn" style="width: 50px"></th>
                <th v-for="column in columns" :key="column.index">
                    {{ column.data }}
                </th>
            </tr>
        </thead>
        <tbody></tbody>
    </table>
</template>

<script>
import DataTable from "datatables.net";
import "datatables.net-bs5";
import "datatables.net-select-bs5";
import "datatables.net-buttons-bs5";

//suppress 'alert' errors
DataTable.ext.errMode = "console";
//pagination buttons to max of 5
DataTable.ext.pager.numbers_length = 5;

const zeroRecordsString = '<div class="spinner-border spinner-border-sm text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>';

export default {
    name: "Datatable",
    data() {
        const self = this;
        let buttons = [];
        if (this.deletable) {
            buttons.push({
                extend: "selected",
                text: "Delete",
                action: function (e, dt, node, config) {
                    self.$emit("deleteRows", self.getSelectedRows());
                },
            });
        }

        let order = [];
        let orderColumn = 0;
        let tableColumns = [...this.columns];
        let select = this.select;

        if (this.detailColumn) {
            orderColumn++;
            order = [[orderColumn, "asc"]];
            tableColumns.unshift({
                data: null,
                orderable: false,
                className: "edit-row",
                render: function (data, type, full, meta) {
                    return '<span class="detail-btn btn btn-mini btn-light fe fe-external-link" style="padding:0.25em 0.5em"></span>';
                },
            });
        }

        if (this.selectCheckboxes) {
            orderColumn++;
            order = [[orderColumn, "asc"]];
            select = {
                style: "multi+shift",
                selector: "td:first-child",
            };
            tableColumns.unshift({
                orderable: false,
                className: "fe select-checkbox-fe",
                targets: 0,
            });
        }

        return {
            selected: null,
            selectAllIconClass: "fe fe-square",
            tableColumns: tableColumns,
            tableSettings: {
                dom:
                    "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6'B>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'li><'col-sm-12 col-md-7'p>>", //order of displayed components, length, filter, reprocessing, table, info, pagination, Buttons
                searching: this.searching,
                paging: this.paging,
                info: this.info,
                data: this.data,
                lengthChange: this.lengthChange,
                language: {
                    paginate: {
                        previous: "<i class='fe fe-chevron-left' />",
                        next: "<i class='fe fe-chevron-right' />",
                    },
                    zeroRecords:zeroRecordsString,
                },
                select: select,
                buttons: buttons,
                columns: tableColumns,
                order: order,
                scrollX: this.scrollX
            },
        };
    },
    props: {
        data: Array,
        columns: Array,
        selectedItem: Object,
        tableId: {
            type: String,
            default: "datatable",
        },
        searching: {
            type: Boolean,
            default: true,
        },
        info: {
            type: Boolean,
            default: false,
        },
        paging: {
            type: Boolean,
            default: true,
        },
        detailColumn: {
            type: Object,
            default: null,
        },
        selectCheckboxes: {
            type: Boolean,
            default: false,
        },
        select: {
            type: [Boolean, Object],
            default: true,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        lengthChange: {
            type: Boolean,
            default: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        scrollX: {
            type: Boolean,
            default: true,
        }
    },
    emits: ["selected", "deleteRows"],
    methods: {
        selectAllClicked() {
            var all = this.table.rows({ search: "applied" }).count(); // get total count of rows
            var selectedRows = this.table
                .rows({ selected: true, search: "applied" })
                .count(); // get total count of selected rows

            if (selectedRows < all) {
                //Added search applied in case user wants the search items will be selected
                this.table.rows({ search: "applied" }).deselect();
                this.table.rows({ search: "applied" }).select();
                this.selectAllIconClass = "fe fe-check-square";
            } else {
                this.table.rows({ search: "applied" }).deselect();
                this.selectAllIconClass = "fe fe-square";
            }
        },
        initTable() {
            console.log("init table");

            const self = this;
            let settings = this.tableSettings;
            this.table = new DataTable("#" + this.tableId, settings);

            //setup listener to emit a row that is selected
            if (this.select) {
                this.table.on("select", function (e, dt, type, indexes) {
                    if (type === "row") {
                        var data = self.table
                            .rows({ selected: true })
                            .data()[0];
                        self.$emit("selected", data);
                        self.selected = data;
                    }
                });
                this.table.on("deselect", function (e, dt, type, indexes) {
                    self.selected = null;
                });
            }
            //select row if parent view is asking for one to be selected initially
            if (this.selectedItem) {
                this.table.rows().every(function (rowIdx, tableLoop, rowLoop) {
                    const item = this.data();
                    if (item === self.selectedItem) {
                        this.select();
                    }
                });
            }
            //setup listeners for selectall
            if (this.selectCheckboxes) {
                this.table.on("select deselect", function () {
                    var all = self.table.rows({ search: "applied" }).count(); // get total count of rows
                    var selectedRows = self.table
                        .rows({ selected: true, search: "applied" })
                        .count(); // get total count of selected rows

                    if (selectedRows < all) {
                        self.selectAllIconClass = "fe fe-minus-square";
                    } else if (selectedRows == all) {
                        self.selectAllIconClass = "fe fe-check-square";
                    } else {
                        self.selectAllIconClass = "fe fe-check-square";
                    }
                });
            }
            //setup listener to trigger the router if editable cell is clicked
            if (this.detailColumn) {
                const openDetail = (data) => {
                    let url = self.detailColumn.baseUrl
                        ? self.detailColumn.baseUrl
                        : "";
                    url += data[self.detailColumn.identifier];

                    self.$router.push(url);
                };
                this.table.on("click", function (e, dt, type, indexes) {
                    if (e.target.className.indexOf("detail-btn") !== -1) {
                        var data = self.table
                            .row(e.target.parentNode.parentNode)
                            .data();
                        openDetail(data);
                    }
                });
                this.table.on("dblclick", function (e, dt, type, indexes) {
                    var data = self.table
                            .row(e.target.parentNode)
                            .data();
                    openDetail(data)
                });
            }
        },
        getSelectedRows() {
            var selected = this.table.rows({ selected: true }).data();
            var rows = [];
            for (var i = 0; i < selected.length; i++) rows.push(selected[i]);
            return rows;
        },
    },
    watch: {
        // whenever data changes, this will run
        data(newData, oldData) {
            //refresh all rows
            //this.table.clear()
            //this.table.rows.add([newData]).draw()

            //re-initialize table
            if (this.table) {
                //remove our event listeners
                //IMPORTANT otherwise events will attach to new table instance
                this.table.off("select deselect click");
                //destroy the table instance
                this.table.destroy();
            }
            this.tableSettings.oLanguage = null;
            // if null is passed in as the data, the loader will show
            if (newData === null) {
                this.tableSettings.language.zeroRecords = zeroRecordsString;
                newData = []
            } else if (!newData.length)
                this.tableSettings.language.zeroRecords = "<div></div>";
            
            this.tableSettings.data = newData;
            this.initTable();
        },
    },
    beforeUnmount() {
        this.table.destroy();
    },
    mounted() {
        this.initTable();
    },
};
</script>

<style lang="scss">
@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";
@import "~datatables.net-select-bs5/css/select.bootstrap5.min.css";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
    background-color: #2c7be5;
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import UnitTest from '../../../../src/views/sandbox/UnitTest'
import UnitTestExecution from '../../../../src/views/sandbox/UnitTestExecution'

const routes = [

  {
    name: 'Unit Test',
    path: '/',
    component: UnitTest,
    props: true
  },
  {
    path: '/:id',
    name: 'Unit Test Execution',
    component: UnitTestExecution,
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
